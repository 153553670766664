import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import FeatureHighlight from 'components/shared/feature-highlight';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { ValueTile } from 'components/shared/value-tile';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import ScreenTypeIcon from 'images/screen-type.svg?react';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop, ShopTraits } from 'types/shops';
import { User } from 'types/user';
import {
  WebsiteIntegrationsAnalyticsPageName,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import {
  EXPIRY_IN_30DAYS,
  getExpiresAtDate,
  getIsStoredDateValid,
} from 'utilities/salesforce';

import SmartButtonConfiguration from './button-configuration';
import { EmbedCode } from './embed-code';
import GetCodeModal from './get-code-modal';
import ShareEmbedCodeModal from './share-embed-code-modal';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  shopTraits: ShopTraits;
  smartButtonSettings: WebsiteIntegrationsSettings;
  user: User;
  multiLocEnabled: boolean;
};

const SmartButtons = ({
  shop,
  shopTraits,
  smartButtonSettings,
  user,
  multiLocEnabled,
}: Props) => {
  const page = multiLocEnabled
    ? WebsiteIntegrationsAnalyticsPageName.SmartButtonsMultiloc
    : WebsiteIntegrationsAnalyticsPageName.SmartButtons;
  const {
    trackProductUpsellLinkClick,
    trackViewedWebsiteIntegrationsCodeDocumentationModal,
  } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseExpiration, setCaseExpiration] = useStoredState<string>(
    `sf-${shop.shopId}-smart-buttons-upsell-expires-at`,
  );

  const [isShareEmbedCodeModalOpen, setIsShareEmbedCodeModalOpen] =
    useState(false);

  const {
    mutate: createSalesforceCase,
    isPending: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const onSave = () => {
    setIsModalOpen(true);
    trackViewedWebsiteIntegrationsCodeDocumentationModal({
      shopId: String(shop.shopId),
      source: 'save changes CTA',
      page,
    });
  };

  const hasValidCase = useMemo(() => {
    return getIsStoredDateValid(caseExpiration);
  }, [caseExpiration]);

  const handleSetLocalStorage = () => {
    const value = getExpiresAtDate(EXPIRY_IN_30DAYS);
    setCaseExpiration(String(value));
  };

  const handleRequestSupportClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.SmartButton,
            subject: SalesforceSubject.SmartButton,
          },
          note: SalesforceNote.SmartButtonNote,
          shopId: shop.shopId,
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () => {
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            );
            handleSetLocalStorage();
          },
        },
      );
    }

    trackProductUpsellLinkClick({
      shopId: String(shop.shopId),
      product: 'smart button',
      page: 'smart buttons',
      linkLabel: 'request support',
      type: ClickedExternalLinkType.LinkedText,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const openShareEmbedCodeModal = () => {
    setIsShareEmbedCodeModalOpen(true);
  };

  const closeShareEmbedCodeModal = () => {
    setIsShareEmbedCodeModalOpen(false);
  };

  return (
    <>
      <div className={styles.upsellContainer}>
        <ValueTile>
          <ValueTile.Heading
            icon={<ScreenTypeIcon />}
            text="Turn your website traffic into
              orders with a Smart Button."
          />
          <ValueTile.Content>
            <ValueTile.GroupedItems
              valueOne="Turn visitors into repeat customers"
              valueTwo="Customize button to fit your brand"
            />
            <ValueTile.GroupedItems
              valueOne="Increase order size through web orders"
              valueTwo="View and analyze your shop and customer data"
            />
          </ValueTile.Content>
        </ValueTile>
        {!shopTraits.isSmartButtonActive && !multiLocEnabled && (
          <div className={styles.embedHelpContainer}>
            <EmbedCode
              openShareEmbedCodeModal={openShareEmbedCodeModal}
              shopInfo={shop}
              shopTraits={shopTraits}
              user={user}
            />
            {hasValidCase ? (
              <FeatureHighlight
                containerClassName={styles.successContainer}
                icon="verification"
                text={`Thanks for requesting help with embedding your Smart Button code. We’ll be in touch soon to get started.`}
              />
            ) : (
              <FeatureHighlight
                containerClassName={styles.supportContainer}
                titleClassName={styles.supportTitle}
                title="Need Help?"
                icon="help"
                text="If you need help embedding the button on your site, we can do it for
                you. A member of our team can can work with you, or your developer,
                to get the button up and running."
                primaryCta={
                  <Button
                    className={styles.requestSupport}
                    variant={ButtonVariant.LinkStylePrimary}
                    onClick={() => handleRequestSupportClick()}
                  >
                    Request Support
                  </Button>
                }
              />
            )}
          </div>
        )}
      </div>
      <SmartButtonConfiguration
        onSave={onSave}
        shop={shop}
        smartButtonSettings={smartButtonSettings}
        shopTraits={shopTraits}
        page={page}
        isMultiLocEnabled={multiLocEnabled}
        user={user}
      />
      {!multiLocEnabled && (
        <GetCodeModal
          shopId={shop.shopId}
          shopUuid={shop.uuid}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          shopName={shop.name}
          shopCity={shop.city}
        />
      )}

      <ShareEmbedCodeModal
        isOpen={isShareEmbedCodeModalOpen}
        isSmartButtonActive={shopTraits.isSmartButtonActive}
        onClose={closeShareEmbedCodeModal}
        shopId={shop.shopId}
        user={user}
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartButtons;
