import PropTypes from 'prop-types';

import ContentTile from '../content-tile';

import AnalyticsTileFooter from './footer';
import AnalyticsTileHeader from './header';

import styles from './styles.module.scss';

const AnalyticsTile = ({
  children = null,
  footerText = null,
  link = '',
  linkText = '',
  title,
  headerTooltip = null,
}) => {
  return (
    <ContentTile className={styles.tile}>
      <AnalyticsTileHeader title={title} tooltipText={headerTooltip} />
      <div className={styles.content}>{children}</div>
      {footerText && (
        <>
          <hr className={styles.divider} />
          <AnalyticsTileFooter
            footerText={footerText}
            link={link}
            linkText={linkText}
          />
        </>
      )}
    </ContentTile>
  );
};

AnalyticsTile.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.node,
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  link: PropTypes.string,
  linkText: PropTypes.string,
  title: PropTypes.string.isRequired,
  headerTooltip: PropTypes.node,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsTile;
