import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { Button, ButtonGroup } from 'crust';

import { entriesOverlap } from 'components/hours/weekly-hours/helpers';
import FormFeedback from 'components/shared/form-feedback';
import { DeleteButton } from 'components/shared/icon-button';
import TimeSelect from 'components/shared/time-select';
import { getNextDay } from 'utilities/date-time';
import { showInvalidSubmitToast } from 'utilities/forms';

import { flattenErrorMessages } from './utils';

import styles from './styles.module.scss';

const EditorForm = ({
  hours = [],
  isUpdateInProgress = false,
  onCloseForm = () => {},
  onFormSubmit = () => {},
  shopTimezone = '',
  trackDailyHoursActions = () => {},
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
    getValues,
    watch,
  } = useForm({
    mode: 'onBlur',
    defautValues: {
      hours,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'hours',
  });

  useEffect(() => {
    if (!isDirty) {
      reset({ hours });
    }
  }, [hours, isDirty, reset]);

  const cancelUpdates = () => {
    reset({ hours });
    onCloseForm();
  };

  // If two sets of hours are defined, they cannot be overlapping.
  const validateOverlapping = () => {
    const hoursArray = getValues('hours');

    // Hours cannot be overlapping if they're not yet defined.
    if (hoursArray.length <= 1 || !hoursArray[1].from || !hoursArray[1].to) {
      return true;
    }

    if (entriesOverlap(...hoursArray)) {
      return 'Your times cannot overlap, please adjust your selection.';
    }

    return true;
  };

  const trackOnClick = (buttonClicked, timeRange) => {
    trackDailyHoursActions(buttonClicked, timeRange);
  };

  const onSubmit = ({ hours }) => {
    onFormSubmit(hours);
    trackOnClick('Save', [...hours]);
    onCloseForm();
  };

  const handleOnChange = ({ field, value }) => field.onChange(value);

  const feedback = flattenErrorMessages(errors);

  return (
    <>
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit, showInvalidSubmitToast)}
      >
        {fields.map(({ id, from, to }, index) => (
          <div className={styles.range} key={id}>
            <div className={styles.hourSelectors}>
              <div
                className={
                  errors?.hours?.[index]?.from
                    ? styles.invalidSelect
                    : styles.select
                }
              >
                <span>Start:</span>
                <Controller
                  control={control}
                  defaultValue={from}
                  name={`hours[${index}].from`}
                  rules={{
                    required: 'Please select a start time.',
                    validate: validateOverlapping,
                  }}
                  render={({ field }) => (
                    <TimeSelect
                      aria-label="Start time"
                      onChange={({ value }) => handleOnChange({ field, value })}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div
                className={
                  errors?.hours?.[index]?.to
                    ? styles.invalidSelect
                    : styles.select
                }
              >
                <span>End:</span>
                <Controller
                  control={control}
                  defaultValue={to}
                  name={`hours[${index}].to`}
                  rules={{
                    required: 'Please select an end time.',
                    validate: validateOverlapping,
                  }}
                  render={({ field }) => (
                    <TimeSelect
                      aria-label="End time"
                      labelNextDayBefore={from}
                      nextDayLabel={getNextDay(
                        moment(from, 'HH:mm', shopTimezone),
                      ).format('ddd')}
                      onChange={({ value }) => handleOnChange({ field, value })}
                      startAtTime={watch('hours')[index].from}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
            <DeleteButton
              className={styles.deleteButton}
              onClick={() => remove(index)}
              disabled={fields.length < 2}
              label="Delete"
            />
          </div>
        ))}
        {feedback.map((message) => (
          <FormFeedback key={message} className={styles.error}>
            {message}
          </FormFeedback>
        ))}
        <div className={styles.addButton}>
          <Button
            appearance="link"
            isDisabled={fields.length > 1}
            label="Add additional time"
            onPress={() => append()}
          >
            Add Additional Time
          </Button>
        </div>
        <ButtonGroup autoCollapse>
          <Button
            isDisabled={isUpdateInProgress}
            onPress={() => {
              cancelUpdates();
              trackDailyHoursActions('Cancel');
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            className={styles.editorButton}
            isDisabled={isUpdateInProgress}
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </ButtonGroup>
      </form>
    </>
  );
};

EditorForm.propTypes = {
  hours: PropTypes.array,
  effectiveDate: PropTypes.string,
  isUpdateInProgress: PropTypes.bool,
  onCloseForm: PropTypes.func,
  onFormSubmit: PropTypes.func,
  shopTimezone: PropTypes.string,
  trackDailyHoursActions: PropTypes.func,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default EditorForm;
