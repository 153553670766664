import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Suspended } from 'components/shared/suspended';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  });

  return <Suspended isLoading={true} variant={'viewPort'} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Logout;
