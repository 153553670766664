import { useCallback, useMemo, useState } from 'react';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import moment from 'moment-timezone';

import { ReportHeader } from 'components/reports/report-header';
import { StandalonePaymentsReportForm } from 'components/reports/standalone-payments/form';
import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import PageControl from 'components/shared/table/page-control';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useStandalonePaymentsReportsQuery } from 'hooks/reports/use-standalone-payments-reports-query';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import {
  ReportAnalyticsType,
  ReportStatus,
  StandalonePaymentsReport,
} from 'types/financials';

import styles from './styles.module.scss';

const DEFAULT_COLUMN_SORT = {
  id: 'createdAt',
  desc: true,
} as const;

type Props = {
  shopId: number;
  shopTimezone: string;
};

export const StandalonePaymentsReports = ({ shopId, shopTimezone }: Props) => {
  const { trackDownloadReport } = useAnalytics();
  const [shouldShowForm, setShouldShowForm] = useState(false);

  const handleClickCreateReport = useCallback(
    () => setShouldShowForm((current) => !current),
    [],
  );

  const [sorting, setSorting] = useState<SortingState>([DEFAULT_COLUMN_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data: reportsQueryResponse, isPending: isReportsQueryLoading } =
    useStandalonePaymentsReportsQuery(
      shopId,
      pagination,
      sorting[0] ?? DEFAULT_COLUMN_SORT,
    );

  const columns = useMemo(() => {
    const helper = createColumnHelper<StandalonePaymentsReport>();

    // When standalone payments reports are generated through the rez-api endpoint
    // the dates we send when creating are stripped of the time part and set to the date in UTC.
    // When we get the reports it is the date we sent but with UTC time so we parse the timezone
    // here in order to display the correct date for the user.
    // https://github.com/slicelife/restaurant_api/blob/f8b8835d83a0d39c88f6603d43069a6042b98191/app/controllers/api/management/v1/shops/standalone_payments_reports_controller.rb#L36
    const formatDateRange = (from: string, to: string) =>
      `${moment.parseZone(from).format('MM/DD/YYYY')} - ${moment
        .parseZone(to)
        .format('MM/DD/YYYY')}`;

    const formatReportCreatedDate = (report: StandalonePaymentsReport) => {
      return moment.tz(report.createdAt, shopTimezone).format('MM/DD/YYYY');
    };

    return [
      helper.accessor((it) => formatDateRange(it.fromDate, it.toDate), {
        id: 'report',
        header: 'Report',
        enableSorting: false,
        cell(ctx) {
          return (
            <div>
              <span className={styles.dateRange}>{ctx.getValue()}</span>
              <br />
              Register Credit Card Payments Report
            </div>
          );
        },
        meta: {
          className: styles.reportColumn,
        },
      }),

      helper.accessor(
        (it) => moment.tz(it.createdAt, shopTimezone).format('MM/DD/YYYY'),
        {
          id: 'createdAt',
          header: 'Date Created',
          meta: {
            chameleonId: 'createdAt',
            className: styles.createdAtColumn,
            mobileHeading: 'Created',
          },
        },
      ),

      helper.display({
        id: 'download',
        header() {
          return <VisuallyHidden>Download</VisuallyHidden>;
        },
        meta: {
          isActionColumn: true,
        },
        cell(ctx) {
          const report = ctx.row.original;
          const range = formatDateRange(report.fromDate, report.toDate);

          const isFailed = report.status === ReportStatus.Failure;
          const isSuccess = report.status === ReportStatus.Success;

          if (isSuccess && report.downloadUrl) {
            return (
              <TableAction
                className={styles.downloadLink}
                icon="download"
                onClick={() =>
                  trackDownloadReport({
                    shopId,
                    type: ReportAnalyticsType.StandalonePayments,
                    createdDate: formatReportCreatedDate(report),
                    fileType: 'csv',
                  })
                }
                title={`Standalone payments report for ${range}.`}
                to={report.downloadUrl}
              />
            );
          }

          // Success without a URL might as well be a failure.
          return (
            <span className={styles.downloadText}>
              {isFailed || isSuccess ? 'Failed' : 'Pending'}
            </span>
          );
        },
      }),
    ];
  }, [shopId, shopTimezone, trackDownloadReport]);

  const table = useTable({
    columns,
    data: reportsQueryResponse?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: reportsQueryResponse?.meta.pagination.pages ?? 1,
    state: {
      isLoading: isReportsQueryLoading,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Credit card payments reports',
  });

  return (
    <>
      <ReportHeader onClickCreateReport={handleClickCreateReport} />
      {shouldShowForm && (
        <StandalonePaymentsReportForm
          closeForm={() => setShouldShowForm(false)}
          shopId={shopId}
          shopTimezone={shopTimezone}
        />
      )}
      <ContentTile>
        <Table className={styles.table} table={table} />
        <PageControl
          currentPage={table.getState().pagination.pageIndex + 1}
          setPage={(page: number) => table.setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
        />
      </ContentTile>
    </>
  );
};
