import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getShopQueryKey } from 'hooks/shops';
import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

export const useUpdatePermissionsToggleMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (nextIsEnabled: boolean) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/settings`,
        {
          setting: {
            register_user_permissions: nextIsEnabled,
          },
        },
      );

      return response.setting.register_user_permissions as boolean;
    },
    onMutate: async (nextIsEnabled: boolean) => {
      const queryKey = getShopQueryKey(shopId);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Optimistically update to the new value
      queryClient.setQueryData<Shop>(queryKey, (shop) =>
        shop ? { ...shop, registerUserPermissions: nextIsEnabled } : undefined,
      );
    },
    onError: (_error, nextIsEnabled) => {
      const correctValue = !nextIsEnabled;

      queryClient.setQueryData<Shop>(getShopQueryKey(shopId), (shop) =>
        shop
          ? {
              ...shop,
              registerUserPermissions: correctValue,
            }
          : undefined,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getShopQueryKey(shopId),
      });
    },
  });
};
