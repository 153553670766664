import { ElementRef, ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  mergeRefs,
  Radio,
  RadioGroup,
  type RadioGroupProps,
  type RadioProps,
} from 'crust';

type ControllerPropsKeys = Exclude<keyof UseControllerProps, 'disabled'>;
type RadioGroupPropsKeys = Exclude<
  keyof RadioGroupProps,
  'isInvalid' | 'onChange' | 'validate' | ControllerPropsKeys
>;

export type RHFRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<RadioGroupProps, RadioGroupPropsKeys> &
  Pick<UseControllerProps<TFieldValues, TName>, ControllerPropsKeys>;

export const RHFRadioGroup = forwardRef(function RHFRadioGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    children,
    control,
    defaultValue,
    isDisabled,
    name,
    rules,
    shouldUnregister,
    ...props
  }: RHFRadioGroupProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof RadioGroup>>,
) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    disabled: isDisabled,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <RadioGroup
      error={fieldState.error?.message}
      isDisabled={field.disabled}
      isInvalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={mergeRefs(ref, field.ref)}
      validationBehavior="aria"
      value={field.value ?? null}
      {...props}
    >
      {children}
    </RadioGroup>
  );
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFRadioGroupProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof RadioGroup>>,
) => ReactElement;

export type RHFRadioProps = RadioProps;
export const RHFRadio = Radio;
