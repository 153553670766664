import { PromoCode } from 'components/discounts/promo-code';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const PromoCodePage = () => {
  const shopId = useCurrentShopId();

  // It is currently not possible to edit promo codes.
  const name = 'new-promo-code';
  const title = 'Add New Promo Code';

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  let content;
  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <PromoCode shopId={shopId} shopTimezone={shop.timezoneIdentifier} />
    );
  }

  return (
    <Page name={name} title={title}>
      {content}
    </Page>
  );
};
