import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetReportsRequestParams,
  GetReportsResponse,
  GetStandalonePaymentsReportsResponse,
  StandalonePaymentsReport,
} from 'types/financials';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { toSnakeCase } from 'utilities/strings';

type UseStandalonePaymentsReportsQueryOptions = Omit<
  UseQueryOptions<GetStandalonePaymentsReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getStandalonePaymentsReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [
  shopId,
  'standalonePaymentsReports',
  ...(params ? [params] : []),
];

export const useStandalonePaymentsReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseStandalonePaymentsReportsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    // Does not use leading "+".
    sort: `${sorting.desc ? '-' : ''}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getStandalonePaymentsReportsQueryKey(shopId, params),
    queryFn: async () => {
      const query = `${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/standalone_payments_reports?${query}`,
      );

      return camelCaseKeys(
        response,
      ) as GetReportsResponse<StandalonePaymentsReport>;
    },
    placeholderData: keepPreviousData,
    ...options,
  });
};
