import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  CreateOrdersReportRequestParams,
  CreateOrdersReportResponse,
  OrdersReportFormValues,
} from 'types/financials';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getOrdersReportsQueryKey } from './use-orders-reports-query';

export const useCreateOnlineOrdersReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
): UseMutationResult<
  CreateOrdersReportResponse,
  ApiRequestError,
  OrdersReportFormValues
> => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  const createOnlineOrdersReport = async (
    shopId: number,
    params: CreateOrdersReportRequestParams,
  ): Promise<CreateOrdersReportResponse> => {
    const fullParams = {
      ...params,
      forceUpdate: true,
    };
    const query = `${queryString.stringify(snakeCaseKeys(fullParams))}`;
    const response = await authenticatedFetch.post(
      `api/management/v1/shops/${shopId}/online_order_ledger?${query}`,
    );

    return camelCaseKeys(response);
  };

  return useMutation({
    mutationFn: (values: OrdersReportFormValues) => {
      return createOnlineOrdersReport(shopId, {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getOrdersReportsQueryKey(shopId),
      });
    },
  });
};
