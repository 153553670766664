import { CustomerSegments } from 'components/customers/customer-segments';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

import styles from './styles.module.scss';

export const CustomerSegmentsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: user,
    isError: isUserError,
    isPending: isUserLoading,
  } = useCurrentUserQuery();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  let content;
  if (isShopLoading || isUserLoading) {
    content = <Loading />;
  } else if (isShopError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = <CustomerSegments shop={shop} user={user} />;
  }

  return (
    <Page
      contentClassName={styles.content}
      name="Customer Segments"
      title="Customer Segments"
    >
      {content}
    </Page>
  );
};
