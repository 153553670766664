import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { User } from 'types/user';
import { camelCaseKeys } from 'utilities/objects';

import useApi from '../use-api';

type UseCurrentUserQueryOptions = Omit<
  UseQueryOptions<User>,
  'queryFn' | 'queryKey'
>;

export const getCurrentUserQueryKey = (): QueryKey => ['user'];

export const useCurrentUserQuery = (options?: UseCurrentUserQueryOptions) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getCurrentUserQueryKey(),
    queryFn: async () => {
      const response = await authenticatedFetch.get(`api/management/v1/me`);

      return camelCaseKeys(response);
    },
    staleTime: 300000, // Five minutes.
    ...options,
  });
};
