import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Loading from 'components/shared/loading';
import Modal from 'components/shared/slice-modal';
import { usePosIntegrationDisconnectAuthMutation } from 'hooks/pos-integration/use-pos-integration-disconnect-auth-mutation';
import { usePosIntegrationQuery } from 'hooks/pos-integration/use-pos-integration-query';
import { usePosIntegrationRedirectQuery } from 'hooks/pos-integration/use-pos-integration-redirect-query';
import { usePosIntegrationRevokeAuthMutation } from 'hooks/pos-integration/use-pos-integration-revoke-auth-mutation';
import { useCreateUberEatsIntegrationMutation } from 'hooks/uber-eats-integration/use-disconnect-uber-eats-integration-mutation';
import { useUberEatsIntegrationRedirectQuery } from 'hooks/uber-eats-integration/use-uber-eats-integration-redirect-query';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

import CloverIntegrationListItem from './integration-list-item/clover';
import FacebookIntegrationListItem from './integration-list-item/facebook';
import SquareIntegrationListItem from './integration-list-item/square';
import { StreamIntegrationListItem } from './integration-list-item/stream';
import { UberEatsIntegrationListItem } from './integration-list-item/uber-eats';
import { PosAuthModes, PosProviders } from './constants';

import styles from './styles.module.scss';

const ShopIntegrations = ({ shopId }) => {
  const {
    isEnabled: isUberEatsIntegrationEnabled,
    isLoading: isUberEatsIntegrationFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.UberEatsIntegration);

  const { isEnabled: isStreamEnabled, isLoading: isStreamLoading } =
    useFeatureFlag(OptimizelyFeatureFlag.StreamBanner);

  const { data: integration } = usePosIntegrationQuery(shopId);

  const { data: squareRedirect } = usePosIntegrationRedirectQuery(
    shopId,
    PosProviders.Square,
  );

  const { data: uberEatsData } = useUberEatsIntegrationRedirectQuery(shopId);

  const isSquareConnected =
    integration?.provider === PosProviders.Square &&
    integration?.status !== 'unauthorized';
  const squareShopName = isSquareConnected
    ? integration.providerShopName
    : null;
  const squareRedirectUrl = squareRedirect?.url;
  const uberEatsRedirectUrl = uberEatsData?.url || '';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePos, setActivePos] = useState(PosProviders.Square);
  const [posAuthMode, setPosAuthMode] = useState(PosAuthModes.Disconnect);
  const [isUberEatsConnected, setIsUberEatsConnected] = useState(
    uberEatsData?.isConnected,
  );

  useEffect(
    () => setIsUberEatsConnected(uberEatsData?.isConnected),
    [uberEatsData?.isConnected],
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { mutate: disconnect } =
    usePosIntegrationDisconnectAuthMutation(shopId);

  const { mutate: disconnectUberEats } = useCreateUberEatsIntegrationMutation();

  const handleDisconnect = () => {
    disconnect(integration.provider, {
      onError: () => {
        toast.error('An unexpected error occurred, please try again.');
      },
      onSettled: () => {
        closeModal();
      },
    });
  };
  const handleUberEatsDisconnect = () => {
    disconnectUberEats(
      { shopId },
      {
        onError: () => {
          toast.error('An unexpected error occurred, please try again.');
        },
        onSuccess: () => {
          setIsUberEatsConnected(false);
          closeModal();
        },
      },
    );
  };

  const { mutate: revoke } = usePosIntegrationRevokeAuthMutation(shopId);

  const handleRevoke = () => {
    revoke(integration.provider, {
      onError: () => {
        toast.error('An unexpected error occurred, please try again.');
      },
      onSettled: () => {
        closeModal();
      },
    });
  };

  const posModalDetails = {
    square: {
      disconnect: {
        text: 'By clicking disconnect, you will disconnect your Square Point of Sale from Slice. As a result, your Point of Sale will no longer receive Slice orders.',
        callback: handleDisconnect,
      },
      revoke: {
        text: 'Are you sure you want to revoke all of Slice’s access permissions? By clicking disconnect, all of your Square locations will be disconnected from Slice.',
        callback: handleRevoke,
      },
    },
    uberEats: {
      disconnect: {
        text: 'Are you sure that you want to remove your Uber Eats integration with Slice Register?',
        callback: handleUberEatsDisconnect,
      },
    },
  };

  const openSquareRevokeModal = () => {
    setActivePos(PosProviders.Square);
    setPosAuthMode(PosAuthModes.Revoke);
    openModal();
  };

  const openSquareDisconnectModal = () => {
    setActivePos(PosProviders.Square);
    setPosAuthMode(PosAuthModes.Disconnect);
    openModal();
  };

  const openUberEatsDisconnectModal = () => {
    setActivePos(PosProviders.UberEats);
    setPosAuthMode(PosAuthModes.Disconnect);
    openModal();
  };

  return (
    <>
      {isUberEatsIntegrationFeatureFlagLoading || isStreamLoading ? (
        <Loading />
      ) : (
        <>
          <SquareIntegrationListItem
            squareShopName={squareShopName}
            openSquareDisconnectModal={openSquareDisconnectModal}
            openSquareRevokeModal={openSquareRevokeModal}
            isSquareConnected={isSquareConnected}
            squareRedirectUrl={squareRedirectUrl}
          />
          <CloverIntegrationListItem />
          <FacebookIntegrationListItem />
          {isUberEatsIntegrationEnabled && (
            <UberEatsIntegrationListItem
              openUberEatsDisconnectModal={openUberEatsDisconnectModal}
              isUberEatsConnected={isUberEatsConnected}
              uberEatsRedirectUrl={uberEatsRedirectUrl}
            />
          )}
          {isStreamEnabled && <StreamIntegrationListItem />}

          <Modal
            header="Disconnect Your Provider"
            isOpen={isModalOpen}
            onClickNo={closeModal}
            onClickYes={posModalDetails[activePos][posAuthMode].callback}
            onRequestClose={closeModal}
            yesButtonText="Disconnect"
          >
            <div className={styles.posListItemModalCopy}>
              {posModalDetails[activePos][posAuthMode].text}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

ShopIntegrations.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopIntegrations;
