import { RegisterDiscount } from 'types/discounts';
import { RegisterLayoutCategory } from 'types/register-layout';
import { RegisterPermission, RegisterUser } from 'types/register-users';

import { Id, shop } from './shop';

export const registerBase = (shopId?: Id) => `${shop(shopId)}/register`;

export const registerProfiles = (shopId?: Id) =>
  `${registerBase(shopId)}/system-profiles`;

export const promotionsAndDiscounts = (shopId?: Id) =>
  `${registerBase(shopId)}/promotions-and-discounts`;

export const registerDiscount = (
  shopId?: Id,
  discountId: RegisterDiscount['id'] = ':discountId',
) => `${promotionsAndDiscounts(shopId)}/${discountId}`;

export const registerLayout = (shopId?: Id) => `${registerBase(shopId)}/layout`;

export const registerLayoutCategory = (
  shopId?: Id,
  categoryId: RegisterLayoutCategory['id'] | string = ':categoryId',
) => `${registerLayout(shopId)}/category/${categoryId}`;

export const registerPermissions = (shopId?: Id) =>
  `${registerBase(shopId)}/permissions`;

export const registerPermission = (
  shopId?: Id,
  permissionGroupId: RegisterPermission['id'] = ':permissionGroupId',
) => `${registerPermissions(shopId)}/${permissionGroupId}`;

export const registerPermissionsLog = (shopId?: Id) =>
  `${registerBase(shopId)}/log`;

export const registerUsers = (shopId?: Id) => `${registerBase(shopId)}/users`;

export const registerUser = (
  shopId?: Id,
  userId: RegisterUser['id'] = ':userId',
) => `${registerUsers(shopId)}/${userId}`;

export const registerTipSettings = (shopId?: Id) =>
  `${registerBase(shopId)}/tip-settings`;

export const shopRegisterPrinting = (shopId?: Id) =>
  `${registerBase(shopId)}/printing`;

export const getRegisterSplashPath = (shopId?: Id) =>
  `${registerBase(shopId)}/learn`;
