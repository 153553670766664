import { Logomark } from 'crust';

import { Shop } from 'types/shops';

import { PsmInfo } from '../psm-info';
import { ShopSelector } from '../shop-selector';

import styles from './styles.module.scss';

type NavigationHeaderProps = {
  activeShop: Shop;
  shopId: Shop['shopId'];
  shops: Shop[];
  verificationCode: string;
  isAdmin: boolean;
};

export const NavigationHeader = ({
  activeShop,
  shopId,
  shops,
  verificationCode,
  isAdmin,
}: NavigationHeaderProps) => {
  return (
    <div className={styles.header}>
      <Logomark className={styles.sliceLogo} />
      {activeShop && (
        <ShopSelector activeShop={activeShop} shops={shops} isAdmin={isAdmin} />
      )}
      <PsmInfo shopId={shopId} verificationCode={verificationCode} />
    </div>
  );
};
