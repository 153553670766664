import { FormEvent, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Icon } from 'crust';

import Link from 'components/shared/link';
import Page from 'components/shared/page';
import { Suspended } from 'components/shared/suspended';
import { useTypeSafeParams } from 'hooks/params/use-type-safe-params';
import {
  useRegisterPermissionGroupsQuery,
  useRegisterUsersQuery,
} from 'hooks/register-users';
import { useDeleteRegisterUserMutation } from 'hooks/register-users/use-delete-register-user-mutation';
import { useResetRegisterUserPinMutation } from 'hooks/register-users/use-reset-register-user-pin-mutation';
import { useSaveRegisterUserMutation } from 'hooks/register-users/use-save-register-user-mutation';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { RegisterUser, UserFormValues } from 'types/register-users';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import GeneratePinTile from './generate-pin-tile';
import UserTile from './user-tile';

import styles from './styles.module.scss';

const RegisterUserForm = () => {
  const { trackClickedGenerateNewPin, trackEnteredUserInformation } =
    useAnalytics();
  const navigate = useNavigate();

  const { shopId, userId } = useTypeSafeParams(['shopId', 'userId']);

  const isNew = userId === 'new';
  const name = isNew ? 'add-register-user' : 'edit-register-user';
  const title = 'Register Users';
  const defaultValuesRef = useRef<UserFormValues>();

  const { data: registerUsers, isLoading: isUsersLoading } =
    useRegisterUsersQuery(shopId, {
      enabled: !isNew,
    });

  const { data: permissionGroups, isPending: isGroupsLoading } =
    useRegisterPermissionGroupsQuery(shopId);

  if (isNew && !defaultValuesRef.current) {
    defaultValuesRef.current = {
      email: '',
      name: '',
      permissionGroups: [],
      phone: '',
      pin: '',
    };
  }

  const user = registerUsers?.find((user) => user.id === userId);

  const { control, formState, getValues, handleSubmit, register, reset } =
    useForm({
      defaultValues: defaultValuesRef.current,
      mode: 'onTouched',
    });
  const { errors } = formState;

  useEffect(() => {
    if (!defaultValuesRef.current && user) {
      reset({
        email: user.email ?? '',
        name: user.name,
        permissionGroups: user.permissionGroups ?? [],
        phone: user.phoneNumber ?? '',
        pin: user.pin ?? '',
      });
    }
  }, [user, reset]);

  const { mutateAsync: saveUser } = useSaveRegisterUserMutation(
    isNew,
    shopId,
    userId,
  );
  const { mutate: deleteUser } = useDeleteRegisterUserMutation(shopId, userId);

  const handleSaveUser = (values: UserFormValues) => {
    saveUser(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess(response: RegisterUser) {
        toast.success(`${response.name} Saved!`);

        if (isNew) {
          // When we create a user, we navigate to the edit user screen to allow for pin creation
          navigate(paths.registerUser(shopId, response.id));
        } else {
          navigate(paths.registerUsers(shopId));
        }
      },
    });
  };

  const handleValidSubmit = (values: UserFormValues) => {
    const groupIds = values.permissionGroups;
    const groupNames: string[] = [];

    if (permissionGroups) {
      groupIds.forEach((groupId) => {
        const group = permissionGroups.find((group) => {
          return group.id === groupId;
        });

        if (group) {
          groupNames.push(group.name);
        }
      });
    }

    trackEnteredUserInformation({
      shopId,
      userName: values.name,
      email: values.email ? values.email : null,
      phone: values.phone ? values.phone : null,
      permissionGroups: groupNames,
    });
    handleSaveUser(values);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      await handleSubmit(handleValidSubmit, showInvalidSubmitToast)(event);
    } catch {
      // Do nothing. Handled by mutations.
    }
  };

  const handleDeleteUser = () => {
    deleteUser(undefined, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: () => {
        toast.success(`${user?.name} Has Been Deleted!`);

        navigate(paths.registerUsers(shopId));
      },
    });
  };

  const { mutate: generatePin } = useResetRegisterUserPinMutation(
    userId,
    shopId,
  );

  const handleGeneratePin = () => {
    generatePin(undefined, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess(response: RegisterUser) {
        toast.success(`${response.name} Pin Updated!`);
      },
    });
  };

  return (
    <Page name={name} title={title}>
      <div className={styles.userDetailHeader}>
        <Link
          className={styles.userDetailHeaderLink}
          to={paths.registerUsers(shopId)}
        >
          <Icon icon="chevronLeft" size="large" />
          User Detail
        </Link>
      </div>
      <div className={styles.tilesWrapper}>
        <Suspended isLoading={isUsersLoading || isGroupsLoading}>
          <UserTile
            shopId={shopId}
            control={control}
            onDeleteUser={handleDeleteUser}
            errors={errors}
            isNew={isNew}
            register={register}
            onSubmit={handleFormSubmit}
            getValues={getValues}
            permissionGroups={permissionGroups ?? []}
          />
          <GeneratePinTile
            pin={user?.pin ?? undefined}
            isDisabled={isNew}
            generatePin={() => {
              trackClickedGenerateNewPin(shopId);
              handleGeneratePin();
            }}
          />
        </Suspended>
      </div>
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterUserForm;
