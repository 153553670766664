import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import AnalyticsTile from 'components/shared/analytics-tile';
import { useProductSalesMetricsQuery } from 'hooks/metrics/use-product-sales-metrics-query';
import { Direction } from 'utilities/sorting';

import ProductsBySales from '../table/products-by-sales';

const TopSellingProducts = ({
  shopId = '',
  startDate,
  endDate,
  orderType = 'online',
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('all');

  const { data, isPending } = useProductSalesMetricsQuery(shopId, {
    start: startDate,
    finish: endDate,
    orderType,
    selectedCategoryId,
  });

  const topSellingItems = useMemo(() => data?.data ?? [], [data]);

  return (
    <AnalyticsTile title="Top Selling Menu Items">
      <ProductsBySales
        data={topSellingItems}
        isEmpty={topSellingItems.length === 0}
        isLoading={isPending}
        shopId={shopId}
        parentTileTitle="Top Selling"
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={setSelectedCategoryId}
        sortDirection={Direction.Descending}
      />
    </AnalyticsTile>
  );
};

TopSellingProducts.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TopSellingProducts;
