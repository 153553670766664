import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';

const NameOnCardInput = ({ nameError, register }) => {
  return (
    <>
      <Label htmlFor="debit-card-name">Name on Card</Label>
      <Input
        aria-required="true"
        id="debit-card-name"
        isInvalid={nameError != null}
        {...register('nameOnCard', {
          required: 'Please enter the name as displayed on this card',
        })}
      />
      <FormFeedback>{nameError?.message}</FormFeedback>
    </>
  );
};

NameOnCardInput.propTypes = {
  nameError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  register: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NameOnCardInput;
