import PropTypes from 'prop-types';

import { Button } from 'crust';

import Link from 'components/shared/link';
import squareLogo from 'images/integrations/square-logo.png';
import * as externalUrls from 'routes/external-urls';
import { emailAddresses, phoneNumbers } from 'utilities/constants';
import { formatPhoneNumber } from 'utilities/strings';

import IntegrationListItem from '../';

import styles from './styles.module.scss';

const SquareIntegrationListItem = ({
  squareShopName = '',
  openSquareDisconnectModal = () => {},
  openSquareRevokeModal = () => {},
  isSquareConnected = false,
  squareRedirectUrl = null,
}) => (
  <IntegrationListItem
    styles={styles}
    copyText={
      <>
        {squareShopName === null ? (
          <>
            <p className={styles.squareCopy}>
              View all your Slice orders directly on your Square Point of Sale!
            </p>
            <p className={styles.squareCopy}>
              Click the &quot;Connect&quot; button to get started. After you are
              connected, let your account manager know so they can turn on the
              integration. You can also contact us at{' '}
              <Link to="mailto:partner@slicelife.com">
                {emailAddresses.partner}
              </Link>{' '}
              or {formatPhoneNumber(phoneNumbers.accountManagers)} to have us
              walk you through the process.
            </p>
            This account is currently not connected to a Square location.
          </>
        ) : (
          <>
            <p className={styles.squareCopy}>
              Your Slice account is connected to the Square location &quot;
              {squareShopName}&quot;.
            </p>
            <p className={styles.squareCopy}>
              <span className={styles.attention}>Please Note:</span> To finalize
              the integration and send Slice orders directly to your Square
              Point of Sale, you must notify your account manager to complete a
              test order. Contact us at{' '}
              <Link to="mailto:partner@slicelife.com">
                {emailAddresses.partner}
              </Link>{' '}
              or {formatPhoneNumber(phoneNumbers.accountManagers)}.
            </p>
            <p className={styles.squareCopy}>
              To disconnect this Slice account from &quot;{squareShopName}
              &quot; (and stop orders from going through to your Point of Sales
              system), please click &quot;Disconnect&quot;.
            </p>
            <p className={styles.squareCopy}>
              If you have multiple locations and want to fully disconnect Slice
              from your Square account, you can{' '}
              <Button
                appearance="link"
                variant="secondary"
                onPress={openSquareRevokeModal}
              >
                revoke Slice access permissions
              </Button>
              .
            </p>
            <p className={styles.squareCopy}>
              <span className={styles.attention}>Questions? </span>
              Read our&nbsp;
              <Link to={externalUrls.squareFaq}>Shop FAQs</Link>
            </p>
          </>
        )}
      </>
    }
    disabled={false}
    imageAltText="Square logo"
    imageSource={squareLogo}
    isConnected={isSquareConnected}
    onDisconnect={openSquareDisconnectModal}
    provider="square"
    redirectUrl={squareRedirectUrl}
  />
);

SquareIntegrationListItem.propTypes = {
  squareShopName: PropTypes.string,
  openSquareDisconnectModal: PropTypes.func,
  openSquareRevokeModal: PropTypes.func,
  isSquareConnected: PropTypes.bool,
  squareRedirectUrl: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SquareIntegrationListItem;
