import { useMatch } from 'react-router-dom';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import NavigationTabs from 'components/shared/navigation-tabs';
import useAnalytics from 'hooks/use-analytics';
import DealsImg from 'images/discounts/deals.svg';
import OnlineDiscountsImg from 'images/discounts/online-discounts.svg';
import PromoCodesImg from 'images/discounts/promo-codes.svg';
import RegisterDiscountsImg from 'images/discounts/register-discounts.svg';
import * as paths from 'routes/paths';
import { Coupon, PromoCode, RegisterDiscount } from 'types/discounts';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { AutomaticDiscounts } from './automatic-discounts';
import { Card } from './card';
import { Deals } from './deals';
import { PromoCodes } from './promo-codes';

import styles from './styles.module.scss';

type Props = {
  coupons: Coupon[];
  promoCodes: PromoCode[];
  registerDiscounts: RegisterDiscount[];
  shop: Shop;
};

export const Discounts = ({
  coupons,
  promoCodes,
  registerDiscounts,
  shop,
}: Props) => {
  const dealsPath = paths.discountDealsPath(shop.shopId);
  const promoCodesPath = paths.promoCodes(shop.shopId);
  const onlineDiscountPath = paths.automaticDiscounts(shop.shopId);

  const editOnlineDiscountPath = paths.editAutomaticDiscount(shop.shopId);
  const newOnlineDiscountCodePath = paths.newAutomaticDiscount(shop.shopId);
  const newPromoCodePath = paths.newPromoCode(shop.shopId);
  const newRegisterDiscountPath = paths.registerDiscount(shop.shopId, 'new');

  const dealsPathMatch = useMatch(dealsPath);
  const promoCodesPathMatch = useMatch(promoCodesPath);
  const onlineDiscountPathMatch = useMatch(onlineDiscountPath);

  const totalPromoCodes = promoCodes.length;
  const totalActivePromoCodes = promoCodes.filter((pc) => pc.active).length;

  const totalRegisterDiscounts = registerDiscounts.length;

  const totalCoupons = coupons.length;
  const totalActiveCoupons = coupons.filter(
    (coupon) => !coupon.disabled,
  ).length;

  const {
    trackClickedCreateOnlineDiscount,
    trackClickedCreatePromoCode,
    trackClickedCreateRegisterDiscount,
    trackClickedDealsTab,
    trackClickedOnlineDiscountsTab,
    trackClickedPromoCodesTab,
  } = useAnalytics();

  const hasShopDiscount = shop.discountPercent != null;

  const handleClickCreateOnlineDiscount = () => {
    trackClickedCreateOnlineDiscount({
      existingOnlineDiscountPercentage: shop.discountPercent,
      isRegister: getShopHasFullRegister(shop) ?? false,
      location: 'banner',
      page: 'discounts',
      shopId: shop.shopId,
    });
  };

  const handleClickCreatePromoCode = () => {
    trackClickedCreatePromoCode({
      countActivePromoCodes: totalActivePromoCodes,
      countPromoCodes: totalPromoCodes,
      isRegister: getShopHasFullRegister(shop) ?? false,
      location: 'banner',
      page: 'discounts',
      shopId: shop.shopId,
    });
  };

  const handleClickCreateRegister = () => {
    trackClickedCreateRegisterDiscount({
      countRegisterDiscounts: totalRegisterDiscounts,
      isRegister: getShopHasFullRegister(shop) ?? false,
      location: 'banner',
      page: 'discounts',
      shopId: shop.shopId,
    });
  };

  return (
    <>
      <ContentTile className={styles.tile}>
        <div className={styles.header}>
          <div className={styles.title}>Boost orders with discounts.</div>
          <div className={styles.description}>
            Discounts are a great way to increase orders with new customers and
            loyal regulars.
          </div>
        </div>
        <div className={styles.content}>
          <Card
            content="Offer shopwide discounts online and boost orders by up to 15%."
            header="Online Discounts"
            image={OnlineDiscountsImg}
            link={
              <Link
                href={
                  hasShopDiscount
                    ? editOnlineDiscountPath
                    : newOnlineDiscountCodePath
                }
                // https://github.com/adobe/react-spectrum/issues/6484
                onPressStart={handleClickCreateOnlineDiscount}
              >
                {hasShopDiscount ? 'Edit' : 'Create'}
              </Link>
            }
          />
          <Card
            content="Offer shopwide discounts that you can promote and share anywhere."
            header="Promo Codes"
            image={PromoCodesImg}
            link={
              <Link
                href={newPromoCodePath}
                onPressStart={handleClickCreatePromoCode}
              >
                Create
              </Link>
            }
          />
          {getShopHasFullRegister(shop) && (
            <Card
              content="Offer discounts right from your in-store Register POS."
              header="Register"
              image={RegisterDiscountsImg}
              link={
                <Link
                  href={newRegisterDiscountPath}
                  onPressStart={handleClickCreateRegister}
                >
                  Create
                </Link>
              }
            />
          )}
          <Card
            content="Discount specific items or offer a bundle price. To set up talk to our team."
            header="Deals"
            image={DealsImg}
            link={<Link href={dealsPath}></Link>}
          />
        </div>
      </ContentTile>
      <NavigationTabs className={styles.tabs}>
        <NavigationTabs.Link
          end
          to={onlineDiscountPath}
          data-chameleon-tag="Online Discount"
          onClick={() =>
            trackClickedOnlineDiscountsTab({
              existingOnlineDiscountPercentage: shop.discountPercent,
              isRegister: getShopHasFullRegister(shop) ?? false,
              page: 'discounts',
              shopId: shop.shopId,
            })
          }
        >
          Online Discount
        </NavigationTabs.Link>
        <NavigationTabs.Link
          to={promoCodesPath}
          data-chameleon-tag="Promo Codes"
          onClick={() =>
            trackClickedPromoCodesTab({
              countActivePromoCodes: totalActivePromoCodes,
              countPromoCodes: totalPromoCodes,
              isRegister: getShopHasFullRegister(shop) ?? false,
              page: 'discounts',
              shopId: shop.shopId,
            })
          }
        >
          Promo Codes
        </NavigationTabs.Link>
        <NavigationTabs.Link
          to={dealsPath}
          data-chameleon-tag="Deals"
          onClick={() =>
            trackClickedDealsTab({
              countActiveDeals: totalActiveCoupons,
              countDeals: totalCoupons,
              isRegister: getShopHasFullRegister(shop) ?? false,
              page: 'discounts',
              shopId: shop.shopId,
            })
          }
        >
          Deals
        </NavigationTabs.Link>
      </NavigationTabs>
      {onlineDiscountPathMatch && (
        <AutomaticDiscounts
          shopDiscount={shop.discountPercent}
          shopId={shop.shopId}
        />
      )}
      {promoCodesPathMatch && (
        <PromoCodes
          shopId={shop.shopId}
          shopTimezone={shop.timezoneIdentifier}
        />
      )}
      {dealsPathMatch && (
        <Deals
          shopId={shop.shopId}
          isRegisterShop={getShopHasFullRegister(shop) ?? false}
        />
      )}
    </>
  );
};
