import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getMenuProductsQueryKey } from 'hooks/menu/use-menu-products-query';
import useApi from 'hooks/use-api';
import { RegisterPrinter } from 'types/register-printing';

export const useDeleteRegisterPrintingSettingMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      await authenticatedFetch.del(
        `api/management/v1/ros/shops/${shopId}/printer_roles/${id}`,
      );
    },

    onSuccess: (_data, id) => {
      // Remove the print setting from the cached print settings list.
      queryClient.setQueryData<RegisterPrinter[]>(
        [shopId, 'printSettings'],
        (old) => old?.filter((printSetting) => printSetting.id !== id),
      );

      queryClient.removeQueries({
        queryKey: [shopId, 'printSettings', id],
      });

      // Invalidate query for menu products
      queryClient.invalidateQueries({
        queryKey: getMenuProductsQueryKey({ shopId }),
      });
    },
  });
};
