import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from 'crust';

import { DropdownButton } from 'components/shared/dropdown-button';
import PageHeader from 'components/shared/page/header';
import PageTitle from 'components/shared/page/title';
import Modal from 'components/shared/slice-modal';
import { useDeleteCategoryMutation } from 'hooks/menu/use-delete-category-mutation';
import useAnalytics from 'hooks/use-analytics';
import CloseIcon from 'images/delete.svg?react';
import EllipsesIcon from 'images/ellipses.svg?react';
import * as paths from 'routes/paths';
import { MenuCategory } from 'types/menu/category';
import { Shop } from 'types/shops';
import { showUnexpectedErrorToast } from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  count?: number;
  isLoading?: boolean;
  name?: string;
  shopId: Shop['shopId'];
  categoryId: MenuCategory['id'] | 'new';
};

const CategoryPageHeader = ({
  count = 0,
  isLoading = false,
  name = '',
  shopId,
  categoryId,
}: Props) => {
  const navigate = useNavigate();
  const { trackDeleteCategory } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isNew = categoryId === 'new';
  const title = isNew ? 'New Category' : 'Edit Category';

  const { isPending: isCategoryDeleting, mutate: deleteCategory } =
    useDeleteCategoryMutation(shopId);

  const handleDeleteCategory = () => {
    if (typeof categoryId === 'number') {
      deleteCategory(
        { categoryId },
        {
          onError: () => {
            showUnexpectedErrorToast();
          },
          onSuccess: () => {
            toast.success('Category and its items deleted!');
            trackDeleteCategory(shopId, categoryId);
            navigate(paths.getMenuCategoriesPath(shopId));
          },
        },
      );
    }
  };

  const controls = [
    {
      name: 'Delete Category',
      action: () => setIsModalOpen(true),
      isActive: false,
      Icon: <CloseIcon aria-hidden="true" />,
    },
  ];

  if (isNew || isLoading) {
    return (
      <PageHeader>
        <PageTitle>{title}</PageTitle>
      </PageHeader>
    );
  }

  return (
    <>
      <PageHeader className={styles.header}>
        <PageTitle className={styles.title}>
          {title}
          <DropdownButton
            buttonClassName={styles.overflowButton}
            buttonContent={<EllipsesIcon className={styles.ellipsisIcon} />}
            controls={controls}
          />
        </PageTitle>
        <div className={styles.buttonWrapper}>
          <Button
            appearance="link"
            icon="trashCan"
            onPress={() => setIsModalOpen(true)}
            variant="secondary"
          >
            Delete Category
          </Button>
        </div>
      </PageHeader>
      <Modal
        header="Delete Category?"
        isOpen={isModalOpen}
        isYesButtonDisabled={isCategoryDeleting}
        onClickNo={() => setIsModalOpen(false)}
        onClickYes={handleDeleteCategory}
        onRequestClose={() => setIsModalOpen(false)}
        yesButtonText="Delete"
      >
        You are about to delete the{' '}
        <strong className={styles.bold}>{name}</strong> category and all of its{' '}
        <strong className={styles.bold}>{count} items</strong> from your menu.{' '}
        This action cannot be undone.
      </Modal>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategoryPageHeader;
