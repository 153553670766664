import { QueryKey } from '@tanstack/react-query';

import { CustomerList, TopProduct } from 'types/customers';

import styles from './styles.module.scss';

type Props = {
  customerAnalyticsQueryKey: QueryKey;
  customerList: CustomerList;
  shopId: string;
  topProduct: TopProduct;
};

const FavoriteItem = ({ topProduct }: Props) => {
  const { productName, customersCount } = topProduct;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftArea}>
          <div>
            <div className={styles.labelText}>Favorite Item</div>
            <div className={styles.valueText}>{productName}</div>
          </div>
        </div>
        <div className={styles.rightArea}>
          <div className={styles.labelText}>Ordered By</div>
          <div className={styles.valueText}>
            {customersCount === 1
              ? '1 Customer'
              : `${customersCount} Customers`}
          </div>
        </div>
      </div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FavoriteItem;
