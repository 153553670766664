import {
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Schedule } from 'types/shop-hours';
import { formatGenerateScheduleRequest } from 'utilities/closings';
import { camelCaseKeys } from 'utilities/objects';

import {
  Closings,
  ClosingsResponse,
  getClosingsQueryKey,
} from './use-closings';
import { getOpeningsQueryKey } from './use-openings';

type GenerateScheduleMutationParams = {
  // TODO: Need to add types for openings and closings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closings?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openings?: any;
  shopId: string;
  shopTimezone: string;
};

export const getScheduleQueryKey = (shopId: string): QueryKey => [
  shopId,
  'schedule',
];

export const useScheduleQuery = (
  shopId: string,
  options?: UseQueryOptions<Schedule>,
): UseQueryResult<Schedule> => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getScheduleQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/v1/shops/${shopId}/schedule`,
      );

      return camelCaseKeys(response);
    },
    ...options,
  });
};

export const useGenerateScheduleMutation = ({
  closings,
  openings,
  shopId,
  shopTimezone,
}: GenerateScheduleMutationParams): UseMutationResult => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      let openingsForSchedule = openings;
      if (openingsForSchedule == null) {
        openingsForSchedule = await authenticatedFetch
          .get(`api/management/v1/shops/${shopId}/openings`)
          .then(camelCaseKeys);
      }
      let closingsForSchedule = closings;
      if (closingsForSchedule == null) {
        closingsForSchedule = await authenticatedFetch
          .get(`api/management/v1/shops/${shopId}/closings`)
          .then(({ data }) => {
            // Formatting the closing data to allow easier
            // interactions with the closureReason prop
            // at the component level e.g. sorting
            const closings = data.map((closing: ClosingsResponse) => ({
              ...closing,
              closureReason: closing.closure_reason.id,
              closureReasonName: closing.closure_reason.name,
            }));
            return camelCaseKeys(closings) as Closings;
          });
      }

      const generateRequestParams = formatGenerateScheduleRequest(
        openingsForSchedule,
        closingsForSchedule,
        shopTimezone,
      );

      const schedule = await authenticatedFetch
        .post(`api/v1/shops/${shopId}/schedule/generate`, {
          ...generateRequestParams,
        })
        .then(camelCaseKeys);

      return {
        closings: closingsForSchedule,
        schedule,
        openings: openingsForSchedule,
      };
    },
    onSuccess: ({ closings, schedule, openings }) => {
      queryClient.setQueryData(getClosingsQueryKey(shopId), closings);
      queryClient.setQueryData(getScheduleQueryKey(shopId), schedule);
      queryClient.setQueryData(getOpeningsQueryKey(shopId), openings);
    },
  });
};
