import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';

import { getMenuProductsQueryKey } from './use-menu-products-query';

type MenuUpdateProductTypesPricesRequest = {
  productTypeIds: number[];
  roundTo: number;
  percentage: number | undefined;
  flat: number | undefined;
};

export const useProductTypesPricesUpdateMutation = (shopId: number) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: MenuUpdateProductTypesPricesRequest) => {
      return authenticatedFetch.put(
        `api/management/v2/shops/${shopId}/menus/product_types/prices`,
        values,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getMenuProductsQueryKey({ shopId }),
      });
    },
  });
};
