import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { z } from 'zod';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { RegisterUpsellFormValues } from 'types/register/register-splash';
import { showInvalidSubmitToast } from 'utilities/forms';

import styles from './styles.module.scss';

const serviceTypeSchema = z.enum(
  ['No', 'Bar Service', 'Waiter Service', 'Both'],
  {
    invalid_type_error: 'Please select your service type.',
  },
);

const expirationSchema = z.enum(
  ['No Contract', '0-3 Months', '4-12 Months', '12 Months+', 'Not Sure'],
  {
    invalid_type_error: 'Please select when your current POS contract expires.',
  },
);

const numLocationsSchema = z.enum(['1', '2', '3'], {
  invalid_type_error: 'Please select your number of locations.',
});

export const registerUpsellFormValidationSchema = z.discriminatedUnion(
  'posSystem',
  [
    z.object({
      posSystem: z.enum(['None']),
      posServiceType: serviceTypeSchema,
      posNumOfLocations: numLocationsSchema,
    }),
    z.object({
      posSystem: z.enum(['Square', 'Clover', 'Other']),
      posSystemName: z
        .string()
        .min(1, 'Please enter the name of your current POS system.')
        .optional(),
      posExpiration: expirationSchema,
      posServiceType: serviceTypeSchema,
      posNumOfLocations: numLocationsSchema,
    }),
  ],
);

const posOptions = registerUpsellFormValidationSchema.options.flatMap(
  (it) => it.shape.posSystem.options,
);

const posExpirationOptions = expirationSchema.options;
const posServiceTypeOptions = serviceTypeSchema.options;
const posNumOfLocationsOptions = numLocationsSchema.options;

type Props = {
  control: Control<RegisterUpsellFormValues>;
  errors: FieldErrors<RegisterUpsellFormValues>;
  handleSubmit: UseFormHandleSubmit<RegisterUpsellFormValues>;
  onSubmitSuccess: (formValues: RegisterUpsellFormValues) => void;
  register: UseFormRegister<RegisterUpsellFormValues>;
  watch: UseFormWatch<RegisterUpsellFormValues>;
};

export const ScheduleDemoForm = ({
  control,
  errors,
  handleSubmit,
  onSubmitSuccess,
  register,
  watch,
}: Props) => {
  const selectedPos = watch('posSystem');

  const handleSubmitClick: SubmitHandler<RegisterUpsellFormValues> = (
    values,
  ) => {
    onSubmitSuccess(values);
  };

  return (
    <form
      id="schedule-register-demo-form"
      className={styles.modalForm}
      onSubmit={handleSubmit(handleSubmitClick, showInvalidSubmitToast)}
    >
      <p className={styles.modalHelpText}>
        Tell us a little about your business to help us personalize your demo.
      </p>
      <RHFRadioGroup
        control={control}
        isRequired
        label="What is your current point of sale system?"
        name="posSystem"
        orientation="horizontal"
        variant="card"
      >
        {posOptions.map((option) => (
          <RHFRadio key={option} label={option} value={option} />
        ))}
      </RHFRadioGroup>
      {selectedPos === 'Other' ? (
        <div className={styles.labeledTextInput}>
          <Label htmlFor={`pos-system-name`}>POS System Name:</Label>
          <Input
            id={`pos-system-name`}
            aria-required
            required
            {...register('posSystemName')}
          />
          <FormFeedback>
            {'posSystemName' in errors ? errors.posSystemName?.message : null}
          </FormFeedback>
        </div>
      ) : null}
      {selectedPos && selectedPos !== 'None' ? (
        <RHFRadioGroup
          control={control}
          isRequired
          label="When does your POS contract expire?"
          name="posExpiration"
          orientation="horizontal"
          variant="card"
        >
          {posExpirationOptions.map((option) => (
            <RHFRadio key={option} label={option} value={option} />
          ))}
        </RHFRadioGroup>
      ) : null}
      <RHFRadioGroup
        control={control}
        isRequired
        label="Do you offer bar or waiter service?"
        name="posServiceType"
        orientation="horizontal"
        variant="card"
      >
        {posServiceTypeOptions.map((option) => (
          <RHFRadio key={option} label={option} value={option} />
        ))}
      </RHFRadioGroup>
      <RHFRadioGroup
        control={control}
        isRequired
        label="How many locations do you own/manage?"
        name="posNumOfLocations"
        orientation="horizontal"
        variant="card"
      >
        {posNumOfLocationsOptions.map((option) => (
          <RHFRadio key={option} label={option} value={option} />
        ))}
      </RHFRadioGroup>
    </form>
  );
};
