import { cloneElement } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ContentTile from 'components/shared/content-tile';

import styles from './styles.module.scss';

const Tile = ({
  canStretch = false,
  children = null,
  footerContent = null,
  icon = null,
  title = '',
}) => (
  <ContentTile
    className={canStretch ? styles.stretchedWrapper : styles.wrapper}
  >
    <div className={styles.header}>
      {icon &&
        cloneElement(icon, {
          'aria-hidden': 'true',
          className: cx(styles.icon, icon.props.className),
        })}
      {title}
    </div>
    <div className={styles.content}>{children}</div>
    {footerContent && <div className={styles.footer}>{footerContent}</div>}
  </ContentTile>
);

Tile.propTypes = {
  canStretch: PropTypes.bool,
  children: PropTypes.node,
  footerContent: PropTypes.node,
  icon: PropTypes.element,
  title: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Tile;
