import { useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import {
  useGenerateScheduleMutation,
  useOpenForTodayQuery,
  useOpenForTodaySelectors,
  useOpeningsQuery,
  usePausingsQuery,
} from 'hooks/hours';
import { useClosings } from 'hooks/hours/use-closings';
import { useCreatePauseMutation } from 'hooks/hours/use-create-pause-mutation';
import { useDeletePauseMutation } from 'hooks/hours/use-delete-pause-mutation';
import { useUpdateOpenForTodayMutation } from 'hooks/hours/use-update-open-for-today-mutation';
import { useUpdatePauseMutation } from 'hooks/hours/use-update-pause-mutation';
import { useShopQuery } from 'hooks/shops';
import { databaseRecordSource } from 'utilities/constants';
import { nowDateTime } from 'utilities/date-time';
import { showUnexpectedErrorToast } from 'utilities/forms';
import { getShopShippingStatus } from 'utilities/shop-hours-utils';

import CloseShopModal from './close-shop-modal';
import { modalOptions, openForShippingTypePayloads } from './constants';
import CurrentDateTime from './current-date-time';
import ExtendPauseModal from './extend-pause-modal';
import PauseOrdersModal from './pause-orders-modal';
import ShopOpeningStatusTile from './shop-opening-status-tile';

import styles from './styles.module.scss';

const HoursHeader = ({ shopId }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [shippingType, setShippingType] = useState(null);
  const [closingReasonById, setClosingReasonById] = useState(null);
  const [pausingReasonById, setPausingReasonById] = useState(null);

  const { data: shop } = useShopQuery(shopId);
  const shopTimezone = shop?.timezoneIdentifier ?? moment.tz.guess();

  const { data: shopOpenForTodayData, isPending: isShopOpenForTodayLoading } =
    useOpenForTodayQuery(shopId);

  const { mutate: updateOpenForToday, isLoading: isUpdateOpenForTodayLoading } =
    useUpdateOpenForTodayMutation(shopId);

  const openForTodaySelectors = useOpenForTodaySelectors(
    shopOpenForTodayData,
    isShopOpenForTodayLoading,
  );
  const {
    isAcceptingOrders,
    isClosedForDelivery,
    isClosedForPickup,
    isRegularScheduleDeliveryOpen,
    isRegularSchedulePickupOpen,
    nextOpenAt,
  } = openForTodaySelectors;

  const { data: openings } = useOpeningsQuery(shopId);
  const { data: closings } = useClosings(shopId);

  const { data: pausings, isPending: isShopPausingsLoading } =
    usePausingsQuery(shopId);

  const { mutate: generateSchedule } = useGenerateScheduleMutation({
    closings,
    openings,
    shopId,
    shopTimezone,
  });

  const handleAcceptOrders = () => {
    updateOpenForToday(
      {
        open_for_today: { delivery: true, pickup: true },
        source: databaseRecordSource,
      },
      {
        onError: () => showUnexpectedErrorToast(),
        onSettled: () => handleOnCloseModal(),
      },
    );
  };

  const handleUpdateOpenForToday = () => {
    updateOpenForToday(
      {
        open_for_today: openForShippingTypePayloads[shippingType],
        closure_reason_id: closingReasonById,
        source: databaseRecordSource,
      },
      {
        onError: () => {
          showUnexpectedErrorToast();
        },
        onSettled: () => handleOnCloseModal(),
      },
    );
  };

  const { mutate: createPause, isPending: isPausingOrders } =
    useCreatePauseMutation(shopId);
  const handlePauseOrders = ({ startTime, endTime }) =>
    createPause(
      {
        startTime,
        endTime,
        shippingType,
        source: databaseRecordSource,
        closureReasonId: pausingReasonById,
      },

      {
        onError: () => showUnexpectedErrorToast(),
        onSuccess: () => {
          generateSchedule();
        },
        onSettled: () => handleOnCloseModal(),
      },
    );

  const { mutate: updatePause, isPending: isExtendingPause } =
    useUpdatePauseMutation(shopId);
  const handleExtendPause = ({ updatedEndTime }) => {
    updatePause(
      {
        pausingId: pausings?.[0].id,
        updatedEndTime,
      },
      {
        onError: () => showUnexpectedErrorToast(),
        onSuccess: () => {
          generateSchedule();
        },
        onSettled: () => handleOnCloseModal(),
      },
    );
  };

  const { mutate: deletePause, isPending: isDeletingPause } =
    useDeletePauseMutation(shopId);

  const handleDeletePause = (pausingId) => {
    deletePause(
      pausingId,

      {
        onError: () => showUnexpectedErrorToast(),

        onSuccess: () => {
          generateSchedule();
        },
        onSettled: () => handleOnCloseModal(),
      },
    );
  };

  const replaceExistingPause = ({ startTime, endTime, callback }) =>
    handleDeletePause(pausings?.[0].id, {
      onSuccess: () => {
        handlePauseOrders({
          startTime,
          endTime,
          callback,
        });
      },
    });

  const nextOpenAtMoment = moment.tz(nextOpenAt, shopTimezone);

  const closedForTodayString = nowDateTime(shopTimezone).format('dddd, MMMM D');
  const nextOpenOnDateString = nextOpenAtMoment.format('dddd, MMMM D');

  const handleOnCloseModal = () => {
    setActiveModal(null);
    setShippingType(null);
    setClosingReasonById(null);
    setPausingReasonById(null);
  };

  const handleOpenCloseModal = () => {
    setActiveModal(modalOptions.closeShippingType);
  };

  const handleOpenPauseModal = () => {
    setActiveModal(modalOptions.pauseShippingType);
  };

  const handleOpenExtendPauseModal = () => {
    setActiveModal(modalOptions.extendPauseShippingType);
  };

  const handleClickOpen = (openingFromPaused = false) => {
    if (openingFromPaused) {
      handleDeletePause(pausings?.[0].id);
    } else if (isClosedForDelivery || isClosedForPickup) {
      handleAcceptOrders();
    }
  };

  const handleClickClose = () => {
    if (isAcceptingOrders) {
      handleOpenCloseModal();
    }
  };

  const handleClickPause = () => {
    handleOpenPauseModal();
  };

  const handleClickExtend = () => {
    handleOpenExtendPauseModal();
  };

  return (
    <div className={styles.currentInfoWrapperWithPause}>
      <CurrentDateTime shopTimezone={shopTimezone} />
      <ShopOpeningStatusTile
        areOptionsDisabled={
          isUpdateOpenForTodayLoading ||
          isPausingOrders ||
          isExtendingPause ||
          isDeletingPause
        }
        className={styles.openingStatusTile}
        isLoading={isShopOpenForTodayLoading || isShopPausingsLoading}
        onClickClose={handleClickClose}
        onClickExtend={handleClickExtend}
        onClickOpen={handleClickOpen}
        onClickPause={handleClickPause}
        shopId={shopId}
        shopOpeningStatus={getShopShippingStatus({
          openForTodaySelectors,
          shopTimezone,
        })}
        shopTimezone={shopTimezone}
      />
      <CloseShopModal
        canCloseForShippingTypes={{
          canCloseForDeliveryAndPickup:
            isRegularScheduleDeliveryOpen && isRegularSchedulePickupOpen,
          canCloseForDelivery:
            isRegularScheduleDeliveryOpen && !isClosedForDelivery,
          canCloseForPickup: isRegularSchedulePickupOpen && !isClosedForPickup,
        }}
        closedForTodayDate={closedForTodayString}
        isOpen={activeModal === modalOptions.closeShippingType}
        nextOpenAtDate={nextOpenOnDateString}
        onClose={handleOnCloseModal}
        onSubmit={handleUpdateOpenForToday}
        selectedClosingReasonId={closingReasonById}
        selectedShippingType={shippingType}
        setSelectedReasonId={setClosingReasonById}
        setShippingType={setShippingType}
        shopId={shopId}
      />
      <PauseOrdersModal
        canPauseForShippingTypes={{
          canPauseForDeliveryAndPickup:
            isRegularScheduleDeliveryOpen &&
            isRegularSchedulePickupOpen &&
            pausings?.[0]?.shippingType !== 'delivery_and_pickup' &&
            !isClosedForDelivery &&
            !isClosedForPickup,
          canPauseForDelivery:
            isRegularScheduleDeliveryOpen &&
            !isClosedForDelivery &&
            pausings?.[0]?.shippingType !== 'delivery',
          canPauseForPickup:
            isRegularSchedulePickupOpen &&
            !isClosedForPickup &&
            pausings?.[0]?.shippingType !== 'pickup',
        }}
        closedForTodayDate={closedForTodayString}
        isOpen={activeModal === modalOptions.pauseShippingType}
        onClose={handleOnCloseModal}
        onSubmit={pausings?.[0] ? replaceExistingPause : handlePauseOrders}
        selectedPausingReasonId={pausingReasonById}
        selectedShippingType={shippingType}
        setSelectedReasonId={setPausingReasonById}
        setShippingType={setShippingType}
        shopId={shopId}
        shopTimezone={shopTimezone}
      />
      {pausings?.[0] && (
        <ExtendPauseModal
          existingPause={pausings[0]}
          isOpen={activeModal === modalOptions.extendPauseShippingType}
          onClose={handleOnCloseModal}
          onSubmit={handleExtendPause}
          shopId={shopId}
          shopTimezone={shopTimezone}
        />
      )}
    </div>
  );
};

HoursHeader.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HoursHeader;
