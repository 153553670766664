export enum OptimizelyFeatureFlag {
  BrandManager = 'brand_manager_page',
  BulkChangePrinterAssignments = 'bulk_change_printer_assignments',
  FakeTestFlag = 'fake_test_flag',
  HoursAnalytics = 'hours_analytics',
  ShouldUpsellAMS = 'should_upsell_ams',
  OwnersPortalCustomerProfilesPage = 'owners_portal_customer_profiles_page',
  ProductKitchenName = 'product_kitchen_ticket_alias',
  HomepageCalloutRecommendation = 'homepage_callout_recommendation',
  RegisterMenuLayoutRedesign = 'register_menu_layout_redesign',
  CustomerProfilesPageImport = 'customer_profiles_page_import',
  RegisterCombinedSalesSummaryReport = 'register_combined_sales_summary_report',
  MenuTableRedesign = 'menu_table_redesign',
  GoogleBusinessProfile = 'google_business_profile',
  PhonePage = 'phone_page',
  UberEatsIntegration = 'uber_eats_integration',
  PayoutManagement = 'payout_management',
  StreamBanner = 'stream_banner',
  GoodsPaymentsPage = 'goods_payments_page',
  LineItemDiscount = 'line_item_discount',
}
