import PropTypes from 'prop-types';

import { Suspended } from 'components/shared/suspended';
import { useShopQuery, useShopScoreQuery } from 'hooks/shops';
import { nowDateTime } from 'utilities/date-time';
import { scoreAsPercentage } from 'utilities/shops';

import {
  MetricTile,
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
} from '../metric-tile';

import StatusIndicator from './status-indicator';

import styles from './styles.module.scss';

const ShopScore = ({ className, shopId }) => {
  const { data: shop, isPending: isShopLoading } = useShopQuery(shopId);
  const { data: shopScoreData, isPending: isShopScoreLoading } =
    useShopScoreQuery(shopId);

  const isPending = isShopLoading || isShopScoreLoading;

  // The response will contain be a 404 if we have no traits data
  // for the shop, so the empty state should only be shown if there
  // are errors in the response
  const isShopScoreInProgress = shopScoreData?.error?.status === 404;

  return (
    <MetricTile className={className}>
      <MetricTileContent className={styles.content}>
        <MetricTileHeader>
          <MetricTileTitle>Pizzeria Score</MetricTileTitle>
        </MetricTileHeader>
        <Suspended isLoading={isPending}>
          <StatusIndicator
            isShopScoreInProgress={isShopScoreInProgress}
            score={scoreAsPercentage(shopScoreData?.score ?? 0)}
            lastUpdatedDate={nowDateTime(shop?.timezoneIdentifier).subtract(
              1,
              'days',
            )}
          />
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};

ShopScore.propTypes = {
  className: PropTypes.string,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopScore;
