import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { UseQueryParameters } from 'types/queries';
import { Shop } from 'types/shops';

type PaymentMethodTypesResponse = {
  brands: string[];
  name: string;
  type: string;
  configuration: {
    merchantId: string;
    merchantName: string;
  };
};

type GetPaymentMethodTypesResponse = PaymentMethodTypesResponse[];

type PaymentMethodTypesQueryParameters = {
  shopId: Shop['shopId'];
};

const getPaymentMethodTypesQueryKey = ({
  shopId,
}: PaymentMethodTypesQueryParameters) => [shopId, 'paymentMethodTypes'];

export const usePaymentMethodTypesQuery = ({
  parameters,
}: UseQueryParameters<PaymentMethodTypesQueryParameters>) => {
  const { authenticatedFetch } = useApi();

  const {
    data: paymentMethodTypes,
    isPending: isPaymentMethodTypesLoading,
    isError: hasPaymentMethodTypeError,
  } = useQuery({
    queryKey: getPaymentMethodTypesQueryKey(parameters),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/payment_method_types/available`,
      );

      return response as GetPaymentMethodTypesResponse;
    },
  });

  return {
    paymentMethodTypes,
    isPaymentMethodTypesLoading,
    hasPaymentMethodTypeError,
  };
};
