import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'crust';

import CollapsibleTile, {
  CollapsibleTileVariant,
} from 'components/shared/collapsible-tile';
import useAnalytics from 'hooks/use-analytics';
import { CustomizationField, getCustomizationFieldName } from 'utilities/menu';

import CustomizationInputs from './customization-inputs';
import CustomizationMeta from './customization-meta';
import CustomizationTileSummary from './customization-tile-summary';
import CustomizationTileTitle from './customization-tile-title';
import CustomizationDeleteModal from './delete-customization-modal';
import Selections from './selections';

import styles from './styles.module.scss';

const Customization = ({
  control,
  errors,
  index,
  getValues,
  length,
  productId,
  register,
  removeField,
  setValue,
  shopId,
  submitCount,
}) => {
  const [isTileOpen, setIsTileOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { trackClickedDeleteCustomization } = useAnalytics();

  useEffect(() => {
    if (errors) {
      setIsTileOpen(true);
    }
  }, [errors, submitCount]);

  const handleClickDelete = () => {
    setIsModalOpen(true);
    trackClickedDeleteCustomization({
      shopId,
      productId,
      customizationName: getValues(
        getCustomizationFieldName(CustomizationField.Name, index),
      ),
    });
  };

  return (
    <CollapsibleTile
      className={styles.tile}
      id={`customization-${index}-tile`}
      isOpen={isTileOpen}
      setIsOpen={setIsTileOpen}
      summary={<CustomizationTileSummary control={control} index={index} />}
      title={<CustomizationTileTitle control={control} index={index} />}
      toggleAfter={
        <IconButton
          aria-label={`Delete customization ${index + 1}`}
          icon="trashCan"
          onPress={handleClickDelete}
        />
      }
      variant={CollapsibleTileVariant.Embedded}
    >
      <CustomizationMeta
        control={control}
        customizationIndex={index}
        register={register}
      />
      <CustomizationInputs
        control={control}
        customizationIndex={index}
        getValues={getValues}
        length={length}
        nameError={errors?.[CustomizationField.Name]}
        register={register}
        setValue={setValue}
        shopId={shopId}
        submitCount={submitCount}
      />
      <Selections
        control={control}
        customizationIndex={index}
        errors={errors?.selections}
        getValues={getValues}
        register={register}
        setValue={setValue}
        shopId={shopId}
        submitCount={submitCount}
      />
      <CustomizationDeleteModal
        control={control}
        index={index}
        isOpen={isModalOpen}
        removeField={removeField}
        setIsOpen={setIsModalOpen}
        setValue={setValue}
      />
    </CollapsibleTile>
  );
};

Customization.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Customization;
