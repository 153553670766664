import { useState } from 'react';

import { Button } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { TooltipIconButton } from 'components/shared/tooltips';
import useAnalytics from 'hooks/use-analytics';

import CreateClosingTile from './create-tile';
import ClosingsTable from './table';

import styles from './styles.module.scss';

type ClosingsProps = {
  shopId: string;
  shopTimezone: string;
};

export const Closings = ({ shopId, shopTimezone }: ClosingsProps) => {
  const { trackAddScheduledClosingTile, trackEditScheduledClosingTile } =
    useAnalytics();

  const [isAddTileVisible, setIsAddTileVisible] = useState(false);
  const [activeClosingId, setActiveClosingId] = useState<number | null>(null);

  const handleAddTileToggle = () => {
    if (!isAddTileVisible) {
      trackAddScheduledClosingTile(shopId);
    }
    setIsAddTileVisible((previousValue) => !previousValue);
  };

  const handleEditTileToggle = (closingId: number) => {
    if (closingId) {
      trackEditScheduledClosingTile(shopId, closingId);
    }
    setActiveClosingId(closingId);
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.addButton}
        onPress={handleAddTileToggle}
        variant="secondary"
        data-testid="scheduled-closings-add-closing-button"
      >
        Add Closing
      </Button>
      {isAddTileVisible && (
        <CreateClosingTile
          shopId={shopId}
          closeTile={() => setIsAddTileVisible(false)}
          shopTimezone={shopTimezone}
        />
      )}
      <ContentTile
        className={styles.tableCard}
        title={
          <div className={styles.tileHeader}>
            <h1 className={styles.title}>Closings</h1>
            <TooltipIconButton label="More about about closings">
              This is your custom schedule. It will overwrite your weekly
              schedule. Feel free to add a closing for a day or specific date
              range. To add a date, please click on the {"'Add Closing'"}{' '}
              button.
            </TooltipIconButton>
          </div>
        }
      >
        <ClosingsTable
          shopId={shopId}
          activeClosingId={activeClosingId}
          setActiveClosingId={handleEditTileToggle}
          shopTimezone={shopTimezone}
        />
      </ContentTile>
    </div>
  );
};
