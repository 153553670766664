import { Phone } from 'components/phone';
import { NotFoundError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

export const PhonePage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const {
    isEnabled: isPhonePageEnabled,
    isLoading: isPhonePageFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.PhonePage);

  let content;
  if (isPhonePageFeatureFlagLoading || isShopLoading) {
    content = <Loading />;
  } else if (!isPhonePageEnabled || isShopError) {
    content = <NotFoundError />;
  } else {
    content = <Phone shop={shop} />;
  }

  return (
    <Page name="Phone" title="Phone">
      {content}
    </Page>
  );
};
