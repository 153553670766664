import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Logomark } from 'crust';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { usePasswordResetMutation } from 'hooks/password-reset/use-password-reset-mutation';
import { emailAddresses, phoneNumbers } from 'utilities/constants';
import { showInvalidSubmitToast } from 'utilities/forms';
import { formatPhoneNumber } from 'utilities/strings';

import { getPasswordResetErrorMessage } from './utilities';

import styles from './styles.module.scss';

const PasswordResetForm = ({ email = '', passwordResetToken = '' }) => {
  const navigate = useNavigate();

  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  useEffect(() => {
    if (!passwordResetToken) {
      // No token passed so they so they will not
      // be able to reset their password. redirect
      // them to the login screen
      navigate('/');
    }
  }, [navigate, passwordResetToken]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
      password: '',
      shouldRemember: false,
    },
    mode: 'onBlur',
  });

  const {
    mutate: putPasswordReset,
    error: resetPasswordError,
    isPending: isPasswordResetLoading,
  } = usePasswordResetMutation();
  const resetPassword = (values) =>
    putPasswordReset(
      { password: values.password, passwordResetToken },
      {
        onSuccess: () => {
          setPasswordResetSuccess(true);
        },
      },
    );

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <Logomark className={styles.logo} />
        <div className={styles.resetBox}>
          <div className={styles.primaryTitleText}>{'Create an account'}</div>
          <div className={styles.secondaryText}>
            View orders, check-in on sales, and more in your restaurant account.
          </div>

          {passwordResetSuccess ? (
            <>
              <div id="alert" className={styles.passwordSuccessText}>
                {'Success! Your password has been reset.'}
              </div>
              <Button
                className={styles.submitButton}
                onClick={() => navigate('/')}
              >
                Login
              </Button>
            </>
          ) : (
            <>
              <div id="alert" className={styles.sliceAlert}>
                {resetPasswordError &&
                  getPasswordResetErrorMessage(resetPasswordError)}
              </div>
              <form
                onSubmit={handleSubmit(resetPassword, showInvalidSubmitToast)}
              >
                <div className={styles.formField}>
                  <Label htmlFor="email-input">Email</Label>
                  <Input
                    id="email-input"
                    inputMode="email"
                    isInvalid={errors.email != null}
                    readOnly={true}
                    {...register('email')}
                  />
                </div>
                <div className={styles.formField}>
                  <Label htmlFor="password-input">{'Create Password'}</Label>
                  <Input
                    id="password-input"
                    isInvalid={errors.password != null}
                    type="password"
                    {...register('password', {
                      minLength: {
                        value: 8,
                        message: 'Passwords have at least 8 characters.',
                      },
                      required: 'Please enter your password.',
                    })}
                  />
                  <FormFeedback>{errors.password?.message}</FormFeedback>
                </div>
                <Button
                  id="reset-submit-button"
                  variant={ButtonVariant.Primary}
                  className={styles.submitButton}
                  type="submit"
                  disabled={isPasswordResetLoading}
                >
                  Submit
                </Button>
              </form>
            </>
          )}

          <div className={styles.bottomText}>
            Need to create an account? Reach out!
            <a
              className={styles.link}
              href={`mailto:${emailAddresses.partner}`}
            >
              {emailAddresses.partner}
            </a>
            <a
              className={styles.link}
              href={`tel:${phoneNumbers.accountManagers}`}
            >
              {formatPhoneNumber(phoneNumbers.accountManagers)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordResetForm.propTypes = {
  email: PropTypes.string,
  passwordResetToken: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PasswordResetForm;
