import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ContentTile from 'components/shared/content-tile';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import { useSaveSmartButtonsSettingsMutation } from 'hooks/website-tools/use-save-smart-buttons-settings';
import { Shop, ShopTraits } from 'types/shops';
import { User } from 'types/user';
import {
  ButtonLayout,
  SmartButtonFormValues,
  SmartButtonStorageKey,
  WebsiteIntegrationColor,
  WebsiteIntegrationsAnalyticsPageName,
  WebsiteIntegrationsSettings,
  WidgetType,
} from 'types/website-tools';
import { showInvalidSubmitToast } from 'utilities/forms';
import {
  getIsPromoMessageAvailableForShop,
  getSmartButtonsAnalyticsValuesForSaveRequest,
  getSmartButtonsDefaultFormValuesFromSettings,
  isSmartButtonsFirstTimeSetup,
} from 'utilities/website-tools';

import SmartButtonForm from '../form';
import SmartButtonPreview from '../smart-button-preview';

import styles from './styles.module.scss';

type Props = {
  onSave: () => void;
  shop: Shop;
  smartButtonSettings: WebsiteIntegrationsSettings;
  shopTraits: ShopTraits;
  page: WebsiteIntegrationsAnalyticsPageName;
  isMultiLocEnabled: boolean;
  user: User;
};

const SmartButtonConfiguration = ({
  onSave,
  shop,
  smartButtonSettings,
  shopTraits,
  page,
  isMultiLocEnabled,
  user,
}: Props) => {
  const { trackSavedSmartButtonsSettings } = useAnalytics();
  const [shopUrl, setShopUrl] = useStoredState<string>(
    SmartButtonStorageKey.siteUrl,
    '',
  );
  const [isFormChanged, setIsFormChanged] = useState(false);

  const isPromoMessageAvailable = getIsPromoMessageAvailableForShop(shop);

  const isFirstTimeSetup = isSmartButtonsFirstTimeSetup(smartButtonSettings);

  const defaultFormValues = getSmartButtonsDefaultFormValuesFromSettings(
    smartButtonSettings,
    isPromoMessageAvailable,
  );

  const {
    control,
    formState: { isSubmitting },
    getValues,
    handleSubmit,
    reset: resetForm,
    setValue,
  } = useForm<SmartButtonFormValues>({
    defaultValues: defaultFormValues,
  });

  const {
    data: updatedSettings,
    mutate: saveSettings,
    isError: isSmartButtonRejected,
    isPending: isSmartButtonSaving,
    isSuccess: isSmartButtonSaved,
    reset: resetMutation,
  } = useSaveSmartButtonsSettingsMutation(shop.shopId);

  useEffect(() => {
    if (isSmartButtonSaved && updatedSettings) {
      toast.success(
        "Settings saved. Be sure you've added the one-time code to your site.",
      );

      // Reset the default values of the form to the newly saved values so that
      // the form is no longer dirty and the form state is reset. Since the form
      // remains visible, we want the submit button to be disabled to help convey
      // that the settings have been saved and to prevent multiple submissions on the same values.
      // We check to see what the smart button was saved as in order to reset the form defaults either as
      // a button/sticker or banner.
      if (updatedSettings.floatingButtonEnabled) {
        let format;
        if (updatedSettings.floatingButtonLayout) {
          format =
            updatedSettings.floatingButtonLayout === ButtonLayout.Button
              ? WidgetType.Button
              : WidgetType.Sticker;
        } else {
          format = defaultFormValues.format;
        }
        resetForm({
          color:
            (updatedSettings.floatingButtonThemeColor as WebsiteIntegrationColor) ??
            defaultFormValues.color,
          format,
          mode:
            updatedSettings.floatingButtonThemeVariant ??
            defaultFormValues.mode,
          position:
            updatedSettings.floatingButtonPosition ??
            defaultFormValues.position,
          promoMessage:
            updatedSettings.floatingButtonValueMessage ??
            defaultFormValues.promoMessage,
          theme: updatedSettings.floatingButtonTheme ?? defaultFormValues.theme,
        });
      } else {
        resetForm({
          color:
            (updatedSettings.navBarThemeColor as WebsiteIntegrationColor) ??
            defaultFormValues.color,
          format: WidgetType.Banner,
          mode: updatedSettings.navBarThemeVariant ?? defaultFormValues.mode,
          position:
            updatedSettings.navBarPosition ?? defaultFormValues.position,
          promoMessage:
            updatedSettings.navBarValueMessage ??
            defaultFormValues.promoMessage,
          theme: updatedSettings.navBarTheme ?? defaultFormValues.theme,
        });
      }

      setIsFormChanged(false);
      resetMutation();
    }
  }, [
    defaultFormValues,
    isSmartButtonSaved,
    resetForm,
    resetMutation,
    updatedSettings,
  ]);

  useEffect(() => {
    if (isSmartButtonRejected) {
      toast.error('Settings not saved. Please try again.');
    }
  }, [isSmartButtonRejected]);

  const handleValidSubmit = (values: SmartButtonFormValues) =>
    saveSettings(values, {
      // We include this onSuccess to show the first time setup as this will run before the
      // cache update that would make the form think we are no longer in a first time setup
      // state, due to the cached value having valid values.
      onSuccess: (_, variables) => {
        if (isFirstTimeSetup) {
          onSave();
        }

        trackSavedSmartButtonsSettings(
          getSmartButtonsAnalyticsValuesForSaveRequest({
            shopId: shop.shopId,
            formValues: variables,
            url: shopUrl ?? '',
            success: true,
            isFirstTimeSetup,
            page,
            isActive: String(shopTraits.isSmartButtonActive),
          }),
        );
      },
      onError: (_, variables) => {
        trackSavedSmartButtonsSettings(
          getSmartButtonsAnalyticsValuesForSaveRequest({
            shopId: shop.shopId,
            formValues: variables,
            url: shopUrl ?? '',
            success: false,
            isFirstTimeSetup,
            page,
            isActive: String(shopTraits.isSmartButtonActive),
          }),
        );
      },
    });

  const isSaveDisabled =
    !isFirstTimeSetup &&
    (isSubmitting || isSmartButtonSaving || !isFormChanged);

  return (
    <ContentTile className={styles.contentTile}>
      <SmartButtonForm
        control={control}
        getValues={getValues}
        handleFormSubmit={handleSubmit(
          handleValidSubmit,
          showInvalidSubmitToast,
        )}
        isPromoMessageAvailable={isPromoMessageAvailable}
        isSaveDisabled={isSaveDisabled}
        isSubmitting={isSubmitting}
        setIsFormChanged={setIsFormChanged}
        setValue={setValue}
        shopInfo={shop}
        shopTraits={shopTraits}
        isMultiLocEnabled={isMultiLocEnabled}
        user={user}
      />
      <div className={styles.previewContainer}>
        <SmartButtonPreview
          control={control}
          shopId={shop.shopId}
          shopUuid={shop.uuid}
          shopUrl={shopUrl}
          setShopUrl={setShopUrl}
          shopTraits={shopTraits}
          page={page}
          isMultiLocEnabled={isMultiLocEnabled}
        />
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartButtonConfiguration;
