import PropTypes from 'prop-types';

import BarChart from 'components/shared/charts/bar';
import Legend from 'components/shared/charts/legend';
import Placeholder from 'components/shared/charts/placeholder';
import { toMoneyString } from 'utilities/currency';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

const SalesByDayBarChart = ({
  data = [],
  highlight = '',
  isEmpty = false,
  legend = [],
}) => (
  <>
    {isEmpty ? (
      <Placeholder />
    ) : (
      <div className={styles.content}>
        <div className={styles.info}>
          <div>
            <div className={styles.highlightLabel}>Best day</div>
            <div className={styles.highlightValue}>{highlight}</div>
          </div>
          <Legend content={legend} isHorizontal />
        </div>
        <BarChart
          className={styles.bar}
          data={data}
          groupMode="grouped"
          keys={['actual', 'average']}
          indexBy="day"
          margin={{ top: 5, right: 0, bottom: 30, left: 50 }}
          colorsFunc={({ id, data }) => data[`${id}Color`]}
          tooltipFunc={({ id, value }) => (
            <strong>
              {capitalize(id)} : {toMoneyString(value)}
            </strong>
          )}
          axisLeft={{
            format: (value) => `${toMoneyString(value).slice(0, -3)}`,
          }}
          axisBottom={{}}
        />
      </div>
    )}
  </>
);

SalesByDayBarChart.propTypes = {
  data: PropTypes.array,
  highlight: PropTypes.string,
  isEmpty: PropTypes.bool,
  legend: PropTypes.array,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SalesByDayBarChart;
