import MenuCategory from 'components/menu/category';
import CategoryPageHeader from 'components/menu/category/page-header';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCategoryQuery } from 'hooks/menu';
import { useCurrentCategoryId } from 'hooks/menu/use-current-category-id';
import { useCurrentShopId } from 'hooks/shops';

const MenuCategoryPage = () => {
  const shopId = useCurrentShopId();
  const categoryId = useCurrentCategoryId();

  const isNew = categoryId === 'new';
  const name = `${isNew ? 'add' : 'edit'}-menu-category`;

  // -1 is a placeholder that will not be used in an active query and helps
  // avoid more complex changes to satisfy the typing of useCategoryQuery.
  const {
    data: original,
    isError: isCategoryError,
    isPending: isCategoryLoading,
  } = useCategoryQuery({
    parameters: { shopId, categoryId: isNew ? -1 : categoryId },
    options: {
      isEnabled: !isNew,
      staleTime: Infinity,
    },
  });

  let content;
  if (!isNew && isCategoryLoading) {
    content = <Loading />;
  } else if (isCategoryError) {
    content = <UnexpectedError />;
  } else {
    content = <MenuCategory original={original} shopId={shopId} />;
  }

  return (
    <Page
      name={name}
      customPageHeader={
        <CategoryPageHeader
          categoryId={categoryId}
          count={original?.category.productIds?.length}
          isLoading={isCategoryLoading}
          name={original?.category.name}
          shopId={shopId}
        />
      }
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuCategoryPage;
