import RegisterProfileContent from 'components/register/register-profiles';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useRegisterProfilesQuery } from 'hooks/register/use-register-profiles-query';
import { useCurrentShopId } from 'hooks/shops';

const RegisterProfiles = () => {
  const shopId = useCurrentShopId();
  const {
    data: registerProfiles,
    isPending,
    isError,
  } = useRegisterProfilesQuery(shopId);

  let content;
  if (isPending) {
    content = <Loading />;
  } else if (isError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <RegisterProfileContent
        registerProfiles={registerProfiles}
        shopId={shopId}
      />
    );
  }

  return (
    <Page name="system-profiles" title="System Profiles">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterProfiles;
