import PropTypes from 'prop-types';

import CustomerPie from './customer-pie-tile';
// import CustomersByWeek from './customers-by-week';
import MostValuableCustomers from './most-valuable-customers';

const CustomerAnalytics = ({
  shopId,
  startDate,
  endDate,
  orderType = 'online',
}) => {
  return (
    <>
      <CustomerPie
        shopId={shopId}
        startDate={startDate}
        endDate={endDate}
        orderType={orderType}
      />
      {/*
        Temporarily removing customers bar chart:
        https://app.shortcut.com/slicelife/epic/228543/update-existing-analytics-tiles-to-last-full-week?
        CustomersByWeek shopId={shopId} shopTimezone={shop?.timezoneIdentifier} />
      */}
      <MostValuableCustomers
        shopId={shopId}
        startDate={startDate}
        endDate={endDate}
        orderType={orderType}
      />
    </>
  );
};

CustomerAnalytics.propTypes = {
  shopId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerAnalytics;
