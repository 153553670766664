import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import CategorySelect from 'components/shared/category-select';
import Label from 'components/shared/label';
import Modal from 'components/shared/slice-modal';
import Select from 'components/shared/slice-select';
import { useProductQuery } from 'hooks/menu';
import {
  AnalyticsField,
  copyFormCustomizations,
  CustomizationField,
  getFormCustomizations,
  ProductField,
  ProductTypeField,
} from 'utilities/menu';

import Customization from './customization';

import styles from './styles.module.scss';

const AddProductCustomizationsModal = ({
  appendCustomizations,
  categoriesById,
  categoryOptions,
  getValues,
  isOpen,
  onClose,
  productId,
  productsById,
  setValue,
  shopId,
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedCustomizationIndexes, setSelectedCustomizationIndexes] =
    useState([]);

  const productOptions = useMemo(
    () =>
      categoriesById[selectedCategoryId]?.productIds
        ?.filter((it) => String(it) !== productId)
        .map((it) => ({ label: productsById[it].name, value: it })) ?? [],
    [categoriesById, productId, productsById, selectedCategoryId],
  );

  const { data: productResponse } = useProductQuery({
    parameters: { shopId, productId: String(selectedProductId) },
    options: { isEnabled: selectedProductId != null },
  });

  const customizations = useMemo(() => {
    if (productResponse?.relationships?.addons == null) {
      return [];
    }

    const currentProductTypes = getValues(ProductField.ProductTypes);
    const currentCustomizations = getValues(ProductField.Customizations);

    const names = new Set(currentCustomizations.map((it) => it.name));
    const hasByHalf = currentCustomizations.some((it) => it.isByHalf);

    return getFormCustomizations(productResponse).filter((it) => {
      return !(
        names.has(it.name) ||
        (it.isByHalf && hasByHalf) ||
        (it.isByType &&
          productResponse.relationships.productTypes.length !==
            currentProductTypes.length)
      );
    });
  }, [getValues, productResponse]);

  useEffect(() => {
    if (isOpen) {
      setSelectedCategoryId(getValues(ProductField.CategoryId));
    } else {
      setSelectedCategoryId(null);
      setSelectedProductId(null);
      setSelectedCustomizationIndexes([]);
    }
  }, [getValues, isOpen]);

  // Always default to first product in the selected category.
  useEffect(() => {
    if (selectedProductId == null) {
      setSelectedProductId(productOptions[0]?.value ?? null);
    }
  }, [selectedProductId, productOptions]);

  // Misc
  const handleOnClose = () => {
    onClose();
  };

  const handleCategorySelected = (option) => {
    setSelectedCategoryId(option.value);
    setSelectedProductId(null);
    setSelectedCustomizationIndexes([]);
  };

  const handleProductSelected = (option) => setSelectedProductId(option.value);

  const handleCustomizationChecked = (index) => {
    const indexes = isCustomizationChecked(index)
      ? selectedCustomizationIndexes.filter((it) => it !== index)
      : selectedCustomizationIndexes.concat(index);

    setSelectedCustomizationIndexes(indexes);
  };

  const getSelectedCategory = () =>
    categoryOptions.find((option) => option.value === selectedCategoryId);

  const getSelectedProduct = () =>
    productOptions.find((option) => option.value === selectedProductId);

  const isCustomizationChecked = (index) =>
    selectedCustomizationIndexes.includes(index);

  const handleSubmit = () => {
    const selectedCustomizations = customizations.filter((_, index) =>
      isCustomizationChecked(index),
    );

    appendCustomizations(
      copyFormCustomizations(
        selectedCustomizations,
        productResponse.product.productTypeIds,
        getValues(ProductField.ProductTypes).map(
          (it) => it[ProductTypeField.Id],
        ),
      ),
    );

    setValue(AnalyticsField.CustomizationAdded, true);
    handleOnClose();
  };

  return (
    <Modal
      header="Add Customizations"
      isOpen={isOpen}
      isYesButtonDisabled={selectedCustomizationIndexes.length === 0}
      onClickNo={handleOnClose}
      onClickYes={handleSubmit}
      onRequestClose={handleOnClose}
      yesButtonText="Add"
    >
      <div className={styles.wrapper}>
        Suggested products to select customizations from:
        <div className={styles.selectWrapper}>
          <Label
            htmlFor="add-customizations-select-category"
            id="add-customizations-select-category-label"
          >
            Categories
          </Label>
          <CategorySelect
            placeholder="Select"
            inputId="add-customizations-select-category"
            options={categoryOptions.filter((it) => it.length > 0)}
            onChange={handleCategorySelected}
            value={getSelectedCategory()}
            menuPosition="fixed"
            aria-labelledby="add-customizations-select-category-label"
          />
        </div>
        <div className={styles.selectWrapper}>
          <Label
            htmlFor="add-customizations-select-product"
            id="add-customizations-select-product-label"
          >
            Products
          </Label>
          <Select
            placeholder="Select"
            inputId="add-customizations-select-product"
            options={productOptions}
            onChange={handleProductSelected}
            value={getSelectedProduct()}
            menuPosition="fixed"
            aria-labelledby="add-customizations-select-product-label"
          />
        </div>
        {selectedProductId != null && (
          <>
            <label className={styles.label}>
              {customizations.length > 0
                ? 'Available customizations to add to your item:'
                : "This product doesn't have any customizations. Please try another one."}
            </label>
            <div className={styles.customizationsWrapper}>
              {customizations.map((customization, index) => (
                <Customization
                  customization={customization}
                  isChecked={isCustomizationChecked(index)}
                  key={customization[CustomizationField.Name]}
                  onChange={() => handleCustomizationChecked(index)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

AddProductCustomizationsModal.propTypes = {
  appendCustomizations: PropTypes.func.isRequired,
  categoriesById: PropTypes.object.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      length: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  getValues: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  productsById: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddProductCustomizationsModal;
