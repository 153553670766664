import PropTypes from 'prop-types';

import { Icon } from 'crust';

import { getNextDay, nowDateTime } from 'utilities/date-time';

import Hours from './hours';
import Tile from './tile';
import UpcomingClosings from './upcoming-closings';

import styles from './styles.module.scss';

const HoursOverview = ({
  isUpdateInProgress = false,
  nextClosing = null,
  onHoursFormSubmit = () => {},
  shopId = '',
  shopDailyHours = {},
  shopTimezone = '',
  todaysClosing = null,
  trackDailyHoursActions = () => {},
}) => {
  const today = nowDateTime(shopTimezone);
  const tomorrow = getNextDay(today);
  const effectiveDateToday = today?.format('YYYY-MM-DD');
  const effectiveDateTomorrow = tomorrow?.format('YYYY-MM-DD');

  return (
    <div className={styles.container}>
      <div className={styles.tiles}>
        <div className={styles.tileColumn}>
          <Tile
            title={`Today's Hours (${today.format('ddd, MMM D')})`}
            icon={<Icon icon="clock" size="large" />}
          >
            <Hours
              effectiveDate={effectiveDateToday}
              hours={shopDailyHours?.today}
              isUpdateInProgress={isUpdateInProgress}
              onDailyHoursSubmit={(hours, shippingType) =>
                onHoursFormSubmit({
                  effectiveDate: effectiveDateToday,
                  hours,
                  shippingType,
                  todayOrTomorrow: 'today',
                })
              }
              shopTimezone={shopTimezone}
              tileId="today"
              trackDailyHoursActions={(
                buttonClicked,
                shippingType,
                timeRange,
              ) =>
                trackDailyHoursActions(
                  buttonClicked,
                  shippingType,
                  effectiveDateToday,
                  'today',
                  timeRange,
                )
              }
              labelPrefix="Today's hours for "
              dataTestId="todays-hours"
            />
          </Tile>
          <Tile
            title={`Tomorrow's Hours (${tomorrow.format('ddd, MMM D')})`}
            icon={<Icon icon="clock" size="large" />}
          >
            <Hours
              effectiveDate={effectiveDateTomorrow}
              hours={shopDailyHours?.tomorrow}
              isUpdateInProgress={isUpdateInProgress}
              onDailyHoursSubmit={(hours, shippingType) =>
                onHoursFormSubmit({
                  effectiveDate: effectiveDateTomorrow,
                  hours,
                  shippingType,
                  todayOrTomorrow: 'tomorrow',
                })
              }
              shopTimezone={shopTimezone}
              tileId="tomorrow"
              trackDailyHoursActions={(
                buttonClicked,
                shippingType,
                timeRange,
              ) =>
                trackDailyHoursActions(
                  buttonClicked,
                  shippingType,
                  effectiveDateTomorrow,
                  'tomorrow',
                  timeRange,
                )
              }
              labelPrefix="Tomorrow's hours for "
              dataTestId="tomorrows-hours"
            />
          </Tile>
        </div>
        <div className={styles.tileColumn}>
          <Tile
            title="Scheduled Closings"
            icon={<Icon icon="lock" size="large" />}
            canStretch
          >
            <UpcomingClosings
              nextClosing={nextClosing}
              todaysClosing={todaysClosing}
              shopId={shopId}
            />
          </Tile>
        </div>
      </div>
    </div>
  );
};

HoursOverview.propTypes = {
  isUpdateInProgress: PropTypes.bool,
  nextClosing: PropTypes.object,
  onHoursFormSubmit: PropTypes.func,
  shopDailyHours: PropTypes.object,
  shopTimezone: PropTypes.string,
  todaysClosing: PropTypes.object,
  trackDailyHoursActions: PropTypes.func,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HoursOverview;
