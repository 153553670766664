import { toast } from 'react-toastify';

import ContentTile from 'components/shared/content-tile';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import MegaphoneIcon from 'images/megaphone2.svg?react';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { User } from 'types/user';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  user: User;
};

const RenewYourCampaignTile = ({ shopId, user }: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const {
    mutate: createSalesforceCase,
    isPending: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const handleGetStartedClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.AMS,
            subject: SalesforceSubject.AMS,
          },
          note: SalesforceNote.AdvancedMarketingServicesNote,
          shopId: Number(shopId),
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () =>
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            ),
        },
      );
    }

    trackProductUpsellLinkClick({
      shopId: shopId,
      product: 'advanced marketing services',
      page: 'campaign manager canceled/lapsed',
      linkLabel: 'renew campaign',
      type: ClickedExternalLinkType.CTA,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <ContentTile className={styles.tile} dataTestId="renew-your-campaign-tile">
      <MegaphoneIcon className={styles.megaphoneIcon} />
      <div className={styles.container}>
        <div className={styles.title}>Renew Your Campaign</div>
        <div className={styles.content}>
          {`Don't stop the momentum - renew your campaign now and continue growing
          your online customer base.`}
        </div>
      </div>

      <Button
        className={styles.renewCampaignButton}
        onClick={() => handleGetStartedClick()}
        variant={ButtonVariant.Transparent}
      >
        <span>Renew Campaign</span>
      </Button>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RenewYourCampaignTile;
