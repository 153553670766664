import { useEffect } from 'react';

import { HoursAnalytics } from 'components/hours-analytics';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import useHoursAnalyticsSummaryQuery from 'hooks/hours-analytics/use-hours-analytics-summary';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';

export const HoursAnalyticsPage = () => {
  const shopId = useCurrentShopId();
  const { trackLoadedHoursAnalyticsPage } = useAnalytics();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const {
    data: summaryData,
    isPending: isSummaryDataLoading,
    isError: isSummaryDataError,
  } = useHoursAnalyticsSummaryQuery({
    shopId,
    timezone: shop?.timezoneIdentifier as string,
    options: { enabled: !isShopLoading && !!shop?.timezoneIdentifier },
  });

  useEffect(() => {
    if (summaryData) {
      const last30DaysAvailability =
        summaryData.last30Days.availabilityScoreRange ?? 'calculating';
      const last30DaysAvailabilityPercentage = summaryData.last30Days
        .availabilityScore
        ? summaryData.last30Days.availabilityScore + '%'
        : '0%';

      trackLoadedHoursAnalyticsPage({
        shopId,
        last30DaysAvailability,
        last30DaysAvailabilityPercentage,
      });
    }
  }, [summaryData, shopId, trackLoadedHoursAnalyticsPage]);

  let content;
  if (isShopLoading || isSummaryDataLoading) {
    content = <Loading />;
  } else if (isShopError || isSummaryDataError) {
    content = <UnexpectedError />;
  } else {
    content = <HoursAnalytics shop={shop} summaryData={summaryData} />;
  }

  return (
    <Page name="hours analytics" title="Hours Analytics">
      {content}
    </Page>
  );
};
