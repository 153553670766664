import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  createLegacyMenuCopyWithPreviousAvailability,
  createLegacyMenuCopyWithUpdatedAvailability,
  createMenuCopyWithPreviousAvailability,
  createMenuCopyWithUpdatedAvailability,
  formatUnavailableUntilString,
} from 'components/menu/products/utilities';
import { getMenuQueryKey, getProductQueryKey } from 'hooks/menu';
import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  MenuProductsResponseBody,
  MenuResponseBody,
  MutationParams,
} from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';

import { getMenuProductsQueryKey } from './use-menu-products-query';

type ErrorResponseBody = {
  messages: {
    meta: {
      products: {
        id: MenuProduct['id'];
      }[];
    };
  }[];
};

type MutationData = {
  productIds: MenuProduct['id'][];
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const useUpdateProductsStockMutation = ({
  productIds,
  shopId,
  shopTimezone,
}: MutationData) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: MutationParams) => {
      const unavailableUntil = formatUnavailableUntilString(
        values.date,
        shopTimezone,
      );

      const data = {
        products: productIds.map((id) => ({
          id,
          stock: {
            unavailable: values.isUnavailable,
            unavailableUntil,
          },
        })),
      };

      await authenticatedFetch.put(
        `api/management/v2/shops/${shopId}/menus/products/stock`,
        data,
      );
    },
    onMutate: async (values) => {
      await queryClient.cancelQueries({
        queryKey: getMenuQueryKey({ shopId }),
      });

      const previousLegacyMenuData = queryClient.getQueryData(
        getMenuQueryKey({ shopId }),
      ) as MenuResponseBody;
      const previousMenuData = queryClient.getQueryData(
        getMenuProductsQueryKey({ shopId }),
      ) as MenuProductsResponseBody;

      queryClient.setQueryData(
        getMenuQueryKey({ shopId }),
        createLegacyMenuCopyWithUpdatedAvailability(
          productIds,
          values,
          shopTimezone,
        ),
      );

      queryClient.setQueryData(
        getMenuProductsQueryKey({ shopId }),
        createMenuCopyWithUpdatedAvailability(productIds, values, shopTimezone),
      );

      return { previousLegacyMenuData, previousMenuData };
    },
    onSuccess: () => {
      for (const id of productIds) {
        queryClient.invalidateQueries({
          queryKey: getProductQueryKey({ shopId, productId: id }),
        });
      }
    },
    onError: async (error: ApiRequestError, _data, context) => {
      // For handling errors from bulk editing, there is a potential for a 400 error to give us back
      // the product ids of the products that were not updated due to a partial update.
      // In this case we reset the cached menu data manually to show the correct UI matching the known server state.
      // Other errors such as timeouts unfortunately also have potential to cause partial updates but no way of us knowing the
      // server state without refetching the menu data.
      // In summary we try to check for product IDs in the error response we know for certain were not updated and manually
      // edit the cache to reflect this. In other cases we invalidate the menu query in order to refetch the data.
      if (error.status === 500) {
        return await queryClient.invalidateQueries({
          queryKey: getMenuQueryKey({ shopId }),
        });
      }

      const previousLegacyMenuData = context?.previousLegacyMenuData;
      const previousMenuData = context?.previousMenuData;

      // An error status of 400 may have messages with the specific product IDs that were not successfully
      // updated, so we want to handle that case by updating the query cache and resetting the
      // unsuccessful product IDs availability. Non 400 status we will invalidate the menu data
      if (error.status === 400) {
        // For each id in the error response, reset the unavailable and unavailableUntil to the
        // previous values.
        const idsFromError: MenuProduct['id'][] = [];

        (error.responseBody as ErrorResponseBody)?.messages?.forEach(
          (message) => {
            message?.meta?.products?.forEach((product) => {
              idsFromError.push(product?.id);
            });
          },
        );

        if (idsFromError.length > 0) {
          if (previousLegacyMenuData) {
            queryClient.setQueryData(
              getMenuQueryKey({ shopId }),
              createLegacyMenuCopyWithPreviousAvailability(
                idsFromError,
                previousLegacyMenuData,
              ),
            );
          }

          if (previousMenuData) {
            queryClient.setQueryData(
              getMenuProductsQueryKey({ shopId }),
              createMenuCopyWithPreviousAvailability(
                idsFromError,
                previousMenuData,
              ),
            );
          }

          return;
        }
      } else {
        if (previousLegacyMenuData) {
          queryClient.setQueryData(
            getMenuQueryKey({ shopId }),
            previousLegacyMenuData,
          );
        }

        if (previousMenuData) {
          queryClient.setQueryData(
            getMenuQueryKey({ shopId }),
            previousMenuData,
          );
        }
      }
    },
  });
};
