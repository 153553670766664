import { ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type HeaderProps = {
  content?: ReactNode[];
  title: string;
  containerClass?: string;
  contentClass?: string;
};

const Header = ({
  content = [],
  title,
  containerClass = '',
  contentClass = '',
}: HeaderProps) => (
  <div className={containerClass}>
    <h1 className={styles.title}>{title}</h1>
    {content.map((paragraph, index) => (
      <p
        className={cx(styles.paragraph, contentClass)}
        key={`paragraph-${index}`}
      >
        {paragraph}
      </p>
    ))}
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Header;
