import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { UberEatsLocations } from 'components/uber-eats-locations';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const UberEatsLocationsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    error: shopQueryError,
    isPending: isShopQueryLoading,
  } = useShopQuery(shopId);

  let content;
  if (isShopQueryLoading) {
    content = <Loading />;
  } else if (shop == null || shopQueryError) {
    content = <UnexpectedError />;
  } else {
    content = <UberEatsLocations shopId={shopId} />;
  }

  return (
    <Page name="uberEatsLocation" title="Uber Eats Locations">
      {content}
    </Page>
  );
};
