import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { UpdateClosingResponseBody } from 'types/hours/closings';
import { Shop } from 'types/shops';
import { formatClosingForTable } from 'utilities/closings';
import { camelCaseKeys } from 'utilities/objects';

import { CachedClosingsData } from './use-create-closings-mutation';

type Values = {
  closingsParams: {
    closure_reason_id: number;
    end_date: string;
    start_date: string;
    reason: string;
    shipping_type: string;
  };
  closingId: number;
};

export const useUpdateClosingsMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ closingsParams, closingId }: Values) => {
      const response = await authenticatedFetch.put(
        `api/management/v1/shops/${shopId}/closings/${closingId}`,
        {
          closing: {
            start_date: closingsParams.start_date,
            end_date: closingsParams.end_date,
            reason: closingsParams.reason,
            shipping_type: closingsParams.shipping_type,
            closure_reason_id: closingsParams.closure_reason_id,
          },
        },
      );

      return (camelCaseKeys(response) as UpdateClosingResponseBody).data;
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [shopId, 'closings'],
        (cachedClosingsData: CachedClosingsData[] | undefined) => {
          if (cachedClosingsData) {
            const updatedClosing = formatClosingForTable(response);
            return cachedClosingsData.map((closing) =>
              closing.id === updatedClosing.id ? updatedClosing : closing,
            ) as CachedClosingsData[];
          }
        },
      );

      // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
      // such as next_open_at which may be impacted by closing updates
      queryClient.invalidateQueries({
        queryKey: [shopId, 'openForToday'],
      });
    },
  });
};
