import { ReactNode } from 'react';

import styles from './styles.module.scss';

type NavigationSectionProps = {
  children: ReactNode;
};

export const NavigationSection = ({ children }: NavigationSectionProps) => (
  <div className={styles.section}>{children}</div>
);
