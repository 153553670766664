import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductPrintSettingsResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { UseQueryParameters } from 'types/queries';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import { getProductQueryKey } from './use-product-query';

type ProductPrintSettingsQueryParameters = {
  shopId: Shop['shopId'];
  productId: MenuProduct['id'];
};

type ProductPrintSettingsQueryOptions = {
  staleTime: number;
};

export const getProductPrintSettingsQueryKey = ({
  shopId,
  productId,
}: ProductPrintSettingsQueryParameters) => [
  ...getProductQueryKey({ shopId, productId }),
  'printSettings',
];

export const useProductPrintSettingsQuery = ({
  parameters,
  options,
}: UseQueryParameters<
  ProductPrintSettingsQueryParameters,
  ProductPrintSettingsQueryOptions
>) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getProductPrintSettingsQueryKey(parameters),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${parameters.shopId}/menu/products/${parameters.productId}/print_settings`,
      );
      return camelCaseKeys(response) as MenuProductPrintSettingsResponseBody;
    },
    staleTime: options?.staleTime,
  });
};
