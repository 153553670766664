import uberEatslogo from 'images/integrations/uber-eats-logo.png';

import IntegrationListItem from '..';

import styles from './styles.module.scss';

type UberEatsIntegrationProps = {
  uberEatsRedirectUrl: string;
  isUberEatsConnected: boolean;
  openUberEatsDisconnectModal: () => void;
};

export const UberEatsIntegrationListItem = ({
  uberEatsRedirectUrl,
  isUberEatsConnected,
  openUberEatsDisconnectModal,
}: UberEatsIntegrationProps) => (
  <IntegrationListItem
    copyText={
      <>
        <p className={styles.copy}>
          View your Uber Eats orders directly on your Slice Register!
        </p>
        <p className={styles.copy}>
          Connect Uber Eats with Slice Register to automatically integrate menu
          updates and live order submissions.
        </p>
        <p className={styles.copy}>
          {isUberEatsConnected
            ? 'Note - to make changes to your integration configuration, please contact your account manager.'
            : 'Note - the Slice menu will override your pre-existing Uber Eats menu once connected.'}
        </p>
      </>
    }
    isConnected={isUberEatsConnected}
    onDisconnect={openUberEatsDisconnectModal}
    imageAltText="Uber Eats logo"
    imageSource={uberEatslogo}
    redirectUrl={uberEatsRedirectUrl}
    provider="uberEats"
  />
);
