import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';

import { Suspended } from 'components/shared/suspended';
import { useCurrentUserQuery } from 'hooks/shared/use-current-user-query';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';

import Alert from '../alert';

import PageFooter from './footer';
import PageHeader from './header';
import PageTitle from './title';

import styles from './styles.module.scss';

type Props = {
  customPageHeader?: ReactElement;
  isLoading?: boolean;
  isFooterShown?: boolean;
  isHeaderShown?: boolean;
  isNavShown?: boolean;
  name: string;
  title?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  mainClassName?: string;
  additionalTrackingProperties?: Record<string, boolean | string>;
};

const Page = ({
  children,
  customPageHeader,
  mainClassName,
  isLoading = false,
  isFooterShown = true,
  isHeaderShown = true,
  isNavShown = true,
  name,
  title = '',
  wrapperClassName = '',
  contentClassName = '',
  additionalTrackingProperties,
}: PropsWithChildren<Props>) => {
  const { trackPageLoad } = useAnalytics();
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth0();
  const shopId = useCurrentShopId();

  const { data: user } = useCurrentUserQuery({
    enabled: isAuthenticated,
  });
  const { data: activeShop } = useShopQuery(shopId, {
    enabled: isAuthenticated && !!shopId,
  });

  useEffect(() => {
    if (!isLoading) {
      trackPageLoad(name, {
        shopId: shopId,
        ...additionalTrackingProperties,
      });
    }
  }, [trackPageLoad, name, shopId, additionalTrackingProperties, isLoading]);

  return (
    <Suspended isLoading={isLoading || isLoadingAuth} variant="viewPort">
      <div className={cx(styles.wrapper, wrapperClassName)}>
        <div
          className={cx(
            isNavShown ? styles.mainWithNav : styles.main,
            mainClassName,
          )}
        >
          {user && user.userType === 'administrator' && (
            <>
              <Alert variant="warning">
                <p>
                  With great power comes great responsibility. You are now
                  {activeShop ? ' in' : ' entering'} a shop owner&apos;s back
                  office.
                </p>
              </Alert>
              {activeShop ? (
                <Alert variant="info">
                  <p>
                    {`You are logged in as ${user.firstName} to shop
                      ${activeShop.name}.`}
                  </p>
                </Alert>
              ) : null}
            </>
          )}
          {isHeaderShown &&
            (customPageHeader || (
              <PageHeader>
                <PageTitle>{title}</PageTitle>
              </PageHeader>
            ))}
          <div
            className={cx(
              isNavShown ? styles.content : styles.contentWithoutNav,
              contentClassName,
            )}
          >
            {children}
          </div>
          {isFooterShown && <PageFooter />}
        </div>
      </div>
    </Suspended>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Page;
