import PropTypes from 'prop-types';

import AnalyticsTile from 'components/shared/analytics-tile';
import { useSalesByDayMetricsQuery } from 'hooks/metrics/use-sales-by-day-metrics-query';
import { capitalize } from 'utilities/strings';

import SalesByDayBarChart from './bar-chart';

import styles from './styles.module.scss';

const DAY_ORDER = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const colorScheme = {
  actual: 'var(--op-color-graph1)',
  average: 'var(--op-color-graph3)',
};

const formatLegend = () =>
  Object.entries(colorScheme).map(([key, color]) => ({
    id: capitalize(key),
    color: color,
  }));

const formatData = (data) =>
  DAY_ORDER.map((dayName) => ({
    day: capitalize(dayName).substring(0, 3),
    actual: data[dayName]?.total / 100,
    average: data[dayName]?.average / 100,
    actualColor: colorScheme.actual,
    averageColor: colorScheme.average,
  }));

const findBestDay = (data) =>
  capitalize(
    Object.keys(data).reduce(
      (a, b) => (data[a]?.total > data[b]?.total ? a : b),
      'unknown',
    ),
  );

const isDataEmpty = (data) =>
  !Object.values(data).some(({ total, average }) => total > 0 || average > 0);

const SalesByDay = ({
  shopId = '',
  startDate,
  endDate,
  orderType = 'online',
}) => {
  const { data, isPending } = useSalesByDayMetricsQuery(shopId, {
    start: startDate,
    finish: endDate,
    orderType,
  });

  return (
    <AnalyticsTile
      data-chameleon-target="Order Metrics SalesByDay BarChart"
      title="Sales by Day"
      footerText={
        <>
          The <span className={styles.bold}>actual</span> sales represent
          earnings for the current week. The{' '}
          <span className={styles.bold}>average</span> sales represent earnings
          average for the last 4 weeks.
        </>
      }
    >
      <SalesByDayBarChart
        data={data ? formatData(data) : []}
        highlight={findBestDay(data ?? {})}
        isEmpty={isPending || isDataEmpty(data)}
        legend={formatLegend(data)}
      />
    </AnalyticsTile>
  );
};

SalesByDay.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SalesByDay;
