import { Link } from 'crust';

import useAnalytics from 'hooks/use-analytics';
import HeadphonesIcon from 'images/headphones.svg?react';
import { Shop } from 'types/shops';
import { getPsmEmailInfo } from 'utilities/psm-info';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  partnerName: string;
};

export const PsmInfoPopup = ({ shopId, partnerName }: Props) => {
  const { trackClickedEmailLink, trackClickedCallLink } = useAnalytics();

  const { subject, body } = getPsmEmailInfo(shopId.toString());

  const handleEmailLinkClick = (): void => {
    trackClickedEmailLink({
      shopId: shopId.toString(),
      destination: 'partnersuccess@slicelife.com',
      // For the analytics event, we want "general inquiry", rather than the
      // specific subject line.
      subject: 'general inquiry',
      page: 'navigation psm info',
      psmName: partnerName,
    });
  };

  const handleCallLinkClick = (): void => {
    trackClickedCallLink({
      shopId: shopId.toString(),
      page: 'navigation psm info',
      psmName: partnerName,
      phoneNumber: '(855) 237-4992',
    });
  };

  return (
    <>
      <HeadphonesIcon className={styles.headphonesIcon} />
      <div className={styles.headerText}>Connect with your Account Manager</div>
      <div className={styles.instructionsText}>
        Questions about how to get the most out of Slice? Issues with products?{' '}
        {"We're"} here to help you and your shop.
      </div>
      <Link
        href="tel:8552374992"
        icon="phone"
        onPress={handleCallLinkClick}
        variant="secondary"
      >
        855-237-4992
      </Link>
      <Link
        href={`mailto:partnersuccess@slicelife.com?subject=${subject}&body=${body}`}
        icon="website"
        onPress={handleEmailLinkClick}
        variant="secondary"
      >
        partnersuccess@slicelife.com
      </Link>
    </>
  );
};
