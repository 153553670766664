import { Menu, MenuButton } from '@szhsin/react-menu';

import useAnalytics from 'hooks/use-analytics';
import { usePsmInfoQuery } from 'hooks/use-psm-info';
import { Shop } from 'types/shops';

import { PsmInfoPopup } from './psm-info-popup';

import styles from './styles.module.scss';

type PsmInfoProps = {
  shopId: Shop['shopId'];
  verificationCode: string;
};

export const PsmInfo = ({ shopId, verificationCode }: PsmInfoProps) => {
  const { data } = usePsmInfoQuery(shopId.toString());
  const hasPartnerName = data?.firstName;
  const partnerName = hasPartnerName
    ? `${data?.firstName} ${data?.lastName}`
    : 'How can we help?';

  const { trackClickedPsmContactInfo } = useAnalytics();

  const handleClickedPsmContactInfo = (): void => {
    trackClickedPsmContactInfo({
      shopId: shopId.toString(),
      page: 'navigation',
      psmName: partnerName,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>Account Manager</div>
      <Menu
        align="start"
        arrow
        direction="right"
        menuClassName={styles.menu}
        position="anchor"
        menuButton={
          <MenuButton
            className={styles.menuButton}
            onClick={handleClickedPsmContactInfo}
          >
            {partnerName}
          </MenuButton>
        }
      >
        <PsmInfoPopup partnerName={partnerName} shopId={shopId} />
      </Menu>
      {verificationCode && (
        <div className={styles.pin}>PIN: {verificationCode}</div>
      )}
    </div>
  );
};
