import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetReportsRequestParams,
  GetReportsResponse,
  GetSalesMixReportsResponse,
  SalesMixReport,
} from 'types/financials';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { toSnakeCase } from 'utilities/strings';

type UseSalesMixReportsQueryOptions = Omit<
  UseQueryOptions<GetSalesMixReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getSalesMixReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'salesMixReports', ...(params ? [params] : [])];

export const useSalesMixReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseSalesMixReportsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    // Does not use leading "+".
    sort: `${sorting.desc ? '-' : ''}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getSalesMixReportsQueryKey(shopId, params),
    queryFn: async () => {
      const query = `${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/sales_mix_reports?${query}`,
      );

      return camelCaseKeys(response) as GetReportsResponse<SalesMixReport>;
    },
    placeholderData: keepPreviousData,
    ...options,
  });
};
