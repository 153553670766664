import PropTypes from 'prop-types';

import Link from 'components/shared/link';
import CaretIcon from 'images/caret.svg?react';

import styles from './styles.module.scss';

const Item = ({
  items = null,
  label = '',
  onClick = () => {},
  parentItemLabel = '',
  trackNavigationItemClick = () => {},
  url = '',
  dataTestId,
}) => {
  const isExternalLink = url.startsWith('http');

  const onItemClick = () => {
    onClick({ label, items });
  };

  const hasItems = items && items.length > 0;

  return hasItems ? (
    <button
      className={styles.item}
      onClick={onItemClick}
      data-testid={dataTestId}
    >
      {label}
      <div className={styles.wrapper}>
        <CaretIcon className={styles.icon} />
      </div>
    </button>
  ) : (
    <Link
      data-chameleon-target={`Nav. Item ${label}`}
      to={url}
      className={styles.item}
      onClick={() =>
        trackNavigationItemClick(
          parentItemLabel ? [parentItemLabel, label] : [label],
          isExternalLink,
        )
      }
      variant="undecorated"
      data-testid={dataTestId}
    >
      {label}
    </Link>
  );
};

Item.propTypes = {
  dataTestId: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  onClick: PropTypes.func,
  parentItemLabel: PropTypes.string,
  trackNavigationItemClick: PropTypes.func,
  url: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Item;
