import { toast } from 'react-toastify';

import Modal from 'components/shared/slice-modal';
import { useDeleteClosingsMutation } from 'hooks/hours/use-delete-closings-mutation';
import useAnalytics from 'hooks/use-analytics';
import { Closing } from 'types/hours/closings';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type DeleteModalProps = {
  activeClosingId: number;
  deletingRecord: Closing;
  setActiveClosingId: (id: number | null) => void;
  setDeletingRecord: (record: Closing | null) => void;
  shopId: Shop['shopId'];
};

export const DeleteModal = ({
  activeClosingId,
  deletingRecord,
  setActiveClosingId,
  setDeletingRecord,
  shopId,
}: DeleteModalProps) => {
  const { trackDeleteClosing } = useAnalytics();

  const { mutate: deleteClosing, isPending: isDeleteInProgress } =
    useDeleteClosingsMutation(shopId);

  const handleDeleteSubmit = () =>
    deleteClosing(deletingRecord.id, {
      onSuccess: () => {
        trackDeleteClosing(shopId, deletingRecord, true);
        if (activeClosingId === deletingRecord.id) {
          setActiveClosingId(null);
        }
        setDeletingRecord(null);
      },
      onError: () => {
        trackDeleteClosing(shopId, deletingRecord, false);
        setDeletingRecord(null);
        toast.error(
          'An error occurred while deleting this record. Try refreshing!',
        );
      },
    });

  return (
    <Modal
      header="Are you sure?"
      isOpen={deletingRecord ? true : false}
      isYesButtonDisabled={isDeleteInProgress}
      onClickNo={() => setDeletingRecord(null)}
      onClickYes={handleDeleteSubmit}
      onRequestClose={() => setDeletingRecord(null)}
      yesButtonText="Delete"
    >
      <div className={styles.modalContent}>
        {`You are about to delete your closing between ${deletingRecord?.startDate} and
        ${deletingRecord?.endDate}. This action cannot be undone.`}
      </div>
    </Modal>
  );
};
