import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Orders } from 'components/orders';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import { RegisterModeName } from 'types/shops';

const DigitalOrdersPage = () => {
  const shopId = useCurrentShopId();
  const { tab } = useParams();

  const additionalTrackingProperties = useMemo(
    () => ({
      tab: tab || 'online',
    }),
    [tab],
  );

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const isSliceOsMode = shop?.registerMode.name == RegisterModeName.Basic;

  let content;

  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <Orders
        rosEnabled={shop.rosEnabled}
        isSliceOsMode={isSliceOsMode}
        shopId={shopId.toString()}
        shopTimezone={shop.timezoneIdentifier}
      />
    );
  }

  return (
    <Page
      name="orders"
      title="Orders"
      additionalTrackingProperties={additionalTrackingProperties}
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DigitalOrdersPage;
