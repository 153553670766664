import { ShopReferral } from 'components/shop-referral';
import { Shop } from 'types/shops';

import { NavSection } from '../nav-items';

import { NavigationHeader } from './header';
import { NavigationItem } from './item';
import { NavigationSection } from './section';

import styles from './styles.module.scss';

type NavigationDesktopProps = {
  activeShop: Shop | undefined;
  isAdmin: boolean;
  navigationSections: NavSection[];
  shopId: Shop['shopId'];
  shops: Shop[];
  trackNavigationItemClick: (label: string[], isExternalLink: boolean) => void;
  verificationCode: string;
};

export const NavigationDesktop = ({
  activeShop,
  isAdmin = false,
  navigationSections,
  shopId,
  shops,
  trackNavigationItemClick,
  verificationCode,
}: NavigationDesktopProps) => {
  return (
    <div className={styles.wrapper}>
      <NavigationHeader
        activeShop={activeShop!}
        shopId={shopId}
        shops={shops}
        verificationCode={verificationCode}
        isAdmin={isAdmin}
      />
      <div className={styles.navContent}>
        {navigationSections.map((section) => (
          <NavigationSection key={section.name}>
            {section.items.map((item) => (
              <NavigationItem
                activeWith={item.activeWith}
                dataTestId={item.dataTestId}
                icon={item.icon}
                items={item.items}
                label={item.label}
                url={item.url}
                key={item.label}
                trackNavigationItemClick={trackNavigationItemClick}
              />
            ))}
          </NavigationSection>
        ))}
        <ShopReferral shopId={shopId} />
      </div>
    </div>
  );
};
