import cx from 'classnames';
import PropTypes from 'prop-types';

import { shippingTypes } from 'utilities/constants';
import { formatDate } from 'utilities/date-time';

import styles from './styles.module.scss';

const displayFormat = 'dddd MM/DD/YY';

const Closing = ({
  className,
  closing: { shippingType, startDate, endDate, closureReasonName } = {},
  dataTestId,
}) => (
  <div className={cx(styles.closing, className)} data-testid={dataTestId}>
    <div className={styles.header}>
      <div className={styles.reasonTitle}>You are closed due to:</div>
      <div>{closureReasonName}</div>
    </div>
    <div className={styles.footer}>
      <div className={styles.footerTitles}>
        <div>For</div>
        <div>From</div>
      </div>
      <div className={styles.footerData}>
        <div>{shippingTypes[shippingType].label}</div>
        <div>
          {formatDate(startDate, displayFormat)}
          <span className={styles.title}> to </span>
          {formatDate(endDate, displayFormat)}
        </div>
      </div>
    </div>
  </div>
);

Closing.propTypes = {
  className: PropTypes.string,
  closing: PropTypes.shape({
    reason: PropTypes.string,
    shippingType: PropTypes.oneOf([
      'delivery',
      'delivery_and_pickup',
      'pickup',
    ]),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    closureReasonName: PropTypes.string,
  }),
  dataTestId: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Closing;
