import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import cx from 'classnames';

import { Button, Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import SubmitFooter from 'components/shared/submit-footer';
import { Suspended } from 'components/shared/suspended';
import { ValueTile } from 'components/shared/value-tile';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useDirectWebSettingsQuery } from 'hooks/site-editor/use-direct-web-settings';
import useAnalytics from 'hooks/use-analytics';
import useDimensions from 'hooks/use-dimensions';
import useStoredState from 'hooks/use-stored-state';
import { useSaveAndPublishMutation } from 'hooks/website-tools/use-save-and-publish-mutation';
import ScreenTypeIcon from 'images/screen-type.svg?react';
import ColorPickerIcon from 'images/site-editor/color-picker.svg?react';
import FontPickerIcon from 'images/site-editor/font-picker.svg?react';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import {
  Breakpoint,
  ColorCodes,
  FontCodes,
  SiteEditorFormValues,
} from 'types/site-editor';
import { User } from 'types/user';
import { showInvalidSubmitToast } from 'utilities/forms';
import {
  EXPIRY_IN_30DAYS,
  getExpiresAtDate,
  getIsStoredDateValid,
} from 'utilities/salesforce';
import {
  defaultColorCode,
  defaultColorHex,
  defaultColorLabel,
  defaultFontCode,
  findSelectedOptions,
} from 'utilities/site-editor';

import BreakpointButtonGroup from '../breakpoint-button-group';
import ColorPicker from '../color-picker';
import FontPicker from '../font-picker';
import OptionToggleButton from '../option-toggle-button';
import SitePreviewIframe from '../site-preview';
import SitePreviewLink from '../site-preview-link';

import styles from './styles.module.scss';

const directWebTabletBreakpoint = 833;
const directWebMobileBreakpoint = 390;
// We show the iframe in a tile with 16px padding left and right, so we account for this when checking
// if iframe has entered tablet view before OP has.
const showDesktopPreviewIconBreakpoint = directWebTabletBreakpoint + 32;
const showTabletPreviewIconBreakpoint = directWebMobileBreakpoint + 32;

enum FormControl {
  Color = 'color',
  Font = 'font',
}

type Props = {
  domainName: string | null;
  shopId: string;
  user: User;
};

const SiteConfiguration = ({ domainName, shopId, user }: Props) => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('desktop');
  const [activeFormControl, setActiveFormControl] = useState<FormControl>();
  const {
    trackClickedBackgroundColorDrawer,
    trackClickedFontStyleDrawer,
    trackClickedSaveAndPublishCustomWebsite,
    trackProductUpsellLinkClick,
  } = useAnalytics();
  const [sitePreviewTileRef, { width: previewTileWidth }] = useDimensions();

  const [caseExpiration, setCaseExpiration] = useStoredState<string>(
    `sf-${shopId}-custom-website-expires-at`,
  );

  const hasValidCase = useMemo(() => {
    return getIsStoredDateValid(caseExpiration);
  }, [caseExpiration]);

  const handleSetLocalStorage = () => {
    const value = getExpiresAtDate(EXPIRY_IN_30DAYS);
    setCaseExpiration(String(value));
  };

  const isDesktopPreviewDisabled = previewTileWidth
    ? previewTileWidth <= showDesktopPreviewIconBreakpoint
    : false;

  const isTabletPreviewDisabled = previewTileWidth
    ? previewTileWidth <= showTabletPreviewIconBreakpoint
    : false;

  const {
    mutate: createSalesforceCase,
    isPending: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const { control, handleSubmit, formState, register, reset, getValues } =
    useForm({
      defaultValues: {
        colorCode: String(defaultColorCode),
        fontCode: String(defaultFontCode),
      } as SiteEditorFormValues,
      mode: 'onBlur',
    });

  const { isDirty, isSubmitting, dirtyFields } = formState;
  const { colorCode, fontCode } = getValues();
  const { selectedColor, selectedFont } = findSelectedOptions({
    selectedColorCode: colorCode,
    selectedFontCode: fontCode,
  });

  const trackClickValues = {
    backgroundColorHex: selectedColor?.hexValue ?? defaultColorHex,
    backgroundColorName: selectedColor?.label ?? defaultColorLabel,
    font: selectedFont,
    shopId,
  };

  const { mutate: saveAndPublish, isPending: isSaveAndPublishLoading } =
    useSaveAndPublishMutation();

  const { data: directWebSettings, isPending: directWebSettingsLoading } =
    useDirectWebSettingsQuery(shopId);

  useEffect(() => {
    if (!isDirty && directWebSettings) {
      reset({
        colorCode: String(directWebSettings.color ?? defaultColorCode),
        fontCode: String(directWebSettings.fontFamily ?? defaultFontCode),
      });
    }
    // Reset when the data changes, not the dirty state of the form.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directWebSettings, reset]);

  const handleColorToggleOnClick = () => {
    if (activeFormControl === FormControl.Color) {
      setActiveFormControl(undefined);
    } else {
      setActiveFormControl(FormControl.Color);
    }

    trackClickedBackgroundColorDrawer({
      selectedColor: dirtyFields.colorCode ? true : false,
      shopId,
      isActive: domainName ? true : false,
    });
  };

  const handleFontToggleOnClick = () => {
    if (activeFormControl === FormControl.Font) {
      setActiveFormControl(undefined);
    } else {
      setActiveFormControl(FormControl.Font);
    }

    trackClickedFontStyleDrawer({
      selectedFont: dirtyFields.fontCode ? true : false,
      shopId,
      isActive: domainName ? true : false,
    });
  };

  const saveSettings = (settings: SiteEditorFormValues) => {
    if (!isSaveAndPublishLoading) {
      saveAndPublish(
        { settings, shopId },
        {
          onError: () =>
            toast.error(
              'Error! Your website updates were not saved or published. Please try again.',
            ),
          onSuccess: (updatedSettings) => {
            trackClickedSaveAndPublishCustomWebsite({
              ...trackClickValues,
              success: true,
            });
            // Reset the default values of the form to the newly saved values so that
            // the form is no longer dirty and the form state is reset. Since the form
            // remains visible, we want the submit button to be disabled to help convey
            // that the settings have been saved and to prevent multiple submissions on the same values.
            reset({
              colorCode: String(updatedSettings.color ?? defaultColorCode),
              fontCode: String(updatedSettings.fontFamily ?? defaultFontCode),
            });
            setActiveFormControl(undefined);

            toast.success(
              <div>
                Your updates have been successfully <b>saved and published</b>.
                Please allow 1-2 hours for the new updates to reflect on your
                website
              </div>,
            );
          },
        },
      );
    }
  };

  const createCase = (settings: SiteEditorFormValues) => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.CustomWebsite,
            subject: SalesforceSubject.CustomWebsite,
          },
          note: `${SalesforceNote.CustomWebsiteNote} --- Background Color: ${ColorCodes[Number(settings.colorCode)]}, Font Style: ${FontCodes[Number(settings.fontCode)]}`,
          shopId: Number(shopId),
          user,
        },
        {
          onSuccess: () => handleSetLocalStorage(),
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
        },
      );
      trackProductUpsellLinkClick({
        shopId: shopId,
        product: 'custom website',
        page: 'custom website',
        linkLabel: 'publish',
        type: ClickedExternalLinkType.CTA,
        outcome: 'lead',
      });
    }
  };

  const handleValidSubmit = (settings: SiteEditorFormValues) => {
    if (domainName) {
      saveSettings(settings);
    } else {
      createCase(settings);
    }
  };

  return (
    <Suspended isLoading={directWebSettingsLoading}>
      <div className={styles.container}>
        {!domainName && (
          <ValueTile>
            <ValueTile.Heading
              icon={<ScreenTypeIcon />}
              text="Create a free website powered by Slice."
            />
            <ValueTile.Content>
              <ValueTile.GroupedItems
                valueOne="Fast and free to set up"
                valueTwo="SEO and mobile optimized"
              />
              <ValueTile.GroupedItems
                valueOne="Repeat orders with automated marketing"
                valueTwo="Connects to your Slice menu"
              />
              <ValueTile.GroupedItems
                valueOne="24/7 support and mantenance"
                valueTwo="Includes a free domain"
              />
            </ValueTile.Content>
          </ValueTile>
        )}

        <ContentTile className={styles.tile}>
          <div className={styles.tileSection}>
            <div className={styles.tileHeader}>
              <div className={styles.title}>Customize Website</div>
              <div className={styles.text}>
                {`Customize the look of your website with colors and fonts and
              preview it. When you're happy with how it looks, click Publish,
              and we'll get working on building your custom site.`}
              </div>
            </div>
          </div>
          {hasValidCase ? (
            <div className={styles.message}>
              <Icon icon="website" className={styles.icon} size="large" />
              {
                "We're building your custom website and will publish it when ready. Check back soon."
              }
            </div>
          ) : (
            <>
              <form
                className={cx(styles.tileSection, styles.configForm)}
                onSubmit={handleSubmit(
                  handleValidSubmit,
                  showInvalidSubmitToast,
                )}
              >
                <div className={styles.toggleOptionButtons}>
                  <div className={styles.optionButtonContainer}>
                    <OptionToggleButton
                      control={control}
                      icon={<ColorPickerIcon />}
                      isSelected={activeFormControl === FormControl.Color}
                      label="Background Color"
                      onClick={handleColorToggleOnClick}
                      optionKey="colorCode"
                    />
                  </div>
                  <div className={styles.hr} />
                  <div className={styles.optionButtonContainer}>
                    <OptionToggleButton
                      control={control}
                      icon={<FontPickerIcon />}
                      isSelected={activeFormControl === FormControl.Font}
                      label="Font Style"
                      onClick={handleFontToggleOnClick}
                      optionKey="fontCode"
                    />
                  </div>
                  <div className={styles.hr} />
                </div>
                <BreakpointButtonGroup
                  breakpoint={breakpoint}
                  isDesktopPreviewDisabled={isDesktopPreviewDisabled}
                  isTabletPreviewDisabled={isTabletPreviewDisabled}
                  setBreakpoint={setBreakpoint}
                  shopId={shopId}
                  domainName={domainName}
                />
                <div className={styles.actionButtons}>
                  {domainName && (
                    <SitePreviewLink
                      control={control}
                      domainName={domainName}
                      shopId={shopId}
                    />
                  )}
                  <Button
                    className={styles.actionButton}
                    data-chameleon-target="Website Customization Publish Button"
                    type="submit"
                    variant="primary"
                    isDisabled={
                      isCreatingSalesforceCaseLoading || isSaveAndPublishLoading
                    }
                  >
                    Publish
                  </Button>
                </div>
                <SubmitFooter
                  cancelText="Reset"
                  className={styles.submitFooter}
                  isSubmitting={isSubmitting}
                  onCancel={() => reset()}
                  submitButtonChameleonTarget="Website Customization Publish Mobile Button"
                  submitText="Publish"
                  isSubmitDisabled={
                    isCreatingSalesforceCaseLoading || isSaveAndPublishLoading
                  }
                />
              </form>
              {activeFormControl !== undefined && (
                <div className={styles.tileSection}>
                  {activeFormControl === FormControl.Color && (
                    <ColorPicker register={register} />
                  )}
                  {activeFormControl === FormControl.Font && (
                    <FontPicker register={register} />
                  )}
                </div>
              )}
            </>
          )}

          <div className={styles.previewContainer} ref={sitePreviewTileRef}>
            <SitePreviewIframe
              breakpoint={breakpoint}
              control={control}
              domainName={domainName}
            />
          </div>
        </ContentTile>
      </div>
    </Suspended>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SiteConfiguration;
