import moment from 'moment-timezone';

import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import { useCustomerOrderProfileQuery } from 'hooks/customers/use-customer-order-profile-query';
import { useCustomersOrdersQuery } from 'hooks/customers/use-customers-orders-query';
import { useShopQuery } from 'hooks/shops';
import { useWindowSize } from 'hooks/use-window-size';
import CalendarAddEventIcon from 'images/calendar-add-event.svg?react';
import CalendarIcon from 'images/calendarEvent.svg?react';
import Star from 'images/favorite-star.svg?react';
import { BaseCustomer } from 'types/customers';
import { customerTypeIcons, getCustomerDisplayName } from 'utilities/customers';

import AnalyticsTile from '../../analytics-tile';

import CustomerStat from './customer-details-modal-content-customer-stat';
import CustomerOrdersTable from './customer-details-order-table';

import styles from './styles.module.scss';

const dateFormat = 'MM/DD/YYYY';

type Props = {
  shopId: number;
  selectedCustomer: BaseCustomer;
};

export const CustomerDetailsModalContent = ({
  shopId,
  selectedCustomer,
}: Props) => {
  const { isMobile } = useWindowSize();
  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const {
    data: customerOrderProfile,
    isError: isCustomerOrderProfileError,
    isPending: isCustomerOrderProfileLoading,
  } = useCustomerOrderProfileQuery({
    parameters: { shopId, customerUuid: selectedCustomer.uuid },
    options: { isEnabled: !!shop },
  });

  const {
    data: customersOrders,
    isError: isCustomersOrdersError,
    isPending: isCustomersOrdersLoading,
    fetchNextPage: loadMoreOrders,
    hasNextPage: hasMoreOrders,
  } = useCustomersOrdersQuery({
    parameters: { shopId, customerUuid: selectedCustomer.uuid },
    options: {
      isEnabled: !!shop,
    },
  });

  if (
    isShopLoading ||
    isCustomerOrderProfileLoading ||
    isCustomersOrdersLoading
  ) {
    return <Loading />;
  } else if (
    isShopError ||
    isCustomerOrderProfileError ||
    isCustomersOrdersError
  ) {
    return <UnexpectedError />;
  }

  const shopTimezone = shop.timezoneIdentifier;

  const CustomerTypeIcon = customerTypeIcons[selectedCustomer.loyaltyStatus];

  const lastOrderedAt = moment
    .tz(customerOrderProfile.lastOrderedAt, shopTimezone)
    .format(dateFormat);
  const customerSince = moment
    .tz(customerOrderProfile.firstOrderedAt, shopTimezone)
    .format(dateFormat);
  const favouriteItem = customerOrderProfile.favouriteProduct.productName;
  const orderCount = customerOrderProfile.orderCount;
  const totalSpend = customerOrderProfile.orderTotal;
  const averageOrderValue = customerOrderProfile.averageOrderValue;
  // The data here are pages of PagedCustomerProfileOrder made up of an array of orders and a nextPage. We want
  // to go through all loaded pages and get only the orders.
  const orders = customersOrders.pages.map((page) => page.data).flat(1);

  return (
    <div className={styles.customerDetailsContainer}>
      <div
        className={styles.customerSummary}
        data-testid="customer details modal summary"
      >
        <CustomerTypeIcon
          className={styles.loyaltyStatusIcon}
          aria-hidden="true"
        />
        <span className={styles.customerName}>
          {getCustomerDisplayName(
            selectedCustomer.firstName,
            selectedCustomer.lastName,
          )}
        </span>
        <CustomerStat
          className={styles.customerStat}
          label="Customer Since"
          value={customerSince}
          Icon={CalendarAddEventIcon}
        />
        <CustomerStat
          className={styles.customerStat}
          label="Last Order Date"
          value={lastOrderedAt}
          Icon={CalendarIcon}
        />
        <CustomerStat
          className={styles.customerStat}
          label="Favorite Item"
          value={favouriteItem}
          Icon={Star}
        />
      </div>
      <div
        className={styles.customerAnalytics}
        data-testid="customer details modal analytics"
      >
        <AnalyticsTile
          className={styles.metricTile}
          canCollapse
          contentClassName={styles.metricTileContent}
          isCurrency={false}
          isLoading={false}
          maxFontSize={isMobile ? 24 : undefined}
          roundValue={false}
          title="Total Order Count"
          value={orderCount}
          variant="inline"
        />
        <AnalyticsTile
          className={styles.metricTile}
          canCollapse
          contentClassName={styles.metricTileContent}
          isCurrency
          isLoading={false}
          maxFontSize={isMobile ? 24 : undefined}
          roundValue={false}
          title="Average Order Value"
          value={averageOrderValue}
          variant="inline"
        />
        <AnalyticsTile
          className={styles.metricTile}
          canCollapse
          contentClassName={styles.metricTileContent}
          isCurrency
          isLoading={false}
          maxFontSize={isMobile ? 24 : undefined}
          roundValue={false}
          title="Total Spend"
          value={totalSpend}
          variant="inline"
        />
      </div>
      <CustomerOrdersTable
        customerUuid={selectedCustomer.uuid}
        loadMoreOrders={() => loadMoreOrders()}
        orders={orders}
        shopId={String(shopId)}
        shopTimezone={shopTimezone}
        showLoadMore={hasMoreOrders ?? false}
      />
    </div>
  );
};
