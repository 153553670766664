import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';

import { Button } from 'crust';

import Link from 'components/shared/link';
import { RHFTextField } from 'components/shared/rhf-text-field';
import Text from 'components/shared/text';
import CaretIcon from 'images/caret.svg?react';
import DummyLogoIcon from 'images/dummy-logo.svg?react';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type ShopSelectorProps = {
  activeShop: Shop;
  isAdmin: boolean;
  shops: Shop[];
};

export const ShopSelector = ({
  activeShop,
  shops,
  isAdmin,
}: ShopSelectorProps) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      shopId: '',
    },
  });

  useEffect(() => {
    setIsDropdownExpanded(false);
  }, [activeShop]);

  const hasMultipleShops = shops.length > 1;
  const onShopListClick = () => setIsDropdownExpanded(true);
  const clickableStyle = cx(styles.shopDetails, styles.clickable);
  const onSubmit = ({ shopId }: { shopId: string }) => {
    const shopUrl = paths.shop(shopId);
    navigate(shopUrl);
  };

  const getLogoImage = (logo: string | null) =>
    logo ? (
      <img alt="Shop logo" className={styles.shopLogo} src={logo} />
    ) : (
      <DummyLogoIcon className={styles.dummyLogo} title="no logo" />
    );

  return (
    <>
      {isDropdownExpanded && (
        <button
          className={styles.clickAway}
          onClick={() => setIsDropdownExpanded(false)}
        />
      )}
      <div
        className={clickableStyle}
        data-testid="desktopNavShopSelectorButton"
        onClick={onShopListClick}
        onKeyDown={onShopListClick}
        role="button"
        tabIndex={0}
      >
        <div className={styles.shopTextWrapper}>
          <Text clamp className={styles.shopDetailsShopName}>
            {activeShop.name}
          </Text>
          <Text clamp className={styles.shopDetailsShopAddress}>
            {activeShop.address}
          </Text>
        </div>
        <CaretIcon className={styles.caret} />
        {isDropdownExpanded && (
          <div className={styles.shopDropdownContainer}>
            {isAdmin && (
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <RHFTextField
                  control={control}
                  name="shopId"
                  placeholder="Enter Shop Id"
                />
                <Button
                  className={styles.arrowButton}
                  data-testid="shopSelectionPageContinueButton"
                  type="submit"
                >
                  {'>'}
                </Button>
              </form>
            )}
            {!isAdmin && hasMultipleShops && (
              <div className={styles.shopsList}>
                {shops.map(
                  (option) =>
                    option.shopId !== activeShop.shopId && (
                      <Link
                        className={styles.dropdownOption}
                        key={option.shopId}
                        to={paths.shop(option.shopId)}
                        variant="undecorated"
                      >
                        {getLogoImage(option.logoUrl)}
                        <div className={styles.dropdownTextWrapper}>
                          <div className={styles.dropdownShopName}>
                            {option.name}
                          </div>
                          <div className={styles.dropdownShopAddress}>
                            {option.address}
                          </div>
                        </div>
                      </Link>
                    ),
                )}
              </div>
            )}
            <button
              data-testid="desktopNavShopSelectorMenuLogoutButton"
              type="button"
              className={styles.logoutButton}
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            >
              Log Out
            </button>
          </div>
        )}
      </div>
    </>
  );
};
