import { useMutation, useQueryClient } from '@tanstack/react-query';

import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { RegisterDiscount } from 'types/discounts';
import { Shop } from 'types/shops';

import { getRegisterDiscountQueryKey, getRegisterDiscountsQueryKey } from '.';

export const useDeleteRegisterDiscountsMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();
  const { trackDeleteRegisterDiscount } = useAnalytics();

  return useMutation({
    mutationFn: async (id: RegisterDiscount['id']) => {
      await authenticatedFetch.del(
        `api/management/v1/ros/shops/${shopId}/discounts/${id}`,
      );
    },
    onSuccess: (_data, id) => {
      // Remove the discount from the cached discounts list.
      queryClient.setQueryData<RegisterDiscount[]>(
        getRegisterDiscountsQueryKey(shopId),
        (current) => current?.filter((discount) => discount.id !== id),
      );

      // Remove the individual cached discount as well.
      queryClient.removeQueries({
        queryKey: getRegisterDiscountQueryKey(shopId, id),
      });

      trackDeleteRegisterDiscount({
        id,
        shopId: String(shopId),
        success: true,
      });
    },
  });
};
