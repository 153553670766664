import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import SliceOrdering from 'components/slice-ordering';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

const SliceOrderingPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  let content;
  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = <SliceOrdering shopId={shop.shopId} shopWebSlug={shop.webSlug} />;
  }

  return (
    <Page name="partner-buttons" title={'Classic Buttons'}>
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SliceOrderingPage;
