import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
  ContextValue,
  Link as AriaLink,
  type LinkProps as AriaLinkProps,
  RouterProvider as AriaRouterProvider,
  useContextProps,
} from 'react-aria-components';
import classNames from 'classnames';
import { Merge, SetOptional } from 'type-fest';

import { Icon, type IconProps } from '../icon/icon';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';
import { mergeRefs } from '../utilities/merge-refs';

import './button-link.css';

type AppearanceProps =
  | {
      appearance: 'button';
      icon?: never;
      iconPosition?: never;
      size?: never;
      variant?: 'slice' | 'primary' | 'secondary';
    }
  | {
      appearance: 'link';
      icon?: never;
      iconPosition?: never;
      size?: 'default' | 'small';
      variant?: 'primary' | 'destructive';
    }
  | {
      appearance: 'link';
      icon?: IconProps['icon'];
      iconPosition?: 'start' | 'end';
      size?: 'default' | 'small';
      variant: 'secondary';
    }
  | {
      appearance: 'link';
      icon?: never;
      iconPosition?: never;
      size?: never;
      variant: 'tertiary';
    };

type AppearanceProp = AppearanceProps['appearance'];
type IconProp = NonNullable<AppearanceProps['icon']>;
type IconPositionProp = NonNullable<AppearanceProps['iconPosition']>;
type SizeProp = NonNullable<AppearanceProps['size']>;
type VariantProp = NonNullable<AppearanceProps['variant']>;

// Distributively apply the optional appearance.
type DefaultedAppearanceProps<
  A extends AppearanceProp,
  T extends AppearanceProps = AppearanceProps,
> = T extends { appearance: A } ? SetOptional<T, 'appearance'> : T;

const resolveVariant = (appearance: AppearanceProp, variant?: VariantProp) =>
  appearance === 'button'
    ? variant == null || variant === 'tertiary'
      ? 'slice'
      : variant
    : variant == null || variant === 'slice'
      ? 'primary'
      : variant;

const resolveSize = (
  appearance: AppearanceProp,
  variant: VariantProp,
  size?: SizeProp,
) =>
  appearance === 'button'
    ? undefined
    : variant === 'tertiary'
      ? undefined
      : (size ?? 'default');

const resolveIcon = (
  appearance: AppearanceProp,
  variant: VariantProp,
  icon?: IconProp,
) =>
  appearance === 'button'
    ? undefined
    : variant === 'secondary'
      ? icon
      : undefined;

type LinkIconProps = {
  appearance: AppearanceProp;
  icon: IconProp;
  iconPosition?: IconPositionProp;
};

const LinkIcon = ({
  appearance,
  icon,
  iconPosition = 'start',
}: LinkIconProps) => (
  <span
    className={classNames(
      getCrustClassName(appearance, 'icon', [iconPosition]),
    )}
  >
    <Icon icon={icon} />
  </span>
);

type CrustButtonProps = DefaultedAppearanceProps<'button'> & {
  children?: string | string[];
};
export type ButtonProps = Merge<AriaButtonProps, CrustButtonProps>;

export const ButtonContext =
  createContext<ContextValue<ButtonProps, ElementRef<typeof AriaButton>>>(null);

export const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: ForwardedRef<ElementRef<typeof AriaButton>>,
) {
  [props, ref] = useContextProps(
    {
      // Given <Button variant="slice" /> we assume that the author intends to
      // show the button appearance. Props passed to the component should always
      // take effect, even if they contradict the context.
      appearance: props.variant === 'slice' ? 'button' : undefined,
      ...props,
    },
    ref,
    ButtonContext,
  );

  props.appearance ??= 'button';
  props.variant = resolveVariant(props.appearance, props.variant);
  props.icon = resolveIcon(props.appearance, props.variant, props.icon);
  props.size = resolveSize(props.appearance, props.variant, props.size);

  const {
    appearance,
    className,
    children,
    icon,
    iconPosition,
    size,
    variant,
    ...ariaProps
  } = props;

  return (
    <AriaButton
      className={mergeAriaClassName(
        getCrustClassName(appearance, [size, variant]),
        className,
      )}
      ref={ref}
      {...ariaProps}
    >
      {icon && (
        <LinkIcon
          appearance={appearance}
          icon={icon}
          iconPosition={iconPosition}
        />
      )}
      {children}
    </AriaButton>
  );
});

type LinkConfiguration = {
  openExternalLinksInNewTab?: boolean;
};

const LinkConfigurationContext = createContext<LinkConfiguration>({
  openExternalLinksInNewTab: false,
});

export type RouterProviderProps = ComponentPropsWithoutRef<
  typeof AriaRouterProvider
> &
  LinkConfiguration;

export const RouterProvider = ({
  openExternalLinksInNewTab,
  ...props
}: RouterProviderProps) => (
  <LinkConfigurationContext.Provider value={{ openExternalLinksInNewTab }}>
    <AriaRouterProvider {...props} />
  </LinkConfigurationContext.Provider>
);

type CrustLinkProps = DefaultedAppearanceProps<'link'> & {
  children?: string | string[];
};

export type LinkProps = Merge<AriaLinkProps, CrustLinkProps>;

export const LinkContext =
  createContext<ContextValue<LinkProps, ElementRef<typeof AriaLink>>>(null);

export const Link = forwardRef(function Link(
  props: LinkProps,
  ref: ForwardedRef<ElementRef<typeof AriaLink>>,
) {
  [props, ref] = useContextProps(
    {
      // Given <Link variant="tertiary"> or <Link size="primary"> we assume that
      // author intends to use show the link appearance. Props passed to the
      // component should always take effect, even if they contradict the context.
      appearance:
        props.variant === 'tertiary' || props.size != null ? 'link' : undefined,
      ...props,
    },
    ref,
    LinkContext,
  );

  const anchorRef = useRef<HTMLAnchorElement | null>(null);
  ref = mergeRefs(ref, anchorRef);

  props.appearance ??= 'link';
  props.variant = resolveVariant(props.appearance, props.variant);
  props.icon = resolveIcon(props.appearance, props.variant, props.icon);
  props.size = resolveSize(props.appearance, props.variant, props.size);

  const {
    appearance,
    className,
    children,
    icon,
    iconPosition,
    size,
    variant,
    ...ariaProps
  } = props;

  const { openExternalLinksInNewTab } = useContext(LinkConfigurationContext);
  const { href, target } = props;

  useEffect(() => {
    const anchor = anchorRef.current;

    if (
      anchor &&
      href &&
      !target &&
      openExternalLinksInNewTab &&
      anchor.origin !== location.origin
    ) {
      anchor.target = '_blank';
    }
  }, [href, openExternalLinksInNewTab, target]);

  return (
    <AriaLink
      className={mergeAriaClassName(
        getCrustClassName(appearance, [size, variant]),
        className,
      )}
      ref={ref}
      {...ariaProps}
    >
      {icon && (
        <LinkIcon
          appearance={appearance}
          icon={icon}
          iconPosition={iconPosition}
        />
      )}
      {children}
    </AriaLink>
  );
});

const getButtonGroupClassName = getCrustClassName.bind(null, 'button-group');

export type ButtonGroupProps = ComponentPropsWithoutRef<'div'> & {
  autoCollapse?: boolean;
  orientation?: 'horizontal' | 'vertical';
};

export const ButtonGroup = ({
  autoCollapse,
  className,
  children,
  orientation = 'horizontal',
  ...props
}: ButtonGroupProps) => (
  <div
    className={classNames(
      getButtonGroupClassName(),
      // @ts-expect-error -- Overloads are lost through bind().
      autoCollapse && getButtonGroupClassName(['auto-collapse']),
      className,
    )}
    data-orientation={orientation}
    role="group"
    {...props}
  >
    {children}
  </div>
);
