import { useMatch } from 'react-router-dom';
import { OptimizelyDecideOption, useDecision } from '@optimizely/react-sdk';

import { useShopQuery } from 'hooks/shops';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { warn } from 'utilities/log';

export type UseFeatureFlag = {
  isEnabled: boolean;
  isLoading: boolean;
};

const useFeatureFlag = (featureFlag: OptimizelyFeatureFlag): UseFeatureFlag => {
  const match = useMatch('/shops/:shopId/*');

  // If "shopId" is not defined, Optimizely will log an error to console output.
  // It is not meaningful to query Optimizely without a shopId.
  const optimizelyShopId = match?.params.shopId ?? '-1';

  if (optimizelyShopId === '-1') {
    warn(
      'useFeatureFlag was called but there is no shopId URL path parameter.',
    );
  }

  const { data: shop, isPending: isShopLoading } = useShopQuery(
    optimizelyShopId,
    {
      enabled: optimizelyShopId !== '-1',
    },
  );
  const isRosEnabled = shop?.rosEnabled ?? false;

  // Don't officially log an impression while still loading.
  const decideOptions = !shop
    ? [OptimizelyDecideOption.DISABLE_DECISION_EVENT]
    : [];

  const [decision, isClientReady, isTimedOut] = useDecision(
    featureFlag,
    {
      autoUpdate: true,
      decideOptions,
      // This number is arbitrary and seems "sensible". It's a tradeoff between
      // penalizing slow connections and showing a loading spinner for a
      // reasonable amount of time when Optimizely is blocked.
      timeout: 2000, // 2 seconds.
    },
    {
      overrideUserId: optimizelyShopId,
      overrideAttributes: {
        shopId: optimizelyShopId,
        isRosEnabled,
      },
    },
  );

  return {
    // Allow the client to still return a decision to the component after the
    // timeout. Slow connections might see content shift after loading.
    isEnabled: !isShopLoading && isClientReady && decision.enabled,
    // The duration of the shop fetch or the timeout, whichever takes longer. If
    // we timeout before the client is ready, return false so the component can
    // start rendering.
    isLoading: isShopLoading || (!isTimedOut && !isClientReady),
  };
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useFeatureFlag;
