import { TipSettings } from 'components/register/tip-settings';
import ContentTile from 'components/shared/content-tile';
import { UnexpectedError } from 'components/shared/error';
import Header from 'components/shared/header';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useRegisterTipSettings } from 'hooks/register/use-register-tip-settings';
import { useCurrentShopId } from 'hooks/shops';

import styles from './styles.module.scss';

const RegisterTipSettings = () => {
  const shopId = useCurrentShopId();
  const { data, isPending, isError } = useRegisterTipSettings(shopId);

  let content;

  if (isPending) {
    content = <Loading />;
  } else if (isError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <ContentTile title="Register and Terminal Tips">
        <TipSettings shopId={shopId} tipSettings={data} />
      </ContentTile>
    );
  }

  return (
    <Page name="tip-settings" title="Tip Settings">
      <Header
        containerClass={styles.header}
        title="Manage Tipping"
        content={[
          'Tip settings determine when an order is shown and the associated tipping percentages. You have the option to activate, select, or deactivate tipping within the following settings.',
        ]}
      />
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterTipSettings;
