import { useCurrentUserQuery } from 'hooks/shared';
import { useShopQuery, useShopsQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import useFeatureFlag from 'hooks/use-feature-flag';
import { useWindowSize } from 'hooks/use-window-size';
import { useVerificationCodeQuery } from 'hooks/verification-code/use-verification-code-query';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { Shop, ShopTraits } from 'types/shops';
import { getShopHasAnyRegister } from 'utilities/shops';

import { NavigationDesktop } from './desktop';
import NavigationMobile from './mobile';
import * as navItems from './nav-items';

type NavigationProps = {
  shopId: Shop['shopId'];
  traits: ShopTraits;
};

export const Navigation = ({ shopId, traits }: NavigationProps) => {
  const { data: shops } = useShopsQuery();
  const { data: activeShop } = useShopQuery(shopId);
  const { data: user } = useCurrentUserQuery({ enabled: true });
  const analytics = useAnalytics();
  const { amsFirstOnboardedDate } = traits;

  const { isEnabled: isBrandManagerPageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.BrandManager,
  );

  const { isEnabled: isHoursAnalyticsEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.HoursAnalytics,
  );

  const { isEnabled: isUpsellAMSEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.ShouldUpsellAMS,
  );

  const { isEnabled: isGoodsPaymentsPageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.GoodsPaymentsPage,
  );

  const { isEnabled: isCustomerProfilesPageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.OwnersPortalCustomerProfilesPage,
  );

  const { isEnabled: isGoogleBusinessProfilePageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.GoogleBusinessProfile,
  );

  const { isEnabled: isPhonePageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.PhonePage,
  );

  const { isEnabled: isPayoutManagementFlagEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.PayoutManagement,
  );

  const { data: verificationCodeData } = useVerificationCodeQuery(shopId);

  const trackNavigationItemClick = (
    labels: string[],
    isExternalLink: boolean,
  ) => {
    analytics.trackNavigationItemClick(
      shopId,
      labels,
      isExternalLink,
      isRegisterUser,
      registerMode,
    );
  };

  const isRegisterUser = activeShop
    ? (getShopHasAnyRegister(activeShop) ?? false)
    : false;
  const registerMode = activeShop ? activeShop.registerMode.name : '';

  const { isMobile } = useWindowSize();

  const navigationSections = [
    navItems.firstSection(shopId.toString(), isHoursAnalyticsEnabled, isMobile),
    navItems.secondSection(
      shopId.toString(),
      isCustomerProfilesPageEnabled,
      isGoodsPaymentsPageEnabled,
    ),
    navItems.thirdSection({
      shopId: shopId.toString(),
      isBrandManagerPageEnabled,
      isUpsellAMSEnabled,
      isGoogleBusinessProfilePageEnabled,
      canViewDigitalAdsReport: !!amsFirstOnboardedDate,
    }),
    navItems.phoneSection(shopId.toString(), isPhonePageEnabled),
    navItems.operationsSection(shopId.toString()),
    navItems.accountsSection(
      shopId.toString(),
      activeShop ? activeShop.slicePayments : false,
      isPayoutManagementFlagEnabled,
    ),
  ].filter((item) => item != null);

  if (activeShop) {
    const registerNavItems = navItems.registerSection({
      shop: activeShop,
    });

    navigationSections.splice(3, 0, registerNavItems);
  }

  const NavigationComponent = isMobile ? NavigationMobile : NavigationDesktop;

  return (
    <NavigationComponent
      activeShop={activeShop}
      isAdmin={user ? user.userType === 'administrator' : false}
      navigationSections={navigationSections}
      shopId={shopId}
      shops={shops ?? []}
      trackNavigationItemClick={trackNavigationItemClick}
      verificationCode={verificationCodeData?.verificationCode ?? ''}
    />
  );
};
