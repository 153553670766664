import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useBulkEditContext } from 'components/menu/products/context';
import Link from 'components/shared/link';
import { RHFCheckbox, RHFCheckboxGroup } from 'components/shared/rhf-checkbox';
import Modal from 'components/shared/slice-modal';
import { useUpdatePrinterAssignments } from 'hooks/menu/use-bulk-printer-assignment';
import * as paths from 'routes/paths';
import { RegisterPrinter } from 'types/register-printing';
import { Shop } from 'types/shops';
import { showUnexpectedErrorToast } from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  printerSettings: RegisterPrinter[];
  isOpen: boolean;
  closeModal: () => void;
};

const validationSchema = z.object({
  itemIds: z.array(z.number()),
  printerRoleIds: z.array(z.string()),
});

type FormValues = z.infer<typeof validationSchema>;

export const AssignPrintersModal = (props: Props) => {
  const { closeModal, printerSettings, isOpen, shop } = props;
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();
  const { mutate: updatePrinterAssignments, isPending: isUpdating } =
    useUpdatePrinterAssignments(String(shop.shopId));

  const { control, reset, handleSubmit, setValue, formState } =
    useForm<FormValues>({
      defaultValues: {
        itemIds: [],
        printerRoleIds: [],
      },
      resolver: zodResolver(validationSchema),
      mode: 'onBlur',
    });

  // Sync the form values with the selected product ids.
  useEffect(() => {
    if (selectedProductIds.size > 0) {
      setValue('itemIds', [...selectedProductIds]);
    }
  }, [selectedProductIds, setValue]);

  const onSubmit = (data: FormValues) => {
    updatePrinterAssignments(data, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: () => {
        toast.success('Printer assignments updated!');
        reset();
        closeModal();
        clearSelectedProductIds();
      },
    });
  };

  const hasPrinters = printerSettings.length > 0;

  return (
    <Modal
      header="Assign Printers"
      isFullScreenMobile
      isOpen={isOpen}
      noButtonText="Cancel"
      onClickNo={closeModal}
      formId="assign-printers-modal-form"
      onRequestClose={closeModal}
      yesButtonText="Save"
      isYesButtonDisabled={
        !hasPrinters ||
        !formState.isDirty ||
        isUpdating ||
        formState.isSubmitting
      }
      className={styles.modal}
    >
      {hasPrinters ? (
        <form id="assign-printers-modal-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.content}>
            <RHFCheckboxGroup
              control={control}
              name="printerRoleIds"
              orientation="vertical"
            >
              {printerSettings.map((printer) => (
                <RHFCheckbox
                  key={printer.id}
                  label={printer.name}
                  value={printer.id}
                />
              ))}
            </RHFCheckboxGroup>
          </div>
        </form>
      ) : (
        <p>
          It looks like you haven&apos;t added any printers yet. You can add one
          in the{' '}
          <Link to={paths.shopRegisterPrinting(shop.shopId)}>
            Printer Settings
          </Link>{' '}
          page.
        </p>
      )}
    </Modal>
  );
};
