import { useState } from 'react';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import moment from 'moment-timezone';

import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Pagination from 'components/shared/table/pagination';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useCustomerExportsQuery } from 'hooks/customers/use-customer-exports';
import { useTable } from 'hooks/shared';
import { CustomerExports } from 'types/customers/profile-exports';
import { Shop } from 'types/shops';

import { DownloadTypeCell } from './download-type';

import styles from './styles.module.scss';

type Props = {
  shopTimezone: Shop['timezoneIdentifier'];
  shopId: Shop['shopId'];
};

export const ExportsTable = ({ shopId, shopTimezone }: Props) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const { data: customerExports, isPending: isExportsLoading } =
    useCustomerExportsQuery(shopId, pagination);

  const helper = createColumnHelper<CustomerExports['data'][0]>();
  const columns = [
    helper.accessor('id', {
      header: 'Export',
      enableSorting: false,
      cell: (info) => {
        const rowData = info.row.original;
        const fromDate = moment
          .tz(rowData.fromDate, shopTimezone)
          .format('MM/DD/YYYY');
        const toDate = moment
          .tz(rowData.toDate, shopTimezone)
          .format('MM/DD/YYYY');

        return (
          <div>
            <span>{`${fromDate} - ${toDate}`}</span>
            <DownloadTypeCell type={rowData.download} />
          </div>
        );
      },
    }),
    helper.accessor('createdAt', {
      header: 'Date',
      enableSorting: false,
      cell: (ctx) => {
        const rowData = ctx.row.original;
        const date = moment
          .tz(rowData.createdAt, shopTimezone)
          .format('MM/DD/YYYY');

        return <span>{date}</span>;
      },
    }),
    helper.accessor('downloadUrl', {
      header: () => <VisuallyHidden>Download Export</VisuallyHidden>,
      enableSorting: false,
      meta: { isActionColumn: true },
      cell: (info) => {
        const rowData = info.row.original;

        if (rowData.status === 'pending') {
          return <span>Pending</span>;
        }

        if (rowData.status === 'failure') {
          return <span>Failure</span>;
        }

        return (
          <TableAction
            title="Download"
            icon="download"
            to={rowData.downloadUrl}
          />
        );
      },
    }),
  ];

  const table = useTable({
    columns,
    data: customerExports?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    state: {
      isLoading: isExportsLoading,
      pagination,
    },
    onPaginationChange: setPagination,
    pageCount: customerExports?.meta.pagination.pages ?? 1,
    chameleonTableTitle: 'Exports',
  });

  return (
    <>
      <Table className={styles.table} table={table} />
      <Pagination
        currentPage={table.getState().pagination.pageIndex + 1}
        isPageControlVisible
        isPerPageControlVisible={false}
        onChangePage={(page: number) => table.setPageIndex(page - 1)}
        totalPages={table.getPageCount()}
        perPage={pagination.pageSize}
        onChangePerPage={() => {}}
      />
    </>
  );
};
