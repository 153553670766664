import { Button } from 'crust';

import Tile from 'components/shared/tile/tile';

import styles from './styles.module.scss';

export const AddNewCard = () => {
  return (
    <Tile className={styles.container}>
      <div>
        <p>Credit Card Info</p>
        <p className={styles.subtext}>
          Enter the CC info you want to use when ordering Goods.
        </p>
      </div>
      <Button variant="primary" type="button">
        Add Credit Card
      </Button>
    </Tile>
  );
};
