import RegisterPromotionsAndDiscounts from 'components/register/promotions-and-discounts';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useRegisterDiscountsQuery } from 'hooks/discounts';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

const RegisterDiscountsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const {
    data: discounts,
    isError: isDiscountsError,
    isPending: isDiscountsLoading,
  } = useRegisterDiscountsQuery(shopId);

  let content;
  if (isShopLoading || isDiscountsLoading) {
    content = <Loading />;
  } else if (isShopError || isDiscountsError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <RegisterPromotionsAndDiscounts
        discounts={discounts}
        shopId={shop.shopId}
        shopTimezone={shop.timezoneIdentifier}
      />
    );
  }

  return (
    <Page name="promotions-and-discounts" title="Promotions & Discounts">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterDiscountsPage;
