import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

export type Closings = {
  data: [
    {
      id: number;
      startDate: string;
      endDate: string;
      shippingType: string;
      reason: string;
      source: string;
      closureReason: number;
      closureReasonName: string;
    },
  ];
};

export type ClosingsResponse = {
  id: number;
  start_date: string;
  end_date: string;
  shipping_type: string;
  reason: string;
  source: string;
  closure_reason: {
    id: number;
    name: string;
  };
};

export const getClosingsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'closings',
];

export const useClosings = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getClosingsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/closings`,
      );

      // Formatting the closing data to allow easier
      // interactions with the closureReason prop
      // at the component level e.g. sorting
      const closings = response.data.map((closing: ClosingsResponse) => ({
        ...closing,
        closureReason: closing.closure_reason.id,
        closureReasonName: closing.closure_reason.name,
      }));
      return camelCaseKeys(closings) as Closings;
    },
  });
};
