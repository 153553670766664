import { useMatch } from 'react-router-dom';

import { Closings } from 'components/hours/closings';
import HoursHeader from 'components/hours/header';
import OpeningStatus from 'components/hours/opening-status';
import WeeklyHours from 'components/hours/weekly-hours';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import NavigationTabs from 'components/shared/navigation-tabs';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import * as paths from 'routes/paths';

export const Hours = () => {
  const shopId = useCurrentShopId();
  const castedShopId = String(shopId);

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const shopHoursPath = paths.shopHours(shopId);
  const weeklyHoursPath = paths.shopHoursWeekly(shopId);
  const closingsPath = paths.shopClosings(shopId);

  const shopHoursMatch = useMatch(shopHoursPath);
  // The weekly hours match logic utilizes the optional `end: false`,
  // because that weekly hours route needs to match for both
  // the "/weekly-hours" and "/weekly-hours/delivery" URLs.
  const weeklyHoursMatch = useMatch({ path: weeklyHoursPath, end: false });
  const closingsMatch = useMatch(closingsPath);

  let content;
  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <>
        <HoursHeader shopId={castedShopId} />
        <NavigationTabs>
          <NavigationTabs.Link
            end
            to={shopHoursPath}
            data-testid="hours-overview-tab"
          >
            Overview
          </NavigationTabs.Link>
          <NavigationTabs.Link
            to={weeklyHoursPath}
            data-testid="hours-weekly-hours-tab"
          >
            Weekly Hours
          </NavigationTabs.Link>
          <NavigationTabs.Link
            to={closingsPath}
            data-testid="hours-closings-tab"
          >
            Scheduled Closings
          </NavigationTabs.Link>
        </NavigationTabs>
        {shopHoursMatch && <OpeningStatus shopId={castedShopId} />}
        {weeklyHoursMatch && <WeeklyHours shopId={castedShopId} />}
        {closingsMatch && (
          <Closings
            shopId={castedShopId}
            shopTimezone={shop.timezoneIdentifier}
          />
        )}
      </>
    );
  }

  return (
    <Page name="hours" title="Hours">
      {content}
    </Page>
  );
};
