import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetOrderActivityReportsResponse,
  GetReportsRequestParams,
  GetReportsResponse,
  OrderActivityReport,
} from 'types/financials';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { toSnakeCase } from 'utilities/strings';

type UseOrderActivityReportsQueryOptions = Omit<
  UseQueryOptions<GetOrderActivityReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getOrderActivityReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'orderActivityReports', ...(params ? [params] : [])];

export const useOrderActivityReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseOrderActivityReportsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    sort: `${sorting.desc ? '-' : '+'}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getOrderActivityReportsQueryKey(shopId, params),
    queryFn: async () => {
      const query = `${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/order_activity_reports?${query}`,
        {},
        true,
        true,
        true,
      );

      // Normalize the response pagination values to be a part of the response
      // similar to OPAS rather than dealing with headers outside API layer.
      return {
        data: camelCaseKeys(response.body),
        meta: {
          pagination: {
            pages: Number(response.headers.get('X-Total-Pages') ?? 0),
            total: Number(response.headers.get('X-Total') ?? 0),
          },
        },
      } as GetReportsResponse<OrderActivityReport>;
    },
    placeholderData: keepPreviousData,
    ...options,
  });
};
