import { CalendarDate } from '@internationalized/date';

import { PresetRangeValue } from 'crust';

import AnalyticsTile from 'components/orders/analytics-tile';
import { RegisterMetricKeys } from 'types/orders';
import { toDollarString } from 'utilities/currency';
import { registerMetricLabels } from 'utilities/financials/orders';

import useRosOrderMetrics from './use-ros-order-metrics';

import styles from './styles.module.scss';

type Props = {
  dates: PresetRangeValue<CalendarDate, string>;
  shopId: string;
  shopTimezone: string;
};

const RegisterMetrics = ({ dates, shopId, shopTimezone }: Props) => {
  const { data, isPending } = useRosOrderMetrics({
    dates,
    shopId,
    shopTimezone,
  });

  return (
    <div
      className={styles.metricsContainer}
      data-testid="register analytics tiles container"
    >
      {Object.entries(registerMetricLabels).map(([key, label]) => {
        let value;

        if (key === 'numOrders') {
          value = data?.[key as RegisterMetricKeys] ?? 0;
        } else {
          value = data ? toDollarString(data[key as RegisterMetricKeys]) : 0;
        }

        return (
          <AnalyticsTile
            key={key}
            labelKey={key}
            isLoading={isPending}
            title={label}
            value={value}
          />
        );
      })}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterMetrics;
