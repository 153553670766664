import { Suspended } from 'components/shared/suspended';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops/shop';
import { calculatePercentDelta } from 'utilities/misc';

import {
  MetricTile,
  MetricTileContent,
  MetricTileDelta,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from '../metric-tile';
import useFetchOrderMetrics from '../use-fetch-order-metrics';

type Props = {
  activeDates: {
    current: {
      startDate: string;
      endDate: string;
    };
    previous: {
      startDate: string;
      endDate: string;
    };
  };
  className?: string;
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const TotalOrders = ({
  activeDates,
  className,
  shopId,
  shopTimezone,
}: Props) => {
  const {
    currentOrderSummary,
    previousOrderSummary,
    isLoading: isMetricsLoading,
  } = useFetchOrderMetrics(activeDates, shopId, shopTimezone);

  const isLoading = isMetricsLoading;

  // Always an integer.
  const currentTotalOrders = currentOrderSummary?.totalDigitalOrders ?? 0;

  const delta = calculatePercentDelta(
    currentTotalOrders,
    previousOrderSummary?.totalDigitalOrders,
  );

  return (
    <MetricTile
      className={className}
      link={{
        shopId: String(shopId),
        title: 'Go to analytics Page',
        to: paths.getFinancialsReportsOrderActivityPath(shopId),
        trackingName: 'total_orders',
      }}
    >
      <MetricTileContent>
        <MetricTileHeader linkIncluded={true}>
          <MetricTileTitle>Total Orders</MetricTileTitle>
        </MetricTileHeader>
        <Suspended isLoading={isLoading}>
          <MetricTileValue value={currentTotalOrders} />
          {delta !== 0 && (
            <MetricTileDelta value={delta} maxFractionDigits={0} />
          )}
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};
