import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { createLocationResponseSchema } from './schemas';
import { getQueryKey as getAccountLocationsQueryKey } from './use-business-profile-account-locations-query';
import { useHandleGoogleReauthError } from './use-handle-google-reauth-error';

const schema = createLocationResponseSchema;

export type ResponseGoogleBusinessProfile = z.infer<typeof schema>;

export const useCreateAccountLocationMutation = () => {
  const queryClient = useQueryClient();
  const { authenticatedFetch } = useApi();
  const handleGoogleReauthError = useHandleGoogleReauthError();

  return useMutation({
    mutationFn: async ({
      shopId,
      searchLocationId,
    }: {
      shopId: Shop['shopId'];
      searchLocationId?: string;
    }) => {
      try {
        const response = await authenticatedFetch.post(
          `api/management/v1/shops/${shopId}/business_profile/account_locations`,
          {
            search_location_id: searchLocationId ? searchLocationId : null,
          },
        );
        return schema.parse(camelCaseKeys(response));
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error(
            'Error parsing Create Business Profile Account Location response',
            error,
          );
        }

        handleGoogleReauthError(shopId, error);

        throw error;
      }
    },
    onSuccess(data, variables) {
      queryClient.invalidateQueries({
        queryKey: getAccountLocationsQueryKey(variables.shopId),
      });
    },
  });
};
