import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { CustomerExportsSchema } from 'types/customers/profile-exports';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const getCustomerExportsQueryKey = (
  shopId: Shop['shopId'],
  pagination?: PaginationState,
) => [shopId, 'customerExports', ...(pagination ? [pagination] : [])];

export const useCustomerExportsQuery = (
  shopId: Shop['shopId'],
  pagination: PaginationState,
) => {
  const { authenticatedFetch } = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
  };

  return useQuery({
    queryKey: getCustomerExportsQueryKey(shopId, pagination),
    queryFn: async () => {
      const query = `${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/customer_downloads?${query}`,
      );

      return CustomerExportsSchema.parse(camelCaseKeys(response));
    },
    placeholderData: keepPreviousData,
  });
};
