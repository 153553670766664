import { Controller } from 'react-hook-form';
import { today } from '@internationalized/date';
import PropTypes from 'prop-types';

import { Button, ButtonGroup } from 'crust';

import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import { RHFDateRangePicker } from 'components/shared/rhf-date-range-picker';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import Select from 'components/shared/slice-select';
import TextInput from 'components/shared/slice-text-input';
import { ClosingScope, useClosingReasons } from 'hooks/hours';
import { shippingTypeOptions } from 'utilities/constants';

import styles from './styles.module.scss';

const ClosingForm = ({
  action,
  control,
  errors,
  isSubmitting = false,
  onClickCancel,
  onSubmit,
  shopTimezone,
}) => {
  const { data: closingReasonOptions } = useClosingReasons(
    ClosingScope.Closing,
  );

  const validateDates = (dates) => {
    if (!dates || !dates.start || !dates.end) {
      return 'Please choose start and end dates.';
    }

    if (dates.start.compare(today(shopTimezone)) < 0) {
      return 'Please choose a start date of today or later.';
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please choose an end date that is after the start date.';
    }

    if (dates.end.compare(dates.start) > 6) {
      return 'Please select a maximum of 7 days.';
    }

    return true;
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <RHFRadioGroup
        name="shippingType"
        control={control}
        label="Service"
        orientation="horizontal"
        variant="card"
      >
        {shippingTypeOptions.map(({ id, label }) => (
          <RHFRadio key={id} label={label} value={id} />
        ))}
      </RHFRadioGroup>
      <RHFDateRangePicker
        control={control}
        description="Please contact your PSM to close for more than 7 days."
        label="Closing Dates"
        minValue={today(shopTimezone)}
        name="dates"
        rules={{ validate: validateDates }}
      />
      <div className={styles.reason}>
        <Label id="closing-reason-label" htmlFor={`closing-reason-${action}`}>
          Reason
        </Label>
        <Controller
          control={control}
          name="closingReason"
          rules={{ required: 'Please select a reason.' }}
          render={({ field }) => (
            <Select
              {...field}
              aria-labelledby="closing-reason-label"
              className={styles.select}
              inputId={`closing-reason-${action}`}
              isInvalid={errors.closingReason != null}
              options={closingReasonOptions || []}
              placeholder="Select a Reason"
            />
          )}
        />
        <FormFeedback>{errors.closingReason?.message}</FormFeedback>
      </div>
      <div className={styles.comment}>
        <Label
          id={`closing-comment-label-${action}`}
          htmlFor={`closing-comment-${action}`}
        >
          Comment
        </Label>
        <Controller
          control={control}
          name="closingComment"
          render={({ field }) => (
            <TextInput
              characterLimit={24}
              id={`closing-comment-${action}`}
              onChange={field.onChange}
              placeholder="Add a comment"
              value={field.value}
            />
          )}
        />
      </div>
      <ButtonGroup autoCollapse>
        <Button
          isDisabled={isSubmitting}
          onPress={onClickCancel}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button isDisabled={isSubmitting} type="submit" variant="primary">
          Save
        </Button>
      </ButtonGroup>
    </form>
  );
};

ClosingForm.propTypes = {
  action: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  onClickCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  shopTimezone: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ClosingForm;
