import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';

import { Badge, Switch } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import Text from 'components/shared/text';
import { TooltipIconButton } from 'components/shared/tooltips';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useCouponsQuery } from 'hooks/discounts/use-coupons-query';
import { useToggleCouponStatusMutation } from 'hooks/discounts/use-toggle-coupon-status-mutation';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import { Coupon } from 'types/discounts';
import { Shop } from 'types/shops';
import { getCouponAmount } from 'utilities/discounts/coupons';
import { showUnexpectedErrorToast } from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  isRegisterShop: boolean;
};

export const Deals = ({ shopId, isRegisterShop }: Props) => {
  const { trackToggledADeal } = useAnalytics();
  const { data: coupons, isPending: isCouponsQueryLoading } =
    useCouponsQuery(shopId);
  const validCoupons = coupons?.filter((coupon) => !coupon.invalid) ?? [];

  const { mutate: toggleCouponStatus, isPending: isToggleCouponStatusLoading } =
    useToggleCouponStatusMutation(shopId);

  const columns = useMemo(() => {
    const helper = createColumnHelper<Coupon>();

    return [
      helper.accessor('id', {}),

      helper.accessor('name', {
        header: 'Name',
        cell(ctx) {
          return <Text wrap="normal">{ctx.getValue()}</Text>;
        },
        meta: { className: styles.nameColumn },
      }),

      helper.accessor('disabled', {
        header: 'Status',
        cell({ getValue }) {
          const isDisabled = getValue();

          return (
            <Badge variant={isDisabled ? 'neutral' : 'success'}>
              {isDisabled ? 'Inactive' : 'Active'}
            </Badge>
          );
        },
        meta: { className: styles.statusColumn },
      }),

      helper.display({
        id: 'amount',
        cell({ row: { original: coupon } }) {
          const displayAmount = getCouponAmount(coupon);
          return coupon.pricingType === 'bundle_price'
            ? displayAmount
            : `${displayAmount} OFF`;
        },
        header: 'Amount',
        enableSorting: false,
        meta: { className: styles.amountColumn },
      }),

      helper.display({
        id: 'enabledToggle',
        header: () => <VisuallyHidden>Enable or Disable Deal</VisuallyHidden>,
        meta: { isActionColumn: true, className: styles.toggleColumn },
        cell: (info) => {
          const coupon = info.row.original;

          return (
            <Switch
              aria-label="Enable Coupon"
              isSelected={!coupon.disabled}
              isReadOnly={isToggleCouponStatusLoading}
              onChange={(isChecked) => {
                toggleCouponStatus(
                  {
                    couponId: coupon.id,
                    isBeingEnabled: isChecked,
                  },
                  {
                    onSuccess: () => {
                      trackToggledADeal({
                        shopId,
                        isRegister: isRegisterShop,
                        dealAmount: getCouponAmount(coupon),
                        dealName: coupon.name,
                        dealType: coupon.pricingType,
                        toggleAction: isChecked ? 'ON' : 'OFF',
                        page: 'discounts',
                      });
                      toast.success(
                        `${coupon.name} was successfully ${isChecked ? 'enabled' : 'disabled'}.`,
                      );
                    },
                    onError: () => {
                      showUnexpectedErrorToast();
                    },
                  },
                );
              }}
            />
          );
        },
      }),
    ];
  }, [
    isRegisterShop,
    isToggleCouponStatusLoading,
    shopId,
    toggleCouponStatus,
    trackToggledADeal,
  ]);

  const table = useTable({
    columns,
    data: validCoupons,
    state: {
      isLoading: isCouponsQueryLoading,
    },
    initialState: {
      sorting: [{ id: 'id', desc: true }],
      columnVisibility: { id: false },
    },
    chameleonTableTitle: 'Deals',
  });

  const dealsTitleContent = (
    <div className={styles.tileHeader}>
      Deals
      <TooltipIconButton label="More about deals">
        {
          "Deals are discounts on specific items or categories, or a bundle price. They're automatically applied at checkout."
        }
      </TooltipIconButton>
    </div>
  );

  return (
    <ContentTile title={dealsTitleContent}>
      <Table className={styles.table} table={table} />
    </ContentTile>
  );
};
