import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import FeatureHighlight from 'components/shared/feature-highlight';
import Link from 'components/shared/link';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { SetStoredState } from 'hooks/use-stored-state';
import CalculatorIcon from 'images/calculator.svg?react';
import CaretIcon from 'images/caret.svg?react';
import { getRegisterSplashPath } from 'routes/paths/register';
import { CustomerType, CustomerTypeOption } from 'types/customers';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';
import { customerTypeLabels } from 'utilities/customers';
import { getShopHasFullRegister } from 'utilities/shops';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  customerType: CustomerType;
  customerTypeOptions: CustomerTypeOption[];
  hasValidCase: boolean;
  onRequestDemoButtonClick: () => void;
  page: string;
  setCustomerType: (customerType: CustomerType) => void;
  setStoredCustomerType: SetStoredState<CustomerType>;
  shop: Shop;
};

const CustomerTypeDropdown = ({
  className,
  customerType,
  customerTypeOptions,
  hasValidCase,
  onRequestDemoButtonClick,
  page,
  setCustomerType,
  setStoredCustomerType,
  shop,
}: Props) => {
  const { shopId } = shop;
  const shopTraits = useShopTraitsContext();

  const {
    trackClickedCustomerTypeDropdown,
    trackSelectedCustomerType,
    trackProductUpsellLinkClick,
  } = useAnalytics();

  const { isPending: isCreatingSalesforceCaseLoading } =
    useCreateSalesforceCaseMutation();

  const label = customerTypeOptions.find(
    (option) => option.value === customerType,
  )?.label;

  const handleClickDropdownItem = (selectedType: CustomerType): void => {
    trackSelectedCustomerType(
      shopId,
      customerTypeLabels[customerType],
      customerTypeLabels[selectedType],
      page,
    );

    setCustomerType(selectedType);

    setStoredCustomerType(selectedType);
  };

  const handleMenuButtonClick = () => {
    trackClickedCustomerTypeDropdown(
      shopId,
      customerTypeLabels[customerType],
      page,
    );
  };

  const onRequestDemoClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      onRequestDemoButtonClick();
    }
    trackProductUpsellLinkClick({
      linkLabel: 'request demo',
      outcome: 'lead',
      page: 'customer analytics',
      product: 'register',
      shopId,
      type: ClickedExternalLinkType.LinkedText,
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const onLearnMoreClick = () => {
    trackProductUpsellLinkClick({
      linkLabel: 'learn more',
      outcome: 'information',
      page: 'customer analytics',
      product: 'register',
      shopId,
      type: ClickedExternalLinkType.LinkedText,
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <div className={cx(styles.dropdownContainer, className)}>
      <Menu
        direction="bottom"
        menuButton={({ open }) => (
          <MenuButton
            className={styles.menuButton}
            onClick={handleMenuButtonClick}
          >
            <CalculatorIcon className={styles.menuButtonIcon} />
            <span className={styles.menuButtonText}>{label}</span>
            <CaretIcon className={open ? styles.caretUp : styles.caretDown} />
          </MenuButton>
        )}
        overflow="auto"
        position="initial"
      >
        <MenuItem
          className={styles.menuItem}
          onClick={() => handleClickDropdownItem(CustomerType.Online)}
        >
          Online
        </MenuItem>

        {getShopHasFullRegister(shop) ? (
          <>
            <MenuItem
              className={styles.menuItem}
              onClick={() => handleClickDropdownItem(CustomerType.Register)}
            >
              Register
            </MenuItem>
            <MenuItem
              className={styles.menuItem}
              onClick={() => handleClickDropdownItem(CustomerType.All)}
            >
              Online + Register
            </MenuItem>
          </>
        ) : (
          <FeatureHighlight
            icon="register"
            text="Slice Register is the easy-to-install POS that transforms your business and makes your customers more valuable."
            primaryCta={
              !hasValidCase ? (
                <Button
                  className={styles.requestDemoBtn}
                  variant={ButtonVariant.LinkStylePrimary}
                  onClick={() => onRequestDemoClick()}
                >
                  Request Demo
                </Button>
              ) : undefined
            }
            secondaryCta={
              <Link
                to={getRegisterSplashPath(shopId)}
                onClick={() => onLearnMoreClick()}
                className={styles.learnMoreLink}
              >
                Learn More
              </Link>
            }
          />
        )}
      </Menu>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerTypeDropdown;
