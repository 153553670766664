import { NotFoundError } from 'components/shared/error';
import Page from 'components/shared/page';

type Props = {
  isNavShown?: boolean;
};

const pageName = 'not-found';

export function NotFoundPage({ isNavShown = false }: Props): JSX.Element {
  return (
    <Page name={pageName} isNavShown={isNavShown} isHeaderShown={false}>
      <NotFoundError />
    </Page>
  );
}
