import { useCallback, useMemo, useState } from 'react';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import moment from 'moment-timezone';

import { ReportHeader } from 'components/reports/report-header';
import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import PageControl from 'components/shared/table/page-control';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTaxReportsQuery } from 'hooks/reports';
import { useTable } from 'hooks/shared';
import { ReportStatus, TaxReport } from 'types/financials';

import { BasicTaxReportsForm } from './form';

import styles from './styles.module.scss';

const DEFAULT_COLUMN_SORT = {
  id: 'createdAt',
  desc: true,
} as const;

type Props = {
  shopId: number;
  shopTimezone: string;
};

const BasicTaxReports = ({ shopId, shopTimezone }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_COLUMN_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data: reportsQueryResponse, isPending: isReportsQueryLoading } =
    useTaxReportsQuery(shopId, pagination, sorting[0] ?? DEFAULT_COLUMN_SORT);

  const [shouldShowForm, setShowShowForm] = useState(false);

  const handleClickCreateReport = useCallback(
    () => setShowShowForm((current) => !current),
    [],
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<TaxReport>();

    // When tax reports are generated in Admin, the timezone sent by Owner's
    // Portal is removed from the report timestamps without changing the
    // calendar date. The clock time is reset to start/end of day. The reports
    // are actually all generated reletive to Pacific Standard Time. When
    // fetching report data, we receive timestamps in UTC with the same calendar
    // date we sent, so we use moment.parseZone to prevent the fetched timestamp
    // from being converted to the browser's local timezone before formatting.
    // Otherwise, the formatted calendar date could be incorrect.
    // https://github.com/slicelife/myr-admin/blob/9c91e32ab247873b5c894b83f8a5ee55535480fa/app/controllers/api/v2/basic_tax_reports_controller.rb#L109
    const formatDateRange = (from: string, to: string) =>
      `${moment.parseZone(from).format('MM/DD/YYYY')} - ${moment
        .parseZone(to)
        .format('MM/DD/YYYY')}`;

    return [
      helper.accessor((it) => formatDateRange(it.fromDate, it.toDate), {
        id: 'report',
        header: 'Report',
        enableSorting: false,
        cell(ctx) {
          return (
            <div>
              <span className={styles.dateRange}>{ctx.getValue()}</span>
              <br />
              Tax Report
            </div>
          );
        },
        meta: {
          className: styles.reportColumn,
        },
      }),

      helper.accessor(
        (it) => moment.tz(it.createdAt, shopTimezone).format('MM/DD/YYYY'),
        {
          id: 'createdAt',
          header: 'Date Created',
          meta: {
            chameleonId: 'createdAt',
            className: styles.createdAtColumn,
            mobileHeading: 'Created',
          },
        },
      ),

      helper.display({
        id: 'download',
        header() {
          return <VisuallyHidden>Download</VisuallyHidden>;
        },
        meta: {
          isActionColumn: true,
        },
        cell(ctx) {
          const report = ctx.row.original;
          const range = formatDateRange(report.fromDate, report.toDate);

          const isFailed = report.status === ReportStatus.Failure;
          const isSuccess = report.status === ReportStatus.Success;

          if (isSuccess && report.pdfDownloadUrl) {
            return (
              <TableAction
                className={styles.downloadLink}
                icon="download"
                title={`Tax report for ${range}.`}
                to={report.pdfDownloadUrl}
              />
            );
          }

          // Success without a URL might as well be a failure.
          return (
            <span className={styles.downloadText}>
              {isFailed || isSuccess ? 'Failed' : 'Pending'}
            </span>
          );
        },
      }),
    ];
  }, [shopTimezone]);

  const table = useTable({
    columns,
    data: reportsQueryResponse?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: reportsQueryResponse?.meta.pagination.pages ?? 1,
    state: {
      isLoading: isReportsQueryLoading,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Tax reports',
  });

  return (
    <>
      <ReportHeader
        description="The tax reports contains the sales taxes that were applied to your sales
      in a timeframe."
        onClickCreateReport={handleClickCreateReport}
      />
      {shouldShowForm && (
        <BasicTaxReportsForm
          closeForm={() => setShowShowForm(false)}
          shopId={shopId}
          shopTimezone={shopTimezone}
        />
      )}
      <ContentTile>
        <Table className={styles.table} table={table} />
        <PageControl
          currentPage={table.getState().pagination.pageIndex + 1}
          setPage={(page: number) => table.setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
        />
      </ContentTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BasicTaxReports;
