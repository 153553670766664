import useCustomersAnalyticsQuery from 'hooks/customers/use-customers-analytics-query';
import {
  CustomerList,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';

import AnalyticsTile from '../analytics-tile';

import styles from './styles.module.scss';

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
};

const CustomerAnalyticsTiles = ({
  customerType,
  dateRange,
  shopId,
  timezone,
}: Props) => {
  const { data: customersAnalytics, isPending: isCustomersAnalyticsLoading } =
    useCustomersAnalyticsQuery({
      customerList: CustomerList.All,
      customerType,
      dateRange,
      registrationType: RegistrationType.All,
      shopId,
      timezone,
    });

  return (
    <div
      className={styles.analyticsContainer}
      data-testid="customer-dashboard-analytics-container"
    >
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={false}
        isLoading={isCustomersAnalyticsLoading}
        title="Number of All Customers"
        value={customersAnalytics?.usersCount ?? 0}
      />
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={false}
        isLoading={isCustomersAnalyticsLoading}
        title="Total Order Count"
        value={customersAnalytics?.orderCount ?? 0}
      />
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={true}
        isLoading={isCustomersAnalyticsLoading}
        title="Average Order Value"
        value={customersAnalytics?.averageOrderValue ?? 0}
      />
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={true}
        isLoading={isCustomersAnalyticsLoading}
        title="Total Spend"
        value={customersAnalytics?.orderTotal ?? 0}
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerAnalyticsTiles;
