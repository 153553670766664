import CampaignManager from 'components/campaign-manager';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import useCampaignManagerSummaryQuery from 'hooks/campaign-manager/use-campaign-manager-summary';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId } from 'hooks/shops';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';

const CampaignManagerPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: user,
    isError: isUserError,
    isPending: isUserLoading,
  } = useCurrentUserQuery();

  const {
    data: summaryData,
    isPending: isSummaryDataLoading,
    isError: isSummaryDataError,
  } = useCampaignManagerSummaryQuery(shopId);

  const traitsData = useShopTraitsContext();

  let content;
  if (isSummaryDataLoading || isUserLoading) {
    content = <Loading />;
  } else if (isSummaryDataError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <CampaignManager
        shopId={shopId}
        startDate={traitsData.amsFirstOnboardedDate ?? null}
        summaryData={summaryData}
        user={user}
      />
    );
  }

  return (
    <Page name="Digital Ads Report" title="Digital Ads Report">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CampaignManagerPage;
