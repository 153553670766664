import { useEffect, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import {
  CalendarDate,
  DateValue,
  now,
  parseAbsolute,
  parseDate,
  today,
} from '@internationalized/date';
import { z } from 'zod';

import {
  PresetDateRange,
  PresetRangePicker,
  PresetRangeState,
  PresetRangeValue,
  useDateFormatter,
} from 'crust';

import AnalyticsDropdownButton from 'components/analytics/dropdown-button';
import Header from 'components/shared/header';
import NavigationTabs from 'components/shared/navigation-tabs';
import { Suspended } from 'components/shared/suspended';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import * as paths from 'routes/paths';
import { OrderTypes } from 'types/orders';
import { AnalyticsStorageKeys } from 'utilities/analytics';
import {
  isFromLast24Hours,
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { getDefaultDateRangePresets } from 'utilities/shared/date-range-presets';

import CustomerAnalytics from './customers';
import OrderAnalytics from './orders';

import styles from './styles.module.scss';

const localStorageId = 'analytics';

const getStoredValue = () => {
  const item = localStorage.getItem(localStorageId);

  if (item) {
    try {
      return JSON.parse(item);
    } catch (error) {
      // Do nothing.
    }
  }
};

const setStoredValue = (dates: unknown) => {
  try {
    localStorage.setItem(
      localStorageId,
      JSON.stringify({
        lastUpdatedAt: now('UTC').toAbsoluteString(),
        dates,
      }),
    );
  } catch {
    // Do nothing.
  }
};

type SchemaContext = {
  maxValue: CalendarDate;
  presets: ReturnType<typeof getDefaultDateRangePresets>;
};

const getSchema = ({ maxValue, presets }: SchemaContext) => {
  const date = z
    .string()
    .date()
    .transform((it) => parseDate(it));
  return z.object({
    lastUpdatedAt: z
      .string()
      .datetime()
      .transform((it) => parseAbsolute(it, 'UTC'))
      .refine((it) => isFromLast24Hours(it)),
    dates: z
      .object({
        id: z.string(),
        label: z.string(),
        start: date,
        end: date,
      })
      .transform((value, ctx) => {
        const id = value.id;
        const preset = presets.find((it) => it.id === id);

        if (preset) {
          return preset;
        }

        if (
          value.end.compare(value.start) >= 0 &&
          value.end.compare(value.start) < 32 &&
          value.end.compare(maxValue) <= 0
        ) {
          return {
            id: 'custom',
            label: 'Custom',
            start: value.start,
            end: value.end,
          } as const;
        }

        // The message is unimportant here.
        ctx.addIssue({ code: z.ZodIssueCode.custom });

        return z.NEVER;
      }),
  });
};

type DateRangeFilterProps<T extends DateValue, S extends string> = {
  defaultValue: PresetRangeValue<T, S>;
  maxValue: CalendarDate;
  onChange: (value: PresetRangeValue<T, S>) => void;
  presets: readonly PresetDateRange<T, S>[];
  shopId: string;
  shopTimezone: string;
};

const DateRangeFilter = <T extends DateValue, S extends string>({
  defaultValue,
  maxValue,
  onChange,
  presets,
  shopId,
  shopTimezone,
}: DateRangeFilterProps<T, S>) => {
  const [dates, setDates] =
    useState<PresetRangeState<typeof presets>>(defaultValue);

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const error = useMemo(() => {
    if (dates == null) {
      return 'Please choose start and end dates.';
    }

    if (dates.end.compare(maxValue) > 0) {
      const formatted = dateFormatter.format(maxValue.toDate(shopTimezone));
      return `Please choose an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please choose an end date that is after the start date.';
    }

    if (dates.end.compare(dates.start) > 30) {
      return 'Please select a maximum of 31 days.';
    }
  }, [dateFormatter, dates, maxValue, shopTimezone]);

  useEffect(() => {
    if (dates != null && error == null) {
      onChange(dates);
    }
  }, [dates, error, onChange, shopId]);

  return (
    <PresetRangePicker
      aria-label="analytics date range"
      error={error}
      isInvalid={error != null}
      maxValue={maxValue}
      onChange={setDates}
      presets={presets}
      validationBehavior="aria"
      value={dates}
    />
  );
};

const getDateContext = (timezone: string) =>
  ({
    timezone,
    maxValue: today(timezone),
    presets: getDefaultDateRangePresets(timezone),
  }) as const;

type Props = {
  isRosEnabled: boolean;
  isSliceOsMode: boolean;
  shopId: string;
  shopTimezone: string;
};

export const Analytics = ({
  isRosEnabled,
  isSliceOsMode,
  shopId,
  shopTimezone,
}: Props) => {
  const { trackAnalyticsOrderTypeFilterChanged, trackClickedAnalyticsTab } =
    useAnalytics();

  const [dateContext, setDateContext] = useState(() =>
    getDateContext(shopTimezone),
  );

  useEffect(() => {
    if (shopTimezone !== dateContext.timezone) {
      setDateContext(getDateContext(shopTimezone));
    }
  }, [dateContext.timezone, shopTimezone]);

  const { presets, maxValue } = dateContext;

  const [dates, setDates] = useState<
    NonNullable<PresetRangeState<typeof presets>>
  >(() => {
    const stored = getStoredValue();

    if (stored) {
      const schema = getSchema({ maxValue, presets });
      const result = schema.safeParse(stored);
      if (result.success) {
        return result.data.dates;
      }
    }

    return presets[2];
  });

  useEffect(() => {
    setStoredValue({
      ...dates,
      start: dates.start.toString(),
      end: dates.end.toString(),
    });
  }, [dates]);

  const [selectedOrderType, setSelectedOrderType] = useStoredState(
    AnalyticsStorageKeys.OrderType,
    OrderTypes.Online,
  );

  const analyticsOrdersPath = paths.shopAnalyticsOrders(shopId);
  const analyticsCustomersPath = paths.shopAnalyticsCustomers(shopId);

  const ordersPathMatch = useMatch(analyticsOrdersPath);
  const customersPathMatch = useMatch(analyticsCustomersPath);

  const onSelectOrderType = (orderType: OrderTypes) => {
    setSelectedOrderType(orderType);
    trackAnalyticsOrderTypeFilterChanged({
      shopId: shopId,
      orderType: getOrderTypeText(orderType),
    });
  };

  const controls = [
    {
      name: 'Online Only',
      action: () => onSelectOrderType(OrderTypes.Online),
    },
    {
      name: 'Register Only',
      action: () => onSelectOrderType(OrderTypes.Offline),
    },
    {
      name: 'Online & Register',
      action: () => onSelectOrderType(OrderTypes.All),
    },
  ];

  const getOrderTypeText = (orderType = selectedOrderType) => {
    if (orderType === OrderTypes.Online) {
      return 'Online Only';
    } else if (orderType === OrderTypes.Offline) {
      return 'Register Only';
    }

    return 'Online & Register';
  };

  return (
    <>
      <div className={styles.header}>
        <Header
          title="Learn more about your shop's performance"
          content={[
            'See your performance in detail with insights into your sales, customers, menu and more.',
          ]}
        />
      </div>
      <div className={styles.filters}>
        <DateRangeFilter
          defaultValue={dates}
          maxValue={maxValue}
          onChange={setDates}
          presets={presets}
          shopId={shopId}
          shopTimezone={shopTimezone}
        />
        {isRosEnabled && !isSliceOsMode && (
          <AnalyticsDropdownButton
            controls={controls}
            selectedOption={getOrderTypeText()}
          />
        )}
      </div>
      <Suspended isLoading={!dates.start || !dates.end}>
        <NavigationTabs className={styles.tabs}>
          <NavigationTabs.Link
            onClick={() => trackClickedAnalyticsTab(shopId, 'orders')}
            to={analyticsOrdersPath}
          >
            Orders
          </NavigationTabs.Link>
          <NavigationTabs.Link
            onClick={() => trackClickedAnalyticsTab(shopId, 'customers')}
            to={analyticsCustomersPath}
          >
            Customers
          </NavigationTabs.Link>
        </NavigationTabs>
        <div className={styles.analyticsContentWrapper}>
          {ordersPathMatch && (
            <OrderAnalytics
              shopId={shopId}
              orderType={selectedOrderType}
              startDate={toStartOfDateAbsoluteString(dates.start, shopTimezone)}
              endDate={toEndOfDateAbsoluteString(dates.end, shopTimezone)}
            />
          )}
          {customersPathMatch && (
            <CustomerAnalytics
              shopId={shopId}
              orderType={selectedOrderType}
              startDate={toStartOfDateAbsoluteString(dates.start, shopTimezone)}
              endDate={toEndOfDateAbsoluteString(dates.end, shopTimezone)}
            />
          )}
        </div>
      </Suspended>
    </>
  );
};
