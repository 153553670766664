import { useCallback, useEffect } from 'react';

import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import SmartButtons from 'components/website-tools/smart-buttons';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { useWebsiteIntegrationsSettingsQuery } from 'hooks/website-tools/use-website-integrations-settings';
import {
  WebsiteIntegrationsAnalyticsPageName,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import {
  getIsPromoMessageAvailableForShop,
  getSmartButtonsAnalyticsValuesForForm,
  getSmartButtonsDefaultFormValuesFromSettings,
  isSmartButtonsFirstTimeSetup,
} from 'utilities/website-tools';

import styles from './styles.module.scss';

const SmartButtonsPage = () => {
  const shopId = useCurrentShopId();
  const { trackFetchedWebsiteIntegrationsSettings } = useAnalytics();

  const {
    data: user,
    isError: isUserError,
    isPending: isUserLoading,
  } = useCurrentUserQuery();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const isMultiLocEnabled = useCallback(
    (settings: WebsiteIntegrationsSettings) =>
      // Right now the multi-location feature is not dependant on the integration type, so the feature is enabled if at least one of them is.
      // Future work will be done to show different experiences based on the integration type.
      settings.floatingButtonMultilocationEnabled ||
      settings.navBarMultilocationEnabled ||
      settings.modalMultilocationEnabled,
    [],
  );

  const shopTraits = useShopTraitsContext();

  const handleFetchedSettings = useCallback(
    (data: WebsiteIntegrationsSettings) => {
      if (shop && shopTraits) {
        const isPromoMessageAvailable = getIsPromoMessageAvailableForShop(shop);
        const isFirstTimeSetup = isSmartButtonsFirstTimeSetup(data);
        const defaultFormValues = getSmartButtonsDefaultFormValuesFromSettings(
          data,
          isPromoMessageAvailable,
        );
        const multiLocEnabled = isMultiLocEnabled(data);
        const previouslySavedSettings = isFirstTimeSetup
          ? {}
          : getSmartButtonsAnalyticsValuesForForm({
              shopId: shopId,
              isFirstTimeSetup,
              formValues: defaultFormValues,
              isActive: String(shopTraits.isSmartButtonActive),
            });

        trackFetchedWebsiteIntegrationsSettings({
          shopId: String(shopId),
          previouslyEnabledSettings: !isFirstTimeSetup,
          isActive: String(shopTraits.isSmartButtonActive),
          page: multiLocEnabled
            ? WebsiteIntegrationsAnalyticsPageName.SmartButtonsMultiloc
            : WebsiteIntegrationsAnalyticsPageName.SmartButtons,
          shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
          ...previouslySavedSettings,
        });
      }
    },
    [
      shop,
      shopTraits,
      shopId,
      isMultiLocEnabled,
      trackFetchedWebsiteIntegrationsSettings,
    ],
  );

  const {
    data: smartButtonSettings,
    isError: isSmartButtonsSettingsError,
    isPending: isSmartButtonsSettingsLoading,
  } = useWebsiteIntegrationsSettingsQuery(shopId, {
    enabled: !!shop,
  });

  useEffect(() => {
    if (smartButtonSettings) {
      handleFetchedSettings(smartButtonSettings);
    }
  }, [smartButtonSettings, handleFetchedSettings]);

  let content;

  if (isShopLoading || isSmartButtonsSettingsLoading || isUserLoading) {
    content = <Loading />;
  } else if (isShopError || isSmartButtonsSettingsError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <SmartButtons
        shopTraits={shopTraits}
        shop={shop}
        smartButtonSettings={{ ...smartButtonSettings }}
        user={user}
        multiLocEnabled={
          smartButtonSettings && isMultiLocEnabled(smartButtonSettings)
        }
      />
    );
  }

  return (
    <Page
      contentClassName={styles.smartButtonsContent}
      mainClassName={styles.smartButtonPagesMain}
      name="viewed_smart_buttons"
      title="Smart Buttons"
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartButtonsPage;
