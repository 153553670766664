import { FormEvent, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button } from 'crust';

import Label from 'components/shared/label';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { User } from 'types/user';
import {
  SmartPopupFormValues,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import { EXPIRY_IN_30DAYS, getIsStoredDateActive } from 'utilities/salesforce';

import ColorDropdown from './color-dropdown';
import PromoDropdown from './promo-dropdown';
import ThemeDropdown from './theme-dropdown';

import styles from './styles.module.scss';

type Props = {
  control: Control<SmartPopupFormValues>;
  handleFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isPromoMessageAvailable: boolean;
  isMultiLocEnabled: boolean;
  isSaveDisabled: boolean;
  isSubmitting: boolean;
  setIsFormChanged: (isFormChanged: boolean) => void;
  shopId: number;
  user: User;
};

const SmartPopupForm = ({
  control,
  handleFormSubmit,
  isPromoMessageAvailable,
  isMultiLocEnabled,
  isSaveDisabled,
  setIsFormChanged,
  shopId,
  user,
}: Props) => {
  const page = isMultiLocEnabled
    ? WebsiteIntegrationsAnalyticsPageName.SmartPopupsMultiloc
    : WebsiteIntegrationsAnalyticsPageName.SmartPopups;

  const { trackProductUpsellLinkClick } = useAnalytics();
  const {
    mutate: createSalesforceCase,
    isPending: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const [calloutDismissedAt, setCalloutDismissedAt] = useStoredState<string>(
    `sb-${shopId}-multilocation-smart-popup-expires-at`,
  );

  const isDismissalActive = useMemo(() => {
    return getIsStoredDateActive(EXPIRY_IN_30DAYS, calloutDismissedAt);
  }, [calloutDismissedAt]);

  const handleSetLocalStorage = () => {
    const today = new Date();
    setCalloutDismissedAt(String(today));
  };

  const handleRequestSupportClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.SmartButton,
            subject: SalesforceSubject.SmartButton,
          },
          note: SalesforceNote.SmartPopupMultilocationNote,
          shopId,
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () => {
            handleSetLocalStorage();
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            );
          },
        },
      );
    }

    trackProductUpsellLinkClick({
      shopId: String(shopId),
      product: 'smart popup',
      page: 'smart popups multiloc',
      linkLabel: 'submit a request',
      type: ClickedExternalLinkType.LinkedText,
      outcome: 'support',
    });
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className={styles.form}
      name="Smart Popups Config Form"
    >
      <div className={styles.fields}>
        <div>
          <Label className={styles.fieldLabel} htmlFor="theme-dropdown">
            Theme
          </Label>
          <ThemeDropdown
            buttonClassName={styles.menuButton}
            control={control}
            setIsFormChanged={setIsFormChanged}
            shopId={shopId}
            page={page}
            shopTraits={{}}
          />
        </div>
        <div>
          <Label className={styles.fieldLabel} htmlFor="color-dropdown">
            Color
          </Label>
          <ColorDropdown
            buttonClassName={styles.menuButton}
            control={control}
            setIsFormChanged={setIsFormChanged}
            shopId={shopId}
            page={page}
            shopTraits={{}}
          />
        </div>

        {isPromoMessageAvailable && !isMultiLocEnabled && (
          <div>
            <Label className={styles.fieldLabel} htmlFor="promo-dropdown">
              Promo Message
            </Label>
            <PromoDropdown
              buttonClassName={styles.menuButton}
              control={control}
              setIsFormChanged={setIsFormChanged}
              shopId={shopId}
              page={page}
              shopTraits={{}}
            />
          </div>
        )}
        {isMultiLocEnabled && !isDismissalActive && (
          <div>
            <p className={styles.fieldLabel}>Information</p>
            <p className={styles.informationText}>
              <Button
                appearance="link"
                onPress={() => handleRequestSupportClick()}
              >
                Submit a request
              </Button>{' '}
              to update your multiple location Smart Popup settings.
            </p>
          </div>
        )}
      </div>
      {!isMultiLocEnabled && (
        <Button
          className={styles.saveButton}
          data-chameleon-target="Smart Popups Save Changes Button"
          isDisabled={isSaveDisabled}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      )}
    </form>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartPopupForm;
