import { useMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ContentTile from 'components/shared/content-tile';
import NavigationTabs from 'components/shared/navigation-tabs';
import { TooltipIconButton } from 'components/shared/tooltips';
import { useDeleteShopOpeningMutaion } from 'hooks/hours/use-delete-shop-opening-mutation';
import { useOpeningsQuery } from 'hooks/hours/use-openings';
import { useUpdateShopOpeningMutaion } from 'hooks/hours/use-update-shop-opening-mutation';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { showUnexpectedErrorToast } from 'utilities/forms';

import HoursEditor from './editor';
import { entriesByTypeAndDay } from './helpers';

import styles from './styles.module.scss';

const WeeklyHours = ({ shopId }) => {
  const { data: weeklyHoursData, isPending: isHoursLoading } =
    useOpeningsQuery(shopId);
  // format the hours to be by shipping type, then by day of week
  const formattedWeeklyHours =
    weeklyHoursData && entriesByTypeAndDay(weeklyHoursData);

  const { trackShopScheduleUpdated } = useAnalytics();

  const handleMutationError = () => {
    showUnexpectedErrorToast();
    trackShopScheduleUpdated(shopId, false);
  };

  const { mutate: deleteShopOpening, isPending: isDeleteHoursInProgress } =
    useDeleteShopOpeningMutaion(shopId);

  const deleteHours = (values) =>
    deleteShopOpening(values, {
      onError: handleMutationError,
    });
  const { mutate: updateShopOpening, isPending: isUpdateShopOpeningLoading } =
    useUpdateShopOpeningMutaion(shopId);

  const updateHours = (entries) =>
    updateShopOpening(entries, {
      onError: handleMutationError,
      onSuccess: () => {
        toast.success(
          "Your restaurant's hours of operation have been successfully updated.",
        );
        trackShopScheduleUpdated(shopId, true);
      },
    });

  const saveEntries = async (entries, onSaveSuccessCallback) => {
    const deleteEntries = [];
    const addAndUpdateEntries = [];
    try {
      entries
        .filter((entry) => entry.isModified)
        .forEach((entry) => {
          const isNew = entry.id.toString().includes('new');
          let formattedEntry = {
            day_of_week: entry.dayOfWeek,
            from: entry.from,
            id: isNew ? null : entry.id,
            open_for: entry.openFor,
            to: entry.to,
            isDeleted: entry.isDeleted,
          };
          if (isNew && !entry.isDeleted) {
            formattedEntry.type = 'create';
            addAndUpdateEntries.push(formattedEntry);
          } else if (!isNew && entry.isDeleted) {
            formattedEntry.type = 'delete';
            deleteEntries.push(formattedEntry);
          } else if (!isNew && !entry.isDeleted) {
            formattedEntry.type = 'update';
            addAndUpdateEntries.push(formattedEntry);
          } else {
            throw new Error(
              "invalid request type, must be 'create', 'delete' or 'update'",
            );
          }
        });
    } catch {
      showUnexpectedErrorToast();
      return;
    }

    // Delete hours first to avoid the following race condition:
    // User toggles shop closed - creates a deletion of an hours record
    // User Toggles shop open - creates a new hours record with same hours
    // Add request hits rez-api first which results in an overlap.
    await deleteHours(deleteEntries);
    updateHours(addAndUpdateEntries, {
      onSuccess: onSaveSuccessCallback,
    });
  };

  const editorProps = {
    isUpdateInProgress: isUpdateShopOpeningLoading || isDeleteHoursInProgress,
    saveEntries,
  };

  const weeklyHoursPath = paths.shopHoursWeekly(shopId);
  const deliveryHoursPath = paths.shopHoursDelivery(shopId);

  const tileHeader = (
    <div className={styles.tileHeader}>
      All Weekly Hours
      <TooltipIconButton label="More information about weekly hours">
        This is your weekly pickup and delivery schedule. Feel free to adjust
        your hours by clicking on a {"'Pencil'"} icon.
      </TooltipIconButton>
    </div>
  );

  const weeklyHoursMatch = useMatch(weeklyHoursPath);
  const deliveryHoursMatch = useMatch(deliveryHoursPath);

  return (
    <ContentTile
      className={styles.container}
      isLoading={isHoursLoading}
      title={tileHeader}
    >
      <NavigationTabs variant="secondary">
        <NavigationTabs.Link end to={weeklyHoursPath}>
          Pickup
        </NavigationTabs.Link>
        <NavigationTabs.Link end to={deliveryHoursPath}>
          Delivery
        </NavigationTabs.Link>
      </NavigationTabs>
      {weeklyHoursMatch && (
        <HoursEditor
          {...editorProps}
          entries={formattedWeeklyHours?.pickup}
          type="pickup"
        />
      )}
      {deliveryHoursMatch && (
        <HoursEditor
          {...editorProps}
          entries={formattedWeeklyHours?.delivery}
          type="delivery"
        />
      )}
    </ContentTile>
  );
};

WeeklyHours.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default WeeklyHours;
