import { CalendarDate } from '@internationalized/date';
import { RangeValue } from '@react-types/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { CreateSalesMixReportResponse } from 'types/financials/reports';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { camelCaseKeys } from 'utilities/objects';

import { getSalesMixReportsQueryKey } from '.';

type FormValues = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateSalesMixReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: FormValues) => {
      const params = {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
      };
      const query = `${queryString.stringify(params)}`;
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/sales_mix_reports?${query}`,
      );

      return camelCaseKeys(response) as CreateSalesMixReportResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getSalesMixReportsQueryKey(shopId),
      });
    },
  });
};
