import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CustomerOrderProfile } from 'types/customers';
import { UseQueryParameters } from 'types/queries';
import { camelCaseKeys } from 'utilities/objects';

type CustomerOrderProfileQueryParameters = {
  shopId: number;
  customerUuid: string;
};

type CustomerOrderProfileQueryOptions = {
  isEnabled: boolean;
};

export const getCustomerOrderProfileQueryKey = ({
  shopId,
  customerUuid,
}: CustomerOrderProfileQueryParameters) => [
  shopId,
  customerUuid,
  'getCustomerOrderProfile',
];

export const useCustomerOrderProfileQuery = ({
  parameters,
  options,
}: UseQueryParameters<
  CustomerOrderProfileQueryParameters,
  CustomerOrderProfileQueryOptions
>) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getCustomerOrderProfileQueryKey(parameters),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/v1/shops/${parameters.shopId}/marketing/customers/${parameters.customerUuid}`,
      );

      return camelCaseKeys(response) as CustomerOrderProfile;
    },
    enabled: options?.isEnabled,
  });
};
