import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Loading from 'components/shared/loading';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { useCreateUberEatsIntegrationMutation } from 'hooks/uber-eats-integration/use-create-uber-eats-integration-mutation';
import { useUberEatsLocationsQuery } from 'hooks/uber-eats-integration/use-uber-eats-locations-query';
import { ApiRequestError } from 'providers/api/helpers';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

export const UberEatsLocations = ({ shopId }: Props) => {
  const {
    data: uberEatsLocationsData,
    isPending: isUberEatsLocationsLoading,
    isSuccess: isUberEatsLocationsSuccess,
  } = useUberEatsLocationsQuery(shopId);

  const { mutateAsync: createUberEatsLocation } =
    useCreateUberEatsIntegrationMutation(shopId);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      location: '',
    },
    shouldUnregister: true,
  });

  const navigate = useNavigate();
  const shopUrl = paths.shopIntegrations(shopId);

  const handleValidSubmit = (values: { location: string }) => {
    if (values.location === 'none') {
      navigate(shopUrl);
    } else {
      return createUberEatsLocation(
        { uberEatsStoreId: values.location },
        {
          onSuccess() {
            navigate(shopUrl);
          },
          onError(err: Error) {
            let message = 'Sorry, something went wrong. Please try again.';

            if (
              err instanceof ApiRequestError &&
              Array.isArray(err.responseBody?.messages) &&
              err.responseBody?.messages.length
            ) {
              message = err.responseBody.messages.join(' ');
            }

            toast.error(message);
          },
        },
      );
    }
  };

  return (
    <ContentTile>
      {isUberEatsLocationsLoading ? (
        <Loading />
      ) : isUberEatsLocationsSuccess ? (
        uberEatsLocationsData.length === 0 ? (
          <>
            <p className={styles.header}>No Uber Eats Locations Found</p>
            <p className={styles.subheader}>
              {`We couldn't find any Uber Eats locations associated with your account, or they may already be linked to other Slice locations.`}
            </p>
            <Button
              variant="primary"
              onPress={() => {
                navigate(shopUrl);
              }}
            >
              Back to Integrations
            </Button>
          </>
        ) : (
          <>
            <p className={styles.header}>Confirm your Uber Eats Location</p>
            <p className={styles.subheader}>
              We’ve retrieved multiple locations for your business. Please
              confirm below.
            </p>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
              <RHFRadioGroup
                aria-label="uber-eats-locations"
                className={styles.radioGroup}
                control={control}
                name="location"
                orientation="horizontal"
                variant="card"
              >
                {uberEatsLocationsData.map((location) => (
                  <RHFRadio
                    value={location.id}
                    key={location.name}
                    label={location.name}
                  />
                ))}
                <RHFRadio value={'none'} label="None of these look correct." />
              </RHFRadioGroup>
              <Button variant="primary" type="submit" isDisabled={isSubmitting}>
                {isSubmitting ? 'Confirming Location...' : 'Confirm Location'}
              </Button>
            </form>
          </>
        )
      ) : (
        <div className={styles.header}>
          Something went wrong retrieving your Uber Eats Locations. Please try
          again or contact your Partner Success Manager.
        </div>
      )}
    </ContentTile>
  );
};
