import { useMemo } from 'react';
import { toast } from 'react-toastify';

import { Button } from 'crust';

import Callout from 'components/shared/callout';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import OnlineOrdering from 'images/callout/online-ordering.png';
import { CalloutEventType } from 'types/callout';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import {
  EXPIRY_IN_30DAYS,
  getExpiresAtDate,
  getIsStoredDateValid,
} from 'utilities/salesforce';

type Props = {
  hasCustomWebsite: boolean;
  hasOwnWebsite: boolean;
  hasSmartButton: boolean;
  isRegisterShop: boolean;
  shopId: Shop['shopId'];
  user: User;
};

const OnlineOrderingCallout = ({
  hasCustomWebsite,
  hasOwnWebsite,
  hasSmartButton,
  isRegisterShop,
  shopId,
  user,
}: Props) => {
  const { trackClickedCalloutAction, trackProductUpsellLinkClick } =
    useAnalytics();

  const [caseExpiration, setCaseExpiration] = useStoredState<string>(
    `sf-${shopId}-online-ordering-expires-at`,
  );

  const hasValidCase = useMemo(() => {
    return getIsStoredDateValid(caseExpiration);
  }, [caseExpiration]);

  const handleSetLocalStorage = () => {
    const value = getExpiresAtDate(EXPIRY_IN_30DAYS);
    setCaseExpiration(String(value));
  };

  const {
    mutate: createSalesforceCase,
    isPending: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const handleGetStartedClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.OnlineOrdering,
            subject: SalesforceSubject.OnlineOrdering,
          },
          note: SalesforceNote.OnlineOrderingNote,
          shopId: Number(shopId),
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () => {
            handleSetLocalStorage();
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            );
          },
        },
      );
    }
    trackProductUpsellLinkClick({
      linkLabel: 'get started',
      outcome: 'lead',
      page: 'home',
      product: 'online ordering',
      shopId: String(shopId),
      type: ClickedExternalLinkType.LinkedText,
    });
    trackClickedCalloutAction({
      '1stLinkLabel': 'Get Started',
      '2ndLinkLabel': null,
      actionClicked: 'Get Started',
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: false,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'online_ordering_callout',
    });
  };

  const analyticEvent: CalloutEventType = useMemo(
    () => ({
      '1stLinkLabel': 'Get Started',
      '2ndLinkLabel': null,
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: false,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'online_ordering_callout',
    }),
    [hasCustomWebsite, hasOwnWebsite, hasSmartButton, isRegisterShop, shopId],
  );

  return (
    <Callout
      event={analyticEvent}
      image={OnlineOrdering}
      primary={
        hasValidCase ? null : (
          <Button onPress={handleGetStartedClick}>Get Started</Button>
        )
      }
      text={'Lower your fees and boost orders through Google Business Profile.'}
    />
  );
};
/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OnlineOrderingCallout;
