import { useEffect, useMemo, useState } from 'react';
import { CalendarDate, parseAbsolute } from '@internationalized/date';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';

import { Link, PresetRangeValue, useDateFormatter } from 'crust';

import FilterDropdown from 'components/orders/filter-dropdown';
import TableFilter from 'components/orders/table-filter';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Pagination from 'components/shared/table/pagination';
import Text from 'components/shared/text';
import { useRegisterOrdersQuery } from 'hooks/orders';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { OrderTabs } from 'types/orders';
import { RegisterOrder, RegisterOrderFilterValue } from 'types/register-orders';
import { toDollarString, toMoneyString } from 'utilities/currency';
import { capitalize } from 'utilities/strings';

import RegisterOrderModal from '../register-order-modal';

import styles from './styles.module.scss';

const DEFAULT_SORT = {
  id: 'datePurchased',
  desc: true,
} as const;

type Props = {
  dates: PresetRangeValue<CalendarDate, string>;
  shopId: string;
  shopTimezone: string;
};

const filterOptions = [
  { label: 'All', value: RegisterOrderFilterValue.All },
  {
    label: 'Awaiting Payment',
    value: RegisterOrderFilterValue.AwaitingPayment,
  },
  { label: 'Cleared', value: RegisterOrderFilterValue.Cleared },
  { label: 'Held', value: RegisterOrderFilterValue.Held },
  { label: 'Paid', value: RegisterOrderFilterValue.Paid },
  {
    label: 'Partially Refunded',
    value: RegisterOrderFilterValue.PartiallyRefunded,
  },
  { label: 'Refunded', value: RegisterOrderFilterValue.Refunded },
];

const RegisterOrdersTable = ({ dates, shopId, shopTimezone }: Props) => {
  const {
    trackSelectedOrderStatusFilter,
    trackClickedOpenRegisterReceiptModal,
    trackClickedCreateAReport,
  } = useAnalytics();

  const [activeOrderId, setActiveOrderId] = useState<string | null>(null);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT]);

  const [selectedStatus, setSelectedStatus] =
    useState<RegisterOrderFilterValue>(RegisterOrderFilterValue.All);

  const setStatus = (status: string) => {
    setSelectedStatus(status as RegisterOrderFilterValue);
  };

  const setPerPage = (perPage: number): void => {
    setPagination({
      pageIndex: 0,
      pageSize: perPage,
    });
  };

  const { data: ordersResponse, isPending: isOrdersLoading } =
    useRegisterOrdersQuery({
      dates,
      page: pagination.pageIndex + 1,
      perPage: pagination.pageSize,
      shopId,
      shopTimezone,
      sort: sorting.at(0) ?? DEFAULT_SORT,
      orderStatus: selectedStatus,
    });

  const totalPages = ordersResponse?.meta.pages ?? 0;

  const suppressPagination =
    !ordersResponse?.meta.results || isOrdersLoading || totalPages <= 1;

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  }, [dates, selectedStatus]);

  // MM/DD/YYYY in en-US.
  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const timeFormatter = useDateFormatter({
    timeStyle: 'short',
    timeZone: shopTimezone,
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<RegisterOrder>();

    return [
      helper.accessor('createdAt', {
        id: 'datePurchased',
        header: 'Date',
        enableSorting: true,
        cell(ctx) {
          const value = parseAbsolute(ctx.getValue(), shopTimezone).toDate();

          return (
            <div className={styles.dateCell}>
              <div>{dateFormatter.format(value)}</div>
              <div className={styles.time}>{timeFormatter.format(value)}</div>
            </div>
          );
        },
        meta: {
          className: styles.dateColumn,
        },
      }),
      helper.accessor(
        (row) => `${row.firstName || ''} ${row.lastName || ''}`.trim(),
        {
          id: 'customerName',
          header: 'Customer',
          enableSorting: true,
          meta: {
            className: styles.customerColumn,
          },
          cell(ctx) {
            return <Text wrap="truncate">{ctx.getValue()}</Text>;
          },
        },
      ),
      helper.accessor((row) => capitalize(row.shippingType).replace('_', ' '), {
        id: 'shippingType',
        header: 'Type',
        enableSorting: true,
        meta: {
          className: styles.orderTypeColumn,
        },
      }),
      helper.accessor(
        (row) => toMoneyString(toDollarString(row.tip), true, true),
        {
          id: 'tip',
          header: 'Tips',
          enableSorting: true,
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (row) => toMoneyString(toDollarString(row.refundedTotal), true, true),
        {
          id: 'refundedTotal',
          header: 'Refund',
          enableSorting: true,
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (row) => toMoneyString(toDollarString(row.total), true, true),
        {
          id: 'total',
          header: 'Total',
          enableSorting: true,
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (row) => row.orderStatus.split('_').map(capitalize).join(' '),
        {
          id: 'status',
          header: 'Status',
          enableSorting: true,
          meta: {
            className: styles.orderStatusColumn,
          },
        },
      ),
      helper.display({
        id: 'modal',
        header: '',
        meta: {
          className: styles.receiptColumn,
          isActionColumn: true,
        },
        cell(ctx) {
          return (
            <TableAction
              icon="orderDetails"
              title="Download receipt"
              onClick={() => {
                setActiveOrderId(ctx.row.original.orderUuid);
                trackClickedOpenRegisterReceiptModal({ shopId });
              }}
            />
          );
        },
      }),
    ];
  }, [
    shopTimezone,
    dateFormatter,
    timeFormatter,
    trackClickedOpenRegisterReceiptModal,
    shopId,
  ]);

  const table = useTable({
    columns,
    data: ordersResponse?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    pageCount: ordersResponse?.meta?.results,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading: isOrdersLoading,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Register Orders',
  });

  const handleCloseModal = (): void => {
    setActiveOrderId(null);
  };

  const trackItemClickHandler = (status: string) => {
    trackSelectedOrderStatusFilter({
      orderStatus: status as RegisterOrderFilterValue,
      shopId,
      tab: OrderTabs.Register,
    });
  };

  const selectedFilterOption = filterOptions.find(
    (option) => option.value === selectedStatus,
  ) ?? { label: 'undefined', value: 'undefined' };

  const handlePageChange = (selectedPage: number) => {
    table.setPageIndex(selectedPage - 1);
  };

  const handleCreateAReportClick = () => {
    trackClickedCreateAReport({
      dateRangeLabel: dates.label.toLowerCase(),
      endDate: dates.end.toString(),
      shopId,
      startDate: dates.start.toString(),
      tab: OrderTabs.Register,
    });
  };

  return (
    <>
      <TableFilter
        currentPage={table.getState().pagination.pageIndex}
        dates={dates}
        itemsTotal={ordersResponse?.meta?.results ?? 0}
        maxPageSize={table.getState().pagination.pageSize}
        pageSize={ordersResponse?.data?.length ?? 0}
        timezone={shopTimezone}
      >
        <FilterDropdown
          filterBy="Order Status"
          filterOptions={filterOptions}
          selectedStatus={selectedFilterOption}
          setSelectedStatus={setStatus}
          onItemClick={trackItemClickHandler}
        />
        <Link
          appearance="button"
          variant="primary"
          onPress={handleCreateAReportClick}
          routerOptions={{
            state: {
              orderType: 'register',
              startDate: dates.start.toString(),
              endDate: dates.end.toString(),
            },
          }}
          href={paths.getFinancialsReportsOrdersPath(shopId)}
        >
          Create a Report
        </Link>
      </TableFilter>
      <RegisterOrderModal
        handleCloseModal={handleCloseModal}
        isOpen={!!activeOrderId}
        shopId={shopId}
        orderId={activeOrderId ?? ''}
        timezone={shopTimezone}
      />
      <Table className={styles.orderTable} table={table} />
      <Pagination
        currentPage={table.getState().pagination.pageIndex + 1}
        isPageControlVisible={!suppressPagination}
        onChangePage={handlePageChange}
        totalPages={ordersResponse?.meta?.pages ?? 0}
        perPage={pagination.pageSize}
        onChangePerPage={setPerPage}
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterOrdersTable;
