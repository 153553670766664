import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CalendarDate, today } from '@internationalized/date';

import { RangeValue, useDateFormatter } from 'crust';

import { CreateReportForm } from 'components/reports/create-report-form';
import { RHFDateRangePicker } from 'components/shared/rhf-date-range-picker';
import { useCreateStandalonePaymentsReportMutation } from 'hooks/reports/use-create-standalone-payments-report-mutation';
import useAnalytics from 'hooks/use-analytics';
import { ReportAnalyticsType } from 'types/financials';
import { Shop } from 'types/shops';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import styles from './styles.module.scss';

type FormValues = {
  dates: RangeValue<CalendarDate>;
};

type Props = {
  closeForm: () => void;
  shopId: Shop['shopId'];
  shopTimezone: string;
};

// This date matches roughly when the backend was able to produce accurate reports for standalone payments - https://app.shortcut.com/slicelife/epic/491809/standalone-payments-report-on-op
const EARLIEST_REPORT_DATE = new CalendarDate(2024, 2, 21);

export const StandalonePaymentsReportForm = ({
  closeForm,
  shopId,
  shopTimezone,
}: Props) => {
  const maxDate = today(shopTimezone);
  const { trackCreateReportClick } = useAnalytics();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      // The date picker start blank. There is no CalendarDate value we can
      // provide that will satisfy TS, but also represent a invalid value. The
      // DateRangePicker accepts null, though.
      dates: null as unknown as FormValues['dates'],
    },
    mode: 'onChange',
  });

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const validateDates = (dates: RangeValue<CalendarDate>) => {
    if (!dates || !dates.start || !dates.end) {
      return 'Please select start and end dates.';
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please select an end date that is after the start date.';
    }

    if (dates.end.compare(maxDate) > 0) {
      const formatted = dateFormatter.format(maxDate.toDate(shopTimezone));
      return `Please select an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) > 30) {
      return 'Please select a maximum of 31 days.';
    }

    if (dates.start.compare(EARLIEST_REPORT_DATE) < 0) {
      const formatted = dateFormatter.format(
        EARLIEST_REPORT_DATE.toDate(shopTimezone),
      );
      return `Please select a date after ${formatted}`;
    }

    return true;
  };

  const { mutate: createReport, isPending: isCreatingReport } =
    useCreateStandalonePaymentsReportMutation(shopId);

  const handleValidSubmit = (values: FormValues) =>
    createReport(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: (_data, { dates }) => {
        trackCreateReportClick({
          type: ReportAnalyticsType.StandalonePayments,
          startDate: dates.start.toString(),
          endDate: dates.end.toString(),
        });
        toast.success('Report is being generated. Check back soon!');
        closeForm();
      },
    });

  return (
    <CreateReportForm
      onCancel={closeForm}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      isSubmitting={isCreatingReport || isSubmitting}
    >
      <RHFDateRangePicker
        control={control}
        name="dates"
        label="Date Range"
        maxValue={maxDate}
        minValue={EARLIEST_REPORT_DATE}
        rules={{
          validate: validateDates,
        }}
      />
      <p className={styles.helpText}>
        This report contains only credit card payments processed on Register
        POS.
      </p>
    </CreateReportForm>
  );
};
