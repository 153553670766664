import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductsResponseBody } from 'types/menu/api';
import { UseQueryParameters } from 'types/queries';
import { Shop } from 'types/shops';
import { createIntegerComparator, Direction } from 'utilities/sorting';

type MenuProductsQueryParameters = {
  shopId: Shop['shopId'] | string;
  channel?: 'online' | 'offline';
};

type MenuProductsQueryOptions = {
  isEnabled: boolean;
};

export const getMenuProductsQueryKey = ({
  shopId,
  channel,
}: MenuProductsQueryParameters) => {
  const key = [Number(shopId), 'menu-products'];
  if (channel) {
    key.push(channel);
  }
  return key;
};

export const useMenuProductsQuery = ({
  parameters,
  options,
}: UseQueryParameters<
  MenuProductsQueryParameters,
  MenuProductsQueryOptions
>) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getMenuProductsQueryKey(parameters),
    queryFn: async (): Promise<MenuProductsResponseBody> => {
      const param = parameters.channel ? `?channel=${parameters.channel}` : '';
      const response = (await authenticatedFetch.get(
        `api/management/v2/shops/${parameters.shopId}/menus/products${param}`,
      )) as MenuProductsResponseBody;
      const comparator = createIntegerComparator('id', {
        direction: Direction.Descending,
      });

      // The API is non-deterministic, which will cause the cache to always miss.
      response.categories.sort(comparator);
      response.products.sort(comparator);
      response.products.forEach((it) => {
        it.types.sort(comparator);
      });

      return response;
    },
    staleTime: 60000,
    enabled: options?.isEnabled,
  });
};
