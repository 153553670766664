import { useParams } from 'react-router-dom';

// Each shop can only have one online discount, so the possible actions we can
// retrieve from the URL params are only "edit" or "new".
// Any other value will be considered invalid, returning a NotFoundPage.

type Params = {
  discountAction?: 'edit' | 'new';
};

export const useCurrentOnlineDiscount = (): Params['discountAction'] => {
  const { '*': fullPath = '' } = useParams();
  const discountAction = fullPath.split('/').pop() as Params['discountAction'];
  if (discountAction !== 'edit' && discountAction !== 'new') {
    return undefined;
  }
  return discountAction;
};
