import { ReactNode } from 'react';

import { Button } from 'crust';

import ContentTile from 'components/shared/content-tile';
import useAnalytics from 'hooks/use-analytics';

import styles from './styles.module.scss';

type IntegrationListItemProps = {
  copyText?: ReactNode;
  disabled?: boolean;
  imageAltText?: string;
  imageSource?: string;
  isConnected?: boolean;
  onDisconnect?: () => void;
  provider: string;
  redirectUrl: string;
};

const IntegrationListItem = ({
  copyText = '',
  disabled = false,
  imageAltText = '',
  imageSource = '',
  isConnected = false,
  onDisconnect = () => {},
  provider,
  redirectUrl,
}: IntegrationListItemProps) => {
  const { trackClickIntegrationItem } = useAnalytics();

  let buttonText;

  if (disabled) {
    buttonText = 'Call Us';
  } else if (isConnected) {
    buttonText = 'Disconnect';
  } else {
    buttonText = 'Connect';
  }

  const handleClick = () => {
    trackClickIntegrationItem(provider);

    if (isConnected) {
      onDisconnect();
    } else {
      window.location.replace(redirectUrl);
    }
  };

  // There is no loading state anywhere on this page, so disable the button
  // until it will actually do something.
  const isDisabled = disabled || (!isConnected && !redirectUrl);

  return (
    <ContentTile className={styles.posListItem}>
      <img
        src={imageSource}
        alt={imageAltText}
        className={styles.posListItemImage}
      />
      <div className={styles.posListItemCopy}>{copyText}</div>

      <Button variant="secondary" isDisabled={isDisabled} onPress={handleClick}>
        {buttonText}
      </Button>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default IntegrationListItem;
