import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetOrdersReportsResponse,
  GetReportsRequestParams,
  GetReportsResponse,
  OrdersReport,
} from 'types/financials';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { toSnakeCase } from 'utilities/strings';

type UseOrdersReportsQueryOptions = Omit<
  UseQueryOptions<GetOrdersReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getOrdersReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'ordersReports', ...(params ? [params] : [])];

export const useOrdersReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseOrdersReportsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    // Does not use leading "+".
    sort: `${sorting.desc ? '-' : ''}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getOrdersReportsQueryKey(shopId, params),
    queryFn: async () => {
      const query = `${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/order_ledger?${query}`,
      );

      return camelCaseKeys(response) as GetReportsResponse<OrdersReport>;
    },
    placeholderData: keepPreviousData,
    ...options,
  });
};
