import { Analytics } from 'components/analytics';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import { RegisterModeName } from 'types/shops';

export const AnalyticsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const isSliceOsMode = shop?.registerMode.name == RegisterModeName.Basic;

  let content;

  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <Analytics
        isRosEnabled={shop.rosEnabled}
        isSliceOsMode={isSliceOsMode}
        shopId={shopId.toString()}
        shopTimezone={shop.timezoneIdentifier}
      />
    );
  }

  return (
    <Page name="analytics" title="Analytics">
      {content}
    </Page>
  );
};
