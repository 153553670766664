import { useInfiniteQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { CustomerOrderProfile } from 'types/customers';
import { UseQueryParameters } from 'types/queries';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

type CustomersOrdersQueryParameters = {
  shopId: number;
  customerUuid: string;
};

type CustomersOrdersQueryOptions = {
  isEnabled: boolean;
};

export const getCustomersOrdersQueryKey = ({
  shopId,
  customerUuid,
}: CustomersOrdersQueryParameters) => [
  shopId,
  customerUuid,
  'getCustomersOrders',
];

export const useCustomersOrdersQuery = ({
  parameters,
  options,
}: UseQueryParameters<
  CustomersOrdersQueryParameters,
  CustomersOrdersQueryOptions
>) => {
  const { authenticatedFetch } = useApi();

  return useInfiniteQuery({
    queryKey: getCustomersOrdersQueryKey(parameters),
    queryFn: async () => {
      let query = '';
      const pageParams = {
        page: 1,
        perPage: 5,
      };

      query = `?${queryString.stringify(snakeCaseKeys(pageParams))}`;

      const response = await authenticatedFetch.get(
        `api/v1/shops/${parameters.shopId}/marketing/customers/${parameters.customerUuid}${query}`,
      );

      const transformedResponse: CustomerOrderProfile = camelCaseKeys(response);

      const pagedData = {
        data: transformedResponse.orders,
        nextPage: pageParams
          ? pageParams.page * pageParams.perPage <
            transformedResponse.orderCount
            ? pageParams.page + 1
            : undefined
          : 2,
      };

      return pagedData;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: options?.isEnabled,
  });
};
