import { useState } from 'react';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { Suspended } from 'components/shared/suspended';
import CompletedCheckmarkIcon from 'images/shop-score/completed-checkmark.svg?react';
import CompletedPlaceholderIcon from 'images/shop-score/completed-placeholder.svg?react';
import OutlineIcon from 'images/shop-score/progress-status-outline.svg?react';
import UncompletedPlaceholderIcon from 'images/shop-score/uncompleted-placeholder.svg?react';

import ActionItem from './action';

import styles from './styles.module.scss';

export type ActionData = {
  id: string;
  buttonText: string;
  disabledButtonText: string;
  buttonLink: string;
  chameleonTag: string;
  icon: string;
  isCompleted: boolean;
  isToastShownOnButtonClick: boolean;
  description: string;
  descriptionLink: string;
  descriptionUrl: string;
  title: string;
};

type Action = {
  shopId: string;
} & ActionData;

type Props = {
  actions: Action[];
  isShopScoreInProgress: boolean;
  isShopScoreLoading: boolean;
  shopId: string;
};

const ActionsOverview = ({
  actions,
  isShopScoreInProgress,
  isShopScoreLoading,
  shopId,
}: Props) => {
  const [isUncompletedOpen, setIsUncompletedOpen] = useState(true);
  const [isCompletedOpen, setIsCompletedOpen] = useState(false);

  const completedActions = actions.filter((action) => action.isCompleted);
  const uncompletedActions = actions.filter((action) => !action.isCompleted);

  return (
    <>
      <CollapsibleTile
        description="Start improving your score by completing these items."
        headerClassName={styles.tileHeader}
        isOpen={isUncompletedOpen}
        setIsOpen={setIsUncompletedOpen}
        summary={uncompletedActions.length}
        title="Your Success List"
      >
        <Suspended isLoading={isShopScoreLoading} variant="short">
          {!isShopScoreInProgress && uncompletedActions.length ? (
            uncompletedActions.map((option) => (
              <ActionItem data={option} key={option.id} shopId={shopId} />
            ))
          ) : (
            <div className={styles.inProgressWrapper}>
              {isShopScoreInProgress ? (
                <OutlineIcon
                  className={styles.placeholderIcon}
                  aria-hidden="true"
                />
              ) : (
                <UncompletedPlaceholderIcon
                  className={styles.placeholderIcon}
                  aria-hidden="true"
                />
              )}
              <div className={styles.inProgressText}>
                {isShopScoreInProgress
                  ? 'Check back soon to see what actions you need to take to start getting more orders with Slice!'
                  : 'You’ve completed all actions!'}
              </div>
            </div>
          )}
        </Suspended>
      </CollapsibleTile>
      <CollapsibleTile
        headerClassName={styles.tileHeader}
        isOpen={isCompletedOpen}
        setIsOpen={setIsCompletedOpen}
        summary={completedActions.length}
        title={
          <>
            <CompletedCheckmarkIcon
              className={styles.completedIcon}
              aria-hidden="true"
            />
            Completed
          </>
        }
        titleClassName={styles.completedTileTitle}
      >
        <Suspended isLoading={isShopScoreLoading} variant="short">
          {!isShopScoreInProgress && completedActions.length ? (
            completedActions.map((option) => (
              <ActionItem data={option} key={option.id} shopId={shopId} />
            ))
          ) : (
            <div className={styles.inProgressWrapper}>
              <CompletedPlaceholderIcon
                className={styles.placeholderIcon}
                aria-hidden="true"
              />
              <div className={styles.inProgressText}>
                You don’t have any completed actions yet.
              </div>
            </div>
          )}
        </Suspended>
      </CollapsibleTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ActionsOverview;
