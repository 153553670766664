import * as log from 'utilities/log';

const noopStatusCodes = [204, 205, 304];

// Only exported for tests, used to replace a lodash function previously used
/** @deprecated */
export const noop = () => {
  // Do nothing
};

export class ApiRequestError extends Error {
  constructor(message, status = null, responseBody = null) {
    super(message);
    this.name = 'ApiRequestError';
    this.status = status;
    this.responseBody = responseBody;
  }
}

export class NetworkError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NetworkError';
  }
}

export const checkStatusCode = (
  response,
  responseBody,
  includeResponseHeaders,
) => {
  // We allow 304 because Core API returns it very often
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 304
  ) {
    if (noopStatusCodes.includes(response.status)) {
      return { json: noop };
    }
    return includeResponseHeaders
      ? { body: responseBody, headers: response.headers }
      : responseBody;
  }
  throw new ApiRequestError(response.statusText, response.status, responseBody);
};

export const getAuthHeader = (accessToken) => {
  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` };
  }
};

export const getContentAndAuthHeaders = (
  accessToken,
  contentType = 'application/json',
) => ({
  ...getAuthHeader(accessToken),
  'Content-Type': contentType,
});

/*
This function is tripple wrapped to allow for depedancy injection. This does make it a little
hard to read, but I believe the end result functions excellently and is relatively easy to use/test.
*/
export const refreshAndRetryOnAuthFail =
  (
    // eslint-disable-next-line no-use-before-define
    fetch,
    getAccessTokenSilently,
  ) =>
  (options, shouldRefreshAndRetryOnAuthFail = true) =>
  async (response) => {
    if (response.status !== 401 || !shouldRefreshAndRetryOnAuthFail) {
      return response;
    }

    log.info('Invalid access token! No 🍕 for you');

    try {
      const token = await getAccessTokenSilently();

      log.info('Successfully refreshed tokens! You earned some 🍕');

      if (options.headers) {
        options.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.error(error);
      log.info('Unable to refresh tokens, go grab some 🍕');
      // The redirect logic really doesn't belong here, as the API layer
      // shouldn't depend on app logic (in this case, what is an authenticated
      // route to know if a redirect is needed). However, until we have
      // consistent app-level error handling, this method is the best place to
      // have the redirect.

      // Unable to retrieve an access token so log the user out.
      window.location.href = '/logout';

      return response;
    }

    return fetch(response.url, options);
  };
