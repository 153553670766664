import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { UseQueryParameters } from 'types/queries';
import { Shop } from 'types/shops/shop';
import { sortRelationships } from 'utilities/menu';

import { getMenuQueryKey } from './use-menu-query';

type ProductQueryParameters = {
  shopId: Shop['shopId'] | string;
  productId: MenuProduct['id'];
};

type ProductQueryOptions = { isEnabled?: boolean; staleTime?: number };

export const getProductQueryKey = ({
  shopId,
  productId,
}: ProductQueryParameters) => [
  ...getMenuQueryKey({ shopId }),
  'products',
  productId,
];

export const useProductQuery = ({
  parameters,
  options,
}: UseQueryParameters<ProductQueryParameters, ProductQueryOptions>) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getProductQueryKey(parameters),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${parameters.shopId}/menus/products/${parameters.productId}`,
      );
      return sortRelationships(response as MenuProductResponseBody);
    },
    staleTime: options?.staleTime,
    enabled: options?.isEnabled,
  });
};
