import streamLogo from 'images/integrations/stream-logo.png';

import IntegrationListItem from '..';

import styles from './styles.module.scss';

export const StreamIntegrationListItem = () => (
  <IntegrationListItem
    copyText={
      <>
        <p className={styles.copy}>
          View all your Slice orders directly to your POS through Stream
        </p>
        <p className={styles.copy}>
          Click the &quot;Connect&quot; button to get started. After you are
          connected, let you account manager know so they can turn on the
          integration. You can also contact us at partner@slicelife.com or
          855-237-4992 to have us walk you through the process.
        </p>
        <p className={styles.copy}>
          This account is currently not connected to a POS using Stream.
        </p>
      </>
    }
    imageAltText="Stream logo"
    imageSource={streamLogo}
    redirectUrl="_blank"
    provider="stream"
  />
);
