import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuCategoryResponseBody } from 'types/menu/api';
import { MenuCategory } from 'types/menu/category';
import { UseQueryParameters } from 'types/queries';
import { Shop } from 'types/shops';
import { sortRelationships } from 'utilities/menu';

import { getMenuQueryKey } from './use-menu-query';

type CategoryQueryParameters = {
  shopId: Shop['shopId'];
  categoryId: MenuCategory['id'];
};

type CategoryQueryOptions = { isEnabled: boolean; staleTime: number };

export const getCategoryQueryKey = ({
  shopId,
  categoryId,
}: CategoryQueryParameters) => [
  ...getMenuQueryKey({ shopId }),
  'categories',
  categoryId,
];

export const useCategoryQuery = ({
  parameters,
  options,
}: UseQueryParameters<CategoryQueryParameters, CategoryQueryOptions>) => {
  const { authenticatedFetch } = useApi();

  // This endpoint does not provide the products in the category.
  return useQuery({
    queryKey: getCategoryQueryKey(parameters),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${parameters.shopId}/menus/categories/${parameters.categoryId}`,
      );
      return sortRelationships(response as MenuCategoryResponseBody);
    },
    staleTime: options?.staleTime,
    enabled: options?.isEnabled,
  });
};
