import RegisterPermissionGroupForm from 'components/register/users/register-permission';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentRegisterPermissionGroupId } from 'hooks/register/use-current-register-permission-group-id';
import {
  useRecommendedRegisterPermissionGroupsQuery,
  useRegisterPermissionGroupsQuery,
} from 'hooks/register-users';
import { useCurrentShopId } from 'hooks/shops';

const RegisterPermissionGroupPage = () => {
  const shopId = useCurrentShopId();
  const permissionGroupId = useCurrentRegisterPermissionGroupId();

  const isNew = permissionGroupId === 'new';
  const name = isNew
    ? 'create-register-permission-group'
    : 'edit-register-permission-group';

  const {
    data: recommendedPermissionGroups,
    isError: isRecommendedPermissionGroupsError,
    isPending: isRecommendedGroupsLoading,
  } = useRecommendedRegisterPermissionGroupsQuery();

  const {
    data: permissionGroups,
    isError: isRegisterPermissionGroupsError,
    isPending: isPermissionGroupsLoading,
    isFetching: isPermissionGroupsFetching,
  } = useRegisterPermissionGroupsQuery(String(shopId), {
    enabled: !isNew,
  });

  const isLoading =
    (isPermissionGroupsLoading && isPermissionGroupsFetching) ||
    isRecommendedGroupsLoading;

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (
    isRecommendedPermissionGroupsError ||
    isRegisterPermissionGroupsError
  ) {
    content = <UnexpectedError />;
  } else {
    content = (
      <RegisterPermissionGroupForm
        permissionGroupId={permissionGroupId}
        permissionGroups={permissionGroups}
        recommendedPermissionGroups={recommendedPermissionGroups}
        shopId={shopId}
      />
    );
  }

  return (
    <Page name={name} title="Register Users">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPermissionGroupPage;
