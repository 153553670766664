import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, ButtonGroup } from 'crust';

import { RHFTextField } from 'components/shared/rhf-text-field';
import { useSaveRegisterProfileMutation } from 'hooks/register/use-save-register-profile-mutation';
import {
  RegisterProfile,
  RegisterProfileFormValues,
} from 'types/register-profile';
import { Shop } from 'types/shops';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  onCancel: () => void;
  onSave: () => void;
  profile?: RegisterProfile;
  profiles: RegisterProfile[];
  shopId: Shop['shopId'];
};

// Needs to match the regular expression used by the ROS service:
// https://github.com/slicelife/ros-service/blob/master/lib/schemas/fields.py#L14
const NAME_REGEX = /^[A-Za-z0-9 _-]+$/;

const RegisterProfileForm = ({
  onCancel: handleCancel,
  onSave: handleSave,
  profile,
  profiles,
  shopId,
}: Props) => {
  const {
    mutate: save,
    isPending: isSaving,
    isSuccess: isSaved,
  } = useSaveRegisterProfileMutation(shopId);

  // The handleSave method could cause this component to unmount so we need to
  // make sure it is called after any state updates are rendered.
  useEffect(() => {
    if (isSaved) {
      handleSave();
    }
  }, [handleSave, isSaved]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      id: profile?.id ?? '',
      name: profile?.name ?? '',
    },
    mode: 'onTouched',
  });

  const validateName = (value: string) => {
    value = value.trim();

    if (!value) {
      return 'Please enter a name.';
    }

    if (value.length > 50) {
      return 'Please enter no more than 50 characters.';
    }

    if (!NAME_REGEX.test(value)) {
      return 'Please use only letters, numbers, spaces, dashes, and underscores.';
    }

    if (profiles.some((it) => it !== profile && it.name === value)) {
      return 'Cannot have two registers with the same name.';
    }

    return true;
  };

  const handleValidSubmit = (values: RegisterProfileFormValues) =>
    save(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
    });

  return (
    <form onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}>
      <RHFTextField
        className={styles.field}
        control={control}
        data-chameleon-target="Register Profile Name Field"
        description="e.g. Kitchen"
        isRequired
        label="Name"
        name="name"
        rules={{
          validate: validateName,
        }}
      />
      <ButtonGroup autoCollapse>
        <Button
          isDisabled={isSubmitting || isSaving}
          onPress={handleCancel}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          data-chameleon-target="Register Profile Save Button"
          isDisabled={isSubmitting || isSaving}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </ButtonGroup>
    </form>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterProfileForm;
