import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/shared/slice-modal';
import { getCustomersOrdersQueryKey } from 'hooks/customers/use-customers-orders-query';
import { BaseCustomer } from 'types/customers';

import { CustomerDetailsModalContent } from './customer-details-modal-content';
import CustomerDetailsModalHeader from './customer-details-modal-header';

import styles from './styles.module.scss';

type Props = {
  shopId: number;
  selectedCustomer: BaseCustomer;
  handleCloseCustomerDetailsModalClick: () => void;
};

const CustomerDetailsModal = ({
  shopId,
  selectedCustomer,
  handleCloseCustomerDetailsModalClick,
}: Props) => {
  const queryClient = useQueryClient();

  const handleClose = () => {
    // We want to reset back to 5 orders shown when the modal is closed and reopened
    queryClient.removeQueries({
      queryKey: getCustomersOrdersQueryKey({
        shopId,
        customerUuid: selectedCustomer.uuid,
      }),
    });
    handleCloseCustomerDetailsModalClick();
  };

  return (
    <Modal
      className={styles.customerDetailsModal}
      contentClassName={styles.customerDetailsModalContent}
      isOpen={!!selectedCustomer}
      header={<CustomerDetailsModalHeader />}
      onRequestClose={handleClose}
    >
      <CustomerDetailsModalContent
        shopId={Number(shopId)}
        selectedCustomer={selectedCustomer}
      />
    </Modal>
  );
};

export { CustomerDetailsModal };
