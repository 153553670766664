import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

import { calculateOrderSummaries } from './helpers';

const useFetchOrderMetrics = (activeDates, shopId, shopTimezone) => {
  const { authenticatedFetch } = useApi();
  const { current, previous } = activeDates;

  // Fetch order metrics for currently selected dates.
  const { data: currentOrderMetrics, isPending: isCurrentOrderMetricsLoading } =
    useQuery({
      queryKey: [
        Number(shopId),
        { start: current.startDate, end: current.endDate },
        'orderMetrics',
        shopTimezone,
      ],
      queryFn: () => {
        const queryStr = queryString.stringify({
          end: current.endDate,
          start: current.startDate,
          timezone: shopTimezone,
        });
        return authenticatedFetch
          .get(`/api/management/v1/shops/${shopId}/orders/by/day?${queryStr}`)
          .then(camelCaseKeys);
      },
      enabled: !!shopTimezone,
    });
  // Fetch order metrics for previous dates (used to calculate deltas).
  const {
    data: previousOrderMetrics,
    isPending: isPreviousOrderMetricsLoading,
  } = useQuery({
    queryKey: [
      Number(shopId),
      { start: previous.startDate, end: previous.endDate },
      'orderMetrics',
      shopTimezone,
    ],
    queryFn: () => {
      const queryStr = queryString.stringify({
        end: previous.endDate,
        start: previous.startDate,
        timezone: shopTimezone,
      });
      return authenticatedFetch
        .get(`/api/management/v1/shops/${shopId}/orders/by/day?${queryStr}`)
        .then(camelCaseKeys);
    },
    enabled: !!shopTimezone,
  });

  return {
    currentOrderSummary:
      currentOrderMetrics && calculateOrderSummaries(currentOrderMetrics),
    previousOrderSummary:
      previousOrderMetrics && calculateOrderSummaries(previousOrderMetrics),
    currentOrderMetrics: currentOrderMetrics || {},
    isLoading: isCurrentOrderMetricsLoading || isPreviousOrderMetricsLoading,
  };
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useFetchOrderMetrics;
