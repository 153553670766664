import { FormEvent } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button } from 'crust';

import Label from 'components/shared/label';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import useAnalytics from 'hooks/use-analytics';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop, ShopTraits } from 'types/shops';
import { User } from 'types/user';
import {
  SmartButtonFormValues,
  Theme,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';

import { EmbedActions } from '../embed-actions';

import ColorDropdown from './color-dropdown';
import FormatDropdown from './format-dropdown';
import ModeDropdown from './mode-dropdown';
import PositionDropdown from './position-dropdown';
import PromoDropdown from './promo-dropdown';
import ThemeDropdown from './theme-dropdown';

import styles from './styles.module.scss';

type Props = {
  control: Control<SmartButtonFormValues>;
  getValues: UseFormGetValues<SmartButtonFormValues>;
  handleFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isPromoMessageAvailable: boolean;
  isSaveDisabled: boolean;
  isSubmitting: boolean;
  setIsFormChanged: (isFormChanged: boolean) => void;
  setValue: UseFormSetValue<SmartButtonFormValues>;
  shopInfo: Shop;
  shopTraits: ShopTraits;
  isMultiLocEnabled: boolean;
  user: User;
};

const SmartButtonForm = ({
  control,
  getValues,
  handleFormSubmit,
  isPromoMessageAvailable,
  isSaveDisabled,
  setIsFormChanged,
  setValue,
  shopInfo,
  shopTraits,
  isMultiLocEnabled,
  user,
}: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const {
    mutate: createSalesforceCase,
    isPending: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();
  const page = isMultiLocEnabled
    ? WebsiteIntegrationsAnalyticsPageName.SmartButtonsMultiloc
    : WebsiteIntegrationsAnalyticsPageName.SmartButtons;
  const { shopId } = shopInfo;
  const currentTheme = useWatch({
    control,
    name: 'theme',
  });

  const handleRequestSupportClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.SmartButton,
            subject: SalesforceSubject.SmartButton,
          },
          note: SalesforceNote.SmartButtonMultilocationNote,
          shopId,
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () => {
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            );
          },
        },
      );
    }

    trackProductUpsellLinkClick({
      shopId: String(shopId),
      product: 'smart button',
      page: 'smart buttons multiloc',
      linkLabel: 'submit a request',
      type: ClickedExternalLinkType.LinkedText,
      outcome: 'support',
    });
  };

  return (
    <>
      {shopTraits.isSmartButtonActive && (
        <div className={styles.header}>
          Customize Button
          <div className={styles.actionsContainer}>
            <EmbedActions
              isShareCodeDisplayed={false}
              isTertiary
              shopInfo={shopInfo}
              shopTraits={shopTraits}
              shouldCreateCase={false}
            />
          </div>
        </div>
      )}
      <form
        onSubmit={handleFormSubmit}
        className={styles.form}
        name="Smart Buttons Config Form"
      >
        <div className={styles.fields}>
          {currentTheme !== Theme.Slice && (
            <div>
              <Label className={styles.fieldLabel} htmlFor="color-dropdown">
                Color
              </Label>
              <ColorDropdown
                buttonClassName={styles.menuButton}
                control={control}
                setIsFormChanged={setIsFormChanged}
                shopId={shopInfo.shopId}
                shopTraits={shopTraits}
                page={page}
              />
            </div>
          )}
          <div>
            <Label className={styles.fieldLabel} htmlFor="theme-dropdown">
              Theme
            </Label>
            <ThemeDropdown
              buttonClassName={styles.menuButton}
              control={control}
              setIsFormChanged={setIsFormChanged}
              shopId={shopInfo.shopId}
              shopTraits={shopTraits}
              page={page}
            />
          </div>
          {!isMultiLocEnabled && currentTheme !== Theme.Slice && (
            <>
              <div data-chameleon-target="Smart Buttons Format Dropdown">
                <Label className={styles.fieldLabel} htmlFor="format-dropdown">
                  Format
                </Label>
                <FormatDropdown
                  buttonClassName={styles.menuButton}
                  control={control}
                  getValues={getValues}
                  setIsFormChanged={setIsFormChanged}
                  setValue={setValue}
                  shopId={shopInfo.shopId}
                  shopTraits={shopTraits}
                />
              </div>
              <div>
                <Label
                  className={styles.fieldLabel}
                  htmlFor="position-dropdown"
                >
                  Position
                </Label>
                <PositionDropdown
                  buttonClassName={styles.menuButton}
                  control={control}
                  setIsFormChanged={setIsFormChanged}
                  shopId={shopInfo.shopId}
                  shopTraits={shopTraits}
                />
              </div>
              <div>
                <Label className={styles.fieldLabel} htmlFor="mode-dropdown">
                  Mode
                </Label>
                <ModeDropdown
                  buttonClassName={styles.menuButton}
                  control={control}
                  setIsFormChanged={setIsFormChanged}
                  shopId={shopInfo.shopId}
                  shopTraits={shopTraits}
                />
              </div>
            </>
          )}
          {isPromoMessageAvailable &&
            !isMultiLocEnabled &&
            currentTheme !== Theme.Slice && (
              <div>
                <Label className={styles.fieldLabel} htmlFor="promo-dropdown">
                  Promo Message
                </Label>
                <PromoDropdown
                  buttonClassName={styles.menuButton}
                  control={control}
                  setIsFormChanged={setIsFormChanged}
                  shopId={shopInfo.shopId}
                  shopTraits={shopTraits}
                  page={page}
                />
              </div>
            )}
          {isMultiLocEnabled && (
            <div>
              <p className={styles.fieldLabel}>Information</p>
              <p className={styles.informationText}>
                <Button
                  appearance="link"
                  onPress={() => handleRequestSupportClick()}
                >
                  Submit a request
                </Button>{' '}
                to update your multiple location Smart Button settings.
              </p>
            </div>
          )}
        </div>
        {!isMultiLocEnabled && currentTheme !== Theme.Slice && (
          <Button
            className={styles.saveButton}
            data-chameleon-target="Smart Buttons Save Changes Button"
            isDisabled={isSaveDisabled}
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        )}
      </form>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartButtonForm;
