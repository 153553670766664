import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ContentTile from 'components/shared/content-tile';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import { useSaveSmartPopUpsSettingsMutation } from 'hooks/website-tools/use-save-smart-popups-settings';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import {
  SmartButtonStorageKey,
  SmartPopupFormValues,
  WebsiteIntegrationColor,
  WebsiteIntegrationsAnalyticsPageName,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import { showInvalidSubmitToast } from 'utilities/forms';
import {
  getIsPromoMessageAvailableForShop,
  getSmartPopupsAnalyticsValuesForSaveRequest,
  getSmartPopupsDefaultFormValuesFromSettings,
  isSmartPopupsFirstTimeSetup,
} from 'utilities/website-tools';

import SmartPopupForm from '../form';
import SmartPopupPreview from '../smart-popup-preview';

import styles from './styles.module.scss';

type Props = {
  onSave: () => void;
  shop: Shop;
  smartPopupSettings: WebsiteIntegrationsSettings;
  page: WebsiteIntegrationsAnalyticsPageName;
  isMultiLocEnabled: boolean;
  user: User;
};

const SmartPopupConfiguration = ({
  onSave,
  shop,
  smartPopupSettings,
  page,
  isMultiLocEnabled,
  user,
}: Props) => {
  const { trackSavedSmartPopupsSettings } = useAnalytics();
  const [shopUrl, setShopUrl] = useStoredState<string>(
    SmartButtonStorageKey.siteUrl,
    '',
  );
  const [isFormChanged, setIsFormChanged] = useState(false);

  const isPromoMessageAvailable = getIsPromoMessageAvailableForShop(shop);

  const isFirstTimeSetup = isSmartPopupsFirstTimeSetup(smartPopupSettings);

  const defaultFormValues = getSmartPopupsDefaultFormValuesFromSettings(
    smartPopupSettings,
    isPromoMessageAvailable,
  );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset: resetForm,
  } = useForm<SmartPopupFormValues>({
    defaultValues: defaultFormValues,
  });

  const {
    data: updatedSettings,
    mutate: saveSettings,
    isError: isSmartPopupRejected,
    isPending: isSmartPopupSaving,
    isSuccess: isSmartPopupSaved,
    reset: resetMutation,
  } = useSaveSmartPopUpsSettingsMutation(shop.shopId);

  useEffect(() => {
    if (isSmartPopupSaved && updatedSettings) {
      toast.success(
        "Settings saved. Be sure you've added the one-time code to your site.",
      );

      // Reset the default values of the form to the newly saved values so that
      // the form is no longer dirty and the form state is reset. Since the form
      // remains visible, we want the submit button to be disabled to help convey
      // that the settings have been saved and to prevent multiple submissions on the same values.
      // We check to see what the smart popup was saved as in order to reset the form defaults appropriately

      resetForm({
        color:
          (updatedSettings.modalThemeColor as WebsiteIntegrationColor) ??
          defaultFormValues.color,
        promoMessage:
          updatedSettings.modalValueMessage ?? defaultFormValues.promoMessage,
        theme: updatedSettings.modalTheme ?? defaultFormValues.theme,
      });

      setIsFormChanged(false);
      resetMutation();
    }
  }, [
    defaultFormValues,
    isSmartPopupSaved,
    resetForm,
    resetMutation,
    updatedSettings,
  ]);

  useEffect(() => {
    if (isSmartPopupRejected) {
      toast.error('Settings not saved. Please try again.');
    }
  }, [isSmartPopupRejected]);

  const handleValidSubmit = (values: SmartPopupFormValues) =>
    saveSettings(values, {
      // We include this onSuccess to show the first time setup as this will run before the
      // cache update that would make the form think we are no longer in a first time setup
      // state, due to the cached value having valid values.
      onSettled: (_, error, variables) => {
        const isSuccess = error == null;

        if (isSuccess && isFirstTimeSetup) {
          onSave();
        }

        trackSavedSmartPopupsSettings(
          getSmartPopupsAnalyticsValuesForSaveRequest({
            shopId: shop.shopId,
            formValues: variables,
            url: shopUrl ?? '',
            success: isSuccess,
            isFirstTimeSetup,
            page,
          }),
        );
      },
    });

  const isSaveDisabled =
    !isFirstTimeSetup && (isSubmitting || isSmartPopupSaving || !isFormChanged);

  return (
    <ContentTile className={styles.contentTile}>
      <SmartPopupForm
        control={control}
        handleFormSubmit={handleSubmit(
          handleValidSubmit,
          showInvalidSubmitToast,
        )}
        isPromoMessageAvailable={isPromoMessageAvailable}
        isSaveDisabled={isSaveDisabled}
        isSubmitting={isSubmitting}
        setIsFormChanged={setIsFormChanged}
        shopId={shop.shopId}
        isMultiLocEnabled={isMultiLocEnabled}
        user={user}
      />
      <div className={styles.previewContainer}>
        <SmartPopupPreview
          control={control}
          shopId={shop.shopId}
          shopUuid={shop.uuid}
          shopUrl={shopUrl}
          setShopUrl={setShopUrl}
          page={page}
        />
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartPopupConfiguration;
