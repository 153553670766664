import { useCallback, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import NumberInput from 'components/shared/number-input';
import { toDollarString, toPennyString } from 'utilities/currency';
import { AnalyticsField, getOfferFieldName, OfferField } from 'utilities/menu';

const SelectionOffer = ({
  activeOfferIndex,
  className,
  control,
  customizationIndex,
  isByType,
  isHalf,
  offerIndex,
  register,
  selectionIndex,
  setValue,
}) => {
  const getFieldName = useCallback(
    (field) =>
      getOfferFieldName(
        field,
        customizationIndex,
        selectionIndex,
        offerIndex,
        isHalf,
      ),
    [customizationIndex, isHalf, offerIndex, selectionIndex],
  );

  const isDisabled = useWatch({
    control,
    name: getFieldName(OfferField.IsDisabled),
  });

  const isActive = activeOfferIndex === offerIndex;
  const isWatchingActivePrice = !isActive && !isByType && !isDisabled;

  const activePrice = useWatch({
    control,
    name: getOfferFieldName(
      OfferField.Price,
      customizationIndex,
      selectionIndex,
      activeOfferIndex,
      isHalf,
    ),
    disabled: !isWatchingActivePrice,
  });

  useEffect(() => {
    if (isWatchingActivePrice) {
      setValue(getFieldName(OfferField.Price), activePrice);
    }
  }, [activePrice, getFieldName, isWatchingActivePrice, setValue]);

  return (
    <>
      <Controller
        control={control}
        name={getFieldName(OfferField.Price)}
        rules={{
          onChange: () => setValue(AnalyticsField.SelectionPriceChanged, true),
          required: 'Please enter a price.',
          min: { value: 0, message: 'Must be at least $0.00.' },
          max: { value: 99999, message: 'Must be less than $1000.' },
          disabled: isDisabled,
        }}
        render={({ field, fieldState }) =>
          isActive ? (
            <NumberInput
              aria-label="Selection price"
              aria-required="true"
              className={className}
              disabled={isDisabled}
              id={`offer-${customizationIndex}-${selectionIndex}-${offerIndex}-${
                isHalf ? 'half' : 'whole'
              }-price-input`}
              isInvalid={fieldState.error != null}
              name={field.name}
              onBlur={field.onBlur}
              onValueChange={(values) =>
                field.onChange(toPennyString(values.value))
              }
              prefix="$"
              ref={field.ref}
              // Disabled fields have undefined values.
              value={toDollarString(field.value)}
              valueIsNumericString
            />
          ) : null
        }
      />
      <input
        type="hidden"
        {...register(getFieldName(OfferField.ProductTypeId))}
      />
      <input type="hidden" {...register(getFieldName(OfferField.IsDisabled))} />
      {isHalf ? (
        <>
          <input type="hidden" {...register(getFieldName(OfferField.LeftId))} />
          <input
            type="hidden"
            {...register(getFieldName(OfferField.RightId))}
          />
        </>
      ) : (
        <input type="hidden" {...register(getFieldName(OfferField.Id))} />
      )}
    </>
  );
};

SelectionOffer.propTypes = {
  activeOfferIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  isByType: PropTypes.bool.isRequired,
  isHalf: PropTypes.bool.isRequired,
  offerIndex: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
  selectionIndex: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SelectionOffer;
