import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ClosingForm from 'components/hours/closings/closing-form';
import { useUpdateClosingsMutation } from 'hooks/hours/use-update-closings-mutation';
import useAnalytics from 'hooks/use-analytics';
import { ApiRequestError } from 'providers/api/helpers';
import { Shop } from 'types/shops';
import { formatClosingForApi } from 'utilities/closings';
import { showInvalidSubmitToast } from 'utilities/forms';

type EditClosingTileProps = {
  activeClosingId: number;
  defaultValues: {
    closingComment: string;
    closingReason: { id: number; label: string; value: number };
    dates: { start: string; end: string };
    shippingType: string;
  };
  onClose: () => void;
  shopId: Shop['shopId'];
  shopTimezone: string;
};

export const EditClosingTile = ({
  activeClosingId,
  defaultValues,
  onClose,
  shopId,
  shopTimezone,
}: EditClosingTileProps) => {
  const { trackUpdateClosing } = useAnalytics();

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
  } = useForm({ defaultValues, mode: 'onBlur' });

  const { mutate: updateClosing } = useUpdateClosingsMutation(shopId);

  const handleSubmitUpdateClosing = (values: {
    closingComment: string;
    closingReason: {
      id: number;
      label: string;
      value: number;
    };
    dates: { start: string; end: string };
    shippingType: string;
  }) =>
    updateClosing(
      {
        closingsParams: formatClosingForApi(values),
        closingId: activeClosingId,
      },
      {
        onSuccess: (response) => {
          trackUpdateClosing(shopId, response, true);
          onClose();
        },
        onError: (error: Error) => {
          handleRequestError((error as ApiRequestError).responseBody);
        },
      },
    );

  const handleRequestError = (responseBody: {
    status: number;
    messages: string[];
  }) => {
    if (
      responseBody?.status === 422 &&
      responseBody?.messages.some((it) =>
        it.includes('lie within another Closing'),
      )
    ) {
      setError('dates', {
        type: 'manual',
        message: 'The dates cannot overlap with an existing closing.',
      });
      showInvalidSubmitToast();
    } else {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <ClosingForm
      action="edit"
      control={control}
      errors={errors}
      isSubmitting={isSubmitting}
      onClickCancel={onClose}
      onSubmit={handleSubmit(handleSubmitUpdateClosing, showInvalidSubmitToast)}
      shopTimezone={shopTimezone}
    />
  );
};
