import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { Icon } from 'crust';
import { IconName } from 'crust/components/icon/icon';

import CaretIcon from 'images/caret.svg?react';
import ExternalIcon from 'images/nav/external.svg?react';

import { SubItem } from '../../nav-items';

import styles from './styles.module.scss';

const isItemActive = (
  activeWith: RegExp[] | string[] = [],
  pathname: string,
  url: string,
) =>
  [...activeWith, url].some((item) =>
    item instanceof RegExp ? item.test(pathname) : item === pathname,
  );

type NavigationItemProps = {
  activeWith: RegExp[] | string[] | undefined;
  dataTestId: string | undefined;
  icon: IconName;
  items: SubItem[] | undefined;
  label: string;
  trackNavigationItemClick: (label: string[], isExternalLink: boolean) => void;
  url: string;
};

export const NavigationItem = ({
  activeWith,
  dataTestId,
  icon,
  items,
  label,
  trackNavigationItemClick,
  url,
}: NavigationItemProps) => {
  const { pathname } = useLocation();
  const isExternalLink = url.startsWith('http');
  const isActive = items
    ? items.some((item) => isItemActive(item.activeWith, pathname, item.url))
    : isItemActive(activeWith, pathname, url);
  const iconStyle = cx(styles.icon, isActive && styles.activeIcon);
  const externalStyle = cx(styles.external, isActive && styles.activeIcon);
  const className = cx(styles.item, isActive && styles.activeItem);
  const canShowSubNavItems = items && isActive;

  return (
    <>
      <Link
        className={className}
        data-chameleon-target={`Nav. Item ${label}`}
        data-testid={dataTestId}
        onClick={() => trackNavigationItemClick([label], isExternalLink)}
        to={url}
      >
        <Icon className={iconStyle} icon={icon} size="large" />
        <div className={styles.label}>
          {label}
          <div className={styles.wrapper}>
            {canShowSubNavItems && <CaretIcon className={styles.caret} />}
          </div>
        </div>
        {isExternalLink && <ExternalIcon className={externalStyle} />}
      </Link>
      {canShowSubNavItems && (
        <ul className={styles.subNavItems}>
          {items.map((item) => {
            const isSubActive = isItemActive(
              item.activeWith,
              pathname,
              item.url,
            );
            const subClassName = cx(
              styles.subNavItem,
              isSubActive && styles.activeSubItem,
            );
            const isSubItemExternalLink = item.url.startsWith('http');

            return (
              <li className={styles.listItem} key={item.url}>
                {!isSubItemExternalLink ? (
                  <Link
                    className={subClassName}
                    data-chameleon-target={`Nav. Item ${item.label}`}
                    onClick={() =>
                      trackNavigationItemClick(
                        [label, item.label],
                        isSubItemExternalLink,
                      )
                    }
                    to={item.url}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <a
                    className={subClassName}
                    href={item.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {item.label}
                    {isSubItemExternalLink && (
                      <ExternalIcon className={externalStyle} />
                    )}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
