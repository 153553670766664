import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  NetworkRegisterPermissionGroup,
  networkToDomainRegisterPermissionGroup,
  RegisterPermissionGroup,
} from 'types/register-users';

export const getRegisterPermissionGroupsQueryKey = (
  shopId: string,
): QueryKey => [shopId, 'permissionGroups'];

type UseRegisterPermissionGroupsQueryOptions = Omit<
  UseQueryOptions<RegisterPermissionGroup[]>,
  'queryKey' | 'queryFn'
>;

export const useRegisterPermissionGroupsQuery = (
  shopId: string,
  options?: UseRegisterPermissionGroupsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterPermissionGroupsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/permission_groups`,
      );

      return response.map(
        (networkRegisterPermissionGroup: NetworkRegisterPermissionGroup) =>
          networkToDomainRegisterPermissionGroup(
            networkRegisterPermissionGroup,
          ),
      ) as RegisterPermissionGroup[];
    },
    ...options,
  });
};
