import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Icon, Link } from 'crust';

import CollapsibleTile from 'components/shared/collapsible-tile';
import ContentTile from 'components/shared/content-tile';
import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import { RHFCheckbox, RHFCheckboxGroup } from 'components/shared/rhf-checkbox';
import { useSavePermisionsGroupMutation } from 'hooks/register-users/use-save-permisions-group-mutation';
import { ApiRequestError } from 'providers/api/helpers';
import * as paths from 'routes/paths';
import {
  NetworkCreateEditRegisterPermissionGroupResponse,
  PermissionsFormValues,
  PermissionsInfo,
  RegisterPermission,
  RegisterPermissionGroup,
} from 'types/register-users';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  permissionGroupId: string;
  permissionGroups: RegisterPermissionGroup[] | undefined;
  recommendedPermissionGroups: PermissionsInfo | undefined;
  shopId: number;
};

const RegisterPermissionGroupForm = ({
  permissionGroupId,
  permissionGroups,
  recommendedPermissionGroups,
  shopId,
}: Props) => {
  const navigate = useNavigate();

  const isNew = permissionGroupId === 'new';

  const [defaultValues] = useState(() => {
    const group = permissionGroups?.find((it) => it.id === permissionGroupId);
    return {
      name: group?.name ?? '',
      permissionIds: group?.permissions.map((it) => it.id) ?? [],
    };
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm<PermissionsFormValues>({
    defaultValues,
    mode: 'onTouched',
  });

  const {
    permissionIdsByCategoryName,
    categoryNamesByPermissionId,
    permissionsById,
  } = useMemo(() => {
    const permissions = recommendedPermissionGroups?.permissions ?? [];
    const permissionIdsByCategoryName = new Map<string, string[]>();
    const categoryNamesByPermissionId = new Map<string, string>();
    const permissionsById = new Map<string, RegisterPermission>();

    for (const permission of permissions) {
      const categoryName = permission.category
        .replaceAll('_', ' ')
        .toLowerCase();

      permissionsById.set(permission.id, permission);
      categoryNamesByPermissionId.set(permission.id, categoryName);

      if (!permissionIdsByCategoryName.has(categoryName)) {
        permissionIdsByCategoryName.set(categoryName, []);
      }

      permissionIdsByCategoryName.get(categoryName)?.push(permission.id);
    }

    return {
      categoryNamesByPermissionId,
      permissionIdsByCategoryName,
      permissionsById,
    };
  }, [recommendedPermissionGroups?.permissions]);

  const selectedPermissionIds = watch('permissionIds');
  const [indeterminateIds, setIndeterminateIds] = useState<Set<string>>(
    () => new Set(),
  );
  const previousPermissionIds = useRef<Set<string> | null>(null);

  useEffect(() => {
    // Avoid constructing a new Set on each render.
    if (previousPermissionIds.current == null) {
      previousPermissionIds.current = new Set();
    }

    const categories = permissionIdsByCategoryName.keys();
    const currentPermissionIds = new Set(selectedPermissionIds);
    const nextPermissionIds = new Set(selectedPermissionIds);
    const nextIndeterminateIds = new Set<string>();

    for (const category of categories) {
      const permissions = permissionIdsByCategoryName.get(category);

      if (permissions == null) {
        // Shouldn't happen, but just keep moving along.
        continue;
      }

      const isCurrentlyChecked = nextPermissionIds.has(category);
      const isPreviouslyChecked = previousPermissionIds.current.has(category);

      if (!isPreviouslyChecked && isCurrentlyChecked) {
        // When a category is checked, check all of its children.
        permissions.forEach((it) => nextPermissionIds.add(it));
        continue;
      }

      if (isPreviouslyChecked && !isCurrentlyChecked) {
        // When a category is unchecked, uncheck all of its children.
        permissions.forEach((it) => nextPermissionIds.delete(it));
        continue;
      }

      const total = permissions.length;
      const checked = permissions
        .map((it) => (currentPermissionIds.has(it) ? 1 : 0))
        .reduce((previous: number, current) => previous + current, 0);

      if (checked === total) {
        // When all of the permissions in a category are checked, make sure
        // the checkbox for the category is also checked.
        nextPermissionIds.add(category);
        continue;
      }

      // When only some of the permissions in category are checked, make sure
      // the category is also unchecked because it's now incomplete.
      nextPermissionIds.delete(category);

      if (checked > 0) {
        // A category checkbox is indeterminate if at least one, but not all of
        // the permissions are checked.
        nextIndeterminateIds.add(category);
      }
    }

    // Since only one category can change for each update, it suffices to
    // compare sizes. Otherwise, we'd have to compute the symmetric differences.
    if (
      nextPermissionIds.size !== currentPermissionIds.size ||
      nextIndeterminateIds.size !== indeterminateIds.size
    ) {
      // Avoid React 18 state batching via setTimeout.
      setTimeout(() => {
        previousPermissionIds.current = nextPermissionIds;
        setValue('permissionIds', Array.from(nextPermissionIds), {
          shouldValidate: true,
        });
        setIndeterminateIds(nextIndeterminateIds);
      }, 0);
    }
  }, [
    categoryNamesByPermissionId,
    defaultValues.permissionIds,
    indeterminateIds.size,
    permissionIdsByCategoryName,
    selectedPermissionIds,
    setValue,
  ]);

  const { isPending: isSaving, mutate: savePermissionGroup } =
    useSavePermisionsGroupMutation(
      isNew,
      shopId,
      permissionIdsByCategoryName,
      permissionGroupId,
      recommendedPermissionGroups,
    );

  const handleValidSubmit = (values: PermissionsFormValues) => {
    savePermissionGroup(values, {
      onError: (error) => {
        if (error instanceof ApiRequestError && error.status === 409) {
          setError('name', {
            type: 'manual',
            message:
              'There is already a permission group with the same name. Please enter a different name.',
          });
          showInvalidSubmitToast();
        } else {
          showUnexpectedErrorToast();
        }
      },
      onSuccess(response: NetworkCreateEditRegisterPermissionGroupResponse) {
        toast.success(`${response.name} ${isNew ? 'Saved' : 'Updated'}!`);

        navigate(paths.registerPermissions(shopId));
      },
    });
  };

  const [isOpen, setIsOpen] = useState(true);

  const handleClickGoBack = () => {
    navigate(paths.registerPermissions(shopId));
  };

  return (
    <>
      <div className={styles.permissionsManagementHeader}>
        <button className={styles.headerButton} onClick={handleClickGoBack}>
          <Icon icon="chevronLeft" size="large" />
          Permissions Management
        </button>
      </div>
      <ContentTile
        className={styles.permissionGroupTile}
        title={`${isNew ? 'Create' : 'Edit'} Permission Group`}
      >
        <form
          className={styles.permissionGroupForm}
          onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
        >
          <div className={styles.description}>
            Build permission groups to restrict access to actions for users.
          </div>
          <div className={styles.nameField}>
            <Label htmlFor="register-group-name">Permission Group Name</Label>
            <Input
              aria-required="true"
              id="register-group-name"
              isInvalid={errors.name != null}
              placeholder=""
              {...register('name', {
                required: 'Please enter a name for this permission group.',
              })}
              data-chameleon-target="Rename Permission Group"
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </div>
          <div>
            <CollapsibleTile
              className={styles.permissionsTile}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title="Permissions Options"
              bodyChameleonTarget="Permissions Options"
            >
              <RHFCheckboxGroup
                aria-label="available permission groups"
                control={control}
                isRequired
                orientation="vertical"
                name="permissionIds"
                rules={{
                  required: 'Please select at least one permission option.',
                }}
              >
                {Array.from(permissionIdsByCategoryName.entries()).map(
                  ([category, permissions]) => (
                    <Fragment key={category}>
                      <RHFCheckbox
                        isIndeterminate={indeterminateIds.has(category)}
                        label={capitalize(category)}
                        value={category}
                      />
                      {permissions.map((permission) => (
                        <RHFCheckbox
                          key={permission}
                          className={styles.indented}
                          label={capitalize(
                            permissionsById.get(permission)?.name ?? '',
                          )}
                          value={permission}
                        />
                      ))}
                    </Fragment>
                  ),
                )}
              </RHFCheckboxGroup>
            </CollapsibleTile>
          </div>
          <hr className={styles.divider} />
          <div className={styles.buttonsArea}>
            <Link
              appearance="button"
              className={styles.button}
              href={paths.registerPermissions(shopId)}
              variant="secondary"
            >
              Cancel
            </Link>
            <Button
              className={styles.button}
              data-chameleon-target="Save Permission Group"
              isDisabled={isSubmitting || isSaving}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </div>
        </form>
      </ContentTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPermissionGroupForm;
