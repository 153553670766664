import PropTypes from 'prop-types';

import LeastSellingProducts from './products-by-sales/least-selling';
import TopSellingProducts from './products-by-sales/top-selling';
import NetSalesByShippingType from './net-sales-by-shipping-type';
import SalesByDay from './sales-by-day';

const OrderAnalytics = ({
  shopId = '',
  startDate,
  endDate,
  orderType = 'online',
}) => {
  return (
    <>
      <NetSalesByShippingType
        shopId={shopId}
        startDate={startDate}
        endDate={endDate}
        orderType={orderType}
      />
      <SalesByDay
        shopId={shopId}
        startDate={startDate}
        endDate={endDate}
        orderType={orderType}
      />
      <TopSellingProducts
        shopId={shopId}
        startDate={startDate}
        endDate={endDate}
        orderType={orderType}
      />
      <LeastSellingProducts
        shopId={shopId}
        startDate={startDate}
        endDate={endDate}
        orderType={orderType}
      />
    </>
  );
};

OrderAnalytics.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OrderAnalytics;
