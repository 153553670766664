import { Navigate, useParams } from 'react-router-dom';

import { UnexpectedError } from 'components/shared/error';
import { Suspended } from 'components/shared/suspended';
import { useShopQuery } from 'hooks/shops';

import * as paths from '../../routes/paths';

// This page is used to redirect the user after activating the Sqaure
// integration. Square doesn't know the numeric shop ID, just the UUID.
// See POS integration here:
// https://github.com/slicelife/pos-integration/blob/c84cb0c68cc67d2e4988672ebf56a998e8ff29ae/app/views/auth/square.py#L111-L113
// https://github.com/slicelife/pos-integration/blob/c84cb0c68cc67d2e4988672ebf56a998e8ff29ae/app/views/auth/square.py#L160-L161
const ShopUuidRedirect = () => {
  const { shopId: shopUuid } = useParams();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopUuid ?? '', { enabled: !!shopUuid });

  const shopId = shop?.shopId;

  if (isShopLoading) {
    return <Suspended isLoading variant="viewPort" />;
  } else if (isShopError || !shopId) {
    return <UnexpectedError />;
  } else {
    return <Navigate to={paths.shopIntegrations(shopId)} replace />;
  }
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopUuidRedirect;
