import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';

import { getOpenForTodayQueryKey } from './use-open-for-today';
import { getPausingsQueryKey } from './use-pausings';

export const useDeletePauseMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (pausingId: string) => {
      const response = await authenticatedFetch.del(
        `api/management/v1/shops/${shopId}/pausings/${pausingId}`,
      );

      return response;
    },
    onSuccess: () => {
      queryClient.setQueryData(getPausingsQueryKey(shopId), []);
      queryClient.invalidateQueries({
        queryKey: getOpenForTodayQueryKey(shopId),
      });
    },
  });
};
