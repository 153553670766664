import * as React from "react";
import { forwardRef } from "react";
const SvgMegaphone = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "m8.719 14.033-.07.997.07-.997Zm-6.28 4.65.948.317.002-.006-.95-.31Zm1.53-4.695.952.31a1 1 0 0 0-.57-1.235l-.381.925ZM8 7l.032-1H8v1Zm5.874 7.832.264-.964-.264.964Zm-.004-8.652.299.955-.3-.954Zm-.87.096v8.46h2v-8.46h-2Zm1.137 7.592c-2.37-.65-4.4-.766-5.347-.833l-.14 1.995c.948.068 2.799.175 4.96.767l.527-1.93Zm-7.313.5L5.28 19l1.898.632L8.72 15l-1.898-.633ZM5.28 19H3.387v2H5.28v-2Zm-1.89-.006 1.532-4.696-1.902-.62-1.531 4.696 1.901.62ZM5.97 8 8.001 8l-.002-2-2.031.001.001 2ZM7.999 7l-.03 1h.014a5.393 5.393 0 0 0 .14.002 21.775 21.775 0 0 0 1.8-.073c1.148-.095 2.694-.309 4.245-.794l-.598-1.909c-1.363.427-2.751.622-3.81.71a19.779 19.779 0 0 1-1.619.066 7.496 7.496 0 0 1-.107-.001h-.003L8 7Zm-3.648 6.063c-1.782-.734-2.19-2.903-1.127-4.273L1.643 7.565C-.142 9.867.459 13.623 3.589 14.912l.762-1.85ZM3.387 19l-1.897-.633A2 2 0 0 0 3.387 21v-2Zm1.892 0v2a2 2 0 0 0 1.898-1.368L5.279 19Zm3.51-5.965a1.933 1.933 0 0 0-1.966 1.332L8.721 15c0-.001-.002.006-.014.015a.097.097 0 0 1-.058.016l.14-1.996ZM5.968 6.001c-.676 0-1.465.037-2.214.236-.76.202-1.538.588-2.111 1.328l1.58 1.225c.226-.29.563-.492 1.045-.62.493-.131 1.073-.168 1.701-.169l-.001-2ZM13 14.735a.9.9 0 0 1 1.136-.867l-.528 1.929A1.1 1.1 0 0 0 15 14.735h-2Zm2-8.459a1.1 1.1 0 0 0-1.43-1.05l.598 1.909A.9.9 0 0 1 13 6.276h2Z" }), /* @__PURE__ */ React.createElement("mask", { id: "a", width: 6, height: 15, x: 17, y: 3, maskUnits: "userSpaceOnUse", style: {
  maskType: "alpha"
} }, /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M17 3h6v14.973h-6V3Z" })), /* @__PURE__ */ React.createElement("g", { stroke: "currentColor", strokeWidth: 2, mask: "url(#a)" }, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 11, r: 6 }), /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 11, r: 2 })));
const ForwardRef = forwardRef(SvgMegaphone);
export default ForwardRef;
