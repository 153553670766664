import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuResponseBody } from 'types/menu/api';
import { UseQueryParameters } from 'types/queries';
import { Shop } from 'types/shops';
import { sortRelationships } from 'utilities/menu';

type MenuQueryParameters = {
  shopId: Shop['shopId'] | string;
  channel?: 'online' | 'offline';
};

type MenuQueryOptions = { isEnabled?: boolean };

export const getMenuQueryKey = ({ shopId, channel }: MenuQueryParameters) => {
  const key = [Number(shopId), 'menu'];
  if (channel) {
    key.push(channel);
  }
  return key;
};

export const useMenuQuery = ({
  parameters,
  options,
}: UseQueryParameters<MenuQueryParameters, MenuQueryOptions>) => {
  const { authenticatedFetch } = useApi();

  // This endpoint doesn't actually provide the full menu as it does not include
  // product addons (customizations). Restaurant API passes the request through
  // to Menu Service /management-api/v2/menus/{shopId} with the include depth hard-coded to `product-types`.
  return useQuery({
    queryKey: getMenuQueryKey(parameters),
    queryFn: async (): Promise<MenuResponseBody> => {
      const param = parameters.channel ? `?channel=${parameters.channel}` : '';
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${parameters.shopId}/menus${param}`,
      );
      return sortRelationships(response);
    },
    staleTime: 60000,
    enabled: options?.isEnabled,
  });
};
