import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterUser } from 'types/register-users';
import { networkGetRegisterUsersResponseToRegisterUsers } from 'utilities/register-users';

export const getRegisterUsersQueryKey = (shopId: string): QueryKey => [
  shopId,
  'registerUsers',
];

type UseRegisterUsersQueryOptions = Omit<
  UseQueryOptions<RegisterUser[]>,
  'queryKey' | 'queryFn'
>;

export const useRegisterUsersQuery = (
  shopId: string,
  options?: UseRegisterUsersQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterUsersQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/register_users`,
      );

      return networkGetRegisterUsersResponseToRegisterUsers(response);
    },
    ...options,
  });
};
