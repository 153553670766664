import { useEffect } from 'react';

import Link from 'components/shared/link';
import Modal from 'components/shared/slice-modal';
import { Suspended } from 'components/shared/suspended';
import { useShopQuery, useShopScoreQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import ClockSolidIcon from 'images/clock-solid.svg?react';
import * as paths from 'routes/paths';
import { Shop, ShopScoreTraits } from 'types/shops';
import {
  getLowerDeliveryFeeEmailInfo,
  shopHasLowDeliveryFee,
} from 'utilities/campaign-manager';

import ListItem from './list-item';

import styles from './styles.module.scss';

type Props = {
  handleCloseModal: () => void;
  shopId: Shop['shopId'];
};

export const OptimizeModal = ({ handleCloseModal, shopId }: Props) => {
  const {
    trackClickedEmailLink,
    trackInternalFeatureLinkClick,
    trackOpenedOptimizeMyCampaignModal,
  } = useAnalytics();
  const onlineDiscountPath = paths.automaticDiscounts(shopId);
  const menuPath = paths.getMenuItemsPath(shopId);
  const recipient = 'partnersuccess@slicelife.com';
  const { body, subject } = getLowerDeliveryFeeEmailInfo(shopId);

  const { data: shop, isPending: isShopLoading } = useShopQuery(shopId);
  const { data: shopScoreData, isPending: isShopScoreLoading } =
    useShopScoreQuery(shopId);
  const hasDiscount = (shop?.discountPercent ?? 0) > 0;
  const hasDeliveryZone = (shop?.deliveryZones?.length ?? 0) > 0;
  const hasLowDeliveryFee = shopHasLowDeliveryFee(shop?.deliveryZones ?? []);

  // See https://alation.prod.slicelife.com/article/33/.
  // "if ops_score_10_plus_photos is true then score =2"
  const has10PlusMenuPhotos =
    shopScoreData?.traitsWithValues?.[ShopScoreTraits.OpsScore10PlusPhotos] ===
    2;

  // The tracking event is wrapped in a useEffect to prevent it from firing
  // before the shop and shopScoreData are loaded.
  useEffect(() => {
    if (shop && shopScoreData) {
      trackOpenedOptimizeMyCampaignModal({
        deliveryFeeChecked: hasLowDeliveryFee,
        discountsChecked: hasDiscount,
        menuPhotosChecked: has10PlusMenuPhotos,
        shopId: String(shopId),
      });
    }
  }, [
    has10PlusMenuPhotos,
    hasDiscount,
    hasLowDeliveryFee,
    shop,
    shopId,
    shopScoreData,
    trackOpenedOptimizeMyCampaignModal,
  ]);

  const handleClickInternalFeatureLink = (copy: string, toPage: string) => {
    trackInternalFeatureLinkClick({
      copy,
      fromPage: 'campaign manager ams',
      shopId: String(shopId),
      toPage,
    });
  };

  const handleClickedEmailLink = () => {
    trackClickedEmailLink({
      destination: recipient,
      page: 'campaign manager ams',
      psmName: '',
      shopId: String(shopId),
      subject: 'lower delivery fee',
    });
  };

  return (
    <Modal
      className={styles.modal}
      contentClassName={styles.content}
      fadeClassName={styles.fade}
      header={<div className={styles.header}>Optimize My Campaign</div>}
      isOpen
      onRequestClose={() => handleCloseModal()}
      role="dialog"
    >
      <Suspended
        className={styles.suspended}
        isLoading={isShopLoading || isShopScoreLoading}
      >
        <ul className={styles.list}>
          <ListItem
            isActive={hasDiscount}
            subtitle="Discounts tend to perform 20% better"
            title={
              <Link
                onClick={() =>
                  handleClickInternalFeatureLink('add a discount', 'discounts')
                }
                to={onlineDiscountPath}
              >
                Add a Discount
              </Link>
            }
          />
          {hasDeliveryZone && (
            <ListItem
              isActive={hasLowDeliveryFee}
              subtitle="Delivery fees less than $4 perform better"
              title={
                <Link
                  onClick={handleClickedEmailLink}
                  to={`mailto:${recipient}?subject=${subject}&body=${body}`}
                >
                  Lower Delivery Fee
                </Link>
              }
            />
          )}
          <ListItem
            isActive={has10PlusMenuPhotos}
            subtitle="Menus with 10+ photos perform better"
            title={
              <Link
                onClick={() =>
                  handleClickInternalFeatureLink('add menu photos', 'items')
                }
                to={menuPath}
              >
                Add Menu Photos
              </Link>
            }
          />
        </ul>
      </Suspended>
      <div className={styles.footer}>
        <ClockSolidIcon className={styles.footerIcon} />
        <div className={styles.footerText}>
          Optimizations can take up to 24hrs to update
        </div>
      </div>
    </Modal>
  );
};
