import PropTypes from 'prop-types';

import { Suspended } from 'components/shared/suspended';
import { TooltipIconButton } from 'components/shared/tooltips';
import { useShopScoreQuery } from 'hooks/shops';
import { ShopScoreAdditionalTraits } from 'types/shops';

import {
  MetricTile,
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from '../metric-tile';

import styles from './styles.module.scss';

const tooltipText = `Things like out of stock items and incorrect hours contribute to your order issue rate, based on your orders from the past 28 days.`;

const AvoidableOrderIssueRate = ({ className, shopId }) => {
  const { data: shopScoreData, isPending } = useShopScoreQuery(shopId);

  // Show the rate as a percent rounded to the nearest integer.
  const avoidableIssueRate = Math.round(
    shopScoreData?.traitsWithValues?.[
      ShopScoreAdditionalTraits.AvoidableIssueRate
    ] ?? 0,
  );

  return (
    <MetricTile className={className}>
      <MetricTileContent>
        <MetricTileHeader>
          <MetricTileTitle>Avoidable Issue Rate</MetricTileTitle>
          <TooltipIconButton label="avoidable issue rate info">
            {tooltipText}
          </TooltipIconButton>
        </MetricTileHeader>
        <Suspended isLoading={isPending}>
          <MetricTileValue type="percent" value={avoidableIssueRate} />
          <div className={styles.criteria}>Criteria: below 5%</div>
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};

AvoidableOrderIssueRate.propTypes = {
  className: PropTypes.string,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AvoidableOrderIssueRate;
