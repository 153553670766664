import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { useDailyHoursQuery } from 'hooks/daily-hours/use-daily-hours';
import { useUpdateDailyhoursMutation } from 'hooks/daily-hours/use-update-daily-hours-mutation';
import { useClosings } from 'hooks/hours/use-closings';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import { showUnexpectedErrorToast } from 'utilities/forms';
import * as sortUtils from 'utilities/sorting';

import HoursOverview from './overview';

import styles from './styles.module.scss';

const OpeningStatus = ({ shopId }) => {
  const { trackDailyHoursActions } = useAnalytics();

  const { data: shopDailyHours } = useDailyHoursQuery(shopId);

  const trackDailyHoursActionsHelper = (
    buttonClicked,
    shippingType,
    date,
    editedDayTile,
    timeRange,
  ) =>
    trackDailyHoursActions(
      shopId,
      buttonClicked,
      shippingType,
      date,
      editedDayTile,
      timeRange || '',
    );

  const { data: shop } = useShopQuery(shopId);

  const shopTimezone = shop?.timezoneIdentifier;

  const { data: closingsData } = useClosings(shopId);

  const sortedClosings = closingsData
    ? sortUtils.sortDate('startDate', true, closingsData)
    : [];

  const isToday = (closing) => {
    const today = moment.tz(shopTimezone).format('YYYY-MM-DD');

    return moment(today).isBetween(
      closing.startDate,
      closing.endDate,
      'day',
      '[]',
    );
  };

  const nextClosing = sortedClosings?.find((closing) => !isToday(closing));

  const todaysClosing = sortedClosings?.find((closing) => isToday(closing));

  const { mutate: updateDailyHours, isPending: isUpdateInProgress } =
    useUpdateDailyhoursMutation(shopId);

  const handleUpdateDailyHours = (values) => {
    updateDailyHours(values, {
      onError: () => showUnexpectedErrorToast(),
      onSuccess: () => {
        toast.success(
          "Your restaurant's hours of operation have been successfully updated.",
        );
      },
    });
  };

  const onHoursFormSubmit = ({
    effectiveDate,
    hours,
    shippingType,
    todayOrTomorrow,
  }) => {
    return handleUpdateDailyHours({
      effectiveDate,
      hours,
      shippingType,
      todayOrTomorrow,
    });
  };

  return (
    <div className={styles.container}>
      <HoursOverview
        isUpdateInProgress={isUpdateInProgress}
        nextClosing={nextClosing}
        shopDailyHours={shopDailyHours}
        todaysClosing={todaysClosing}
        shopTimezone={shopTimezone}
        onHoursFormSubmit={onHoursFormSubmit}
        trackDailyHoursActions={trackDailyHoursActionsHelper}
        shopId={shopId}
      />
    </div>
  );
};

OpeningStatus.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OpeningStatus;
