import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Link } from 'crust';

import { DropdownButton } from 'components/shared/dropdown-button';
import PageHeader from 'components/shared/page/header';
import PageTitle from 'components/shared/page/title';
import Modal from 'components/shared/slice-modal';
import { StockDropdown } from 'components/shared/stock-dropdown';
import { setMenuProductsScrollIdSynchronous } from 'hooks/menu';
import { useDeleteProductMutation } from 'hooks/menu/use-delete-product-mutation';
import useAnalytics from 'hooks/use-analytics';
import EllipsesIcon from 'images/ellipses.svg?react';
import { getMenuItemsPath } from 'routes/paths/menu';
import { MenuCategory } from 'types/menu/category';
import { MenuProduct, MenuProductType } from 'types/menu/product';
import { showUnexpectedErrorToast } from 'utilities/forms';
import { generateShopMenuLink } from 'utilities/misc';

import { buildAnchorCategoryId } from './utils';

import styles from './styles.module.scss';

type Props = {
  category: MenuCategory | undefined;
  isLoading: boolean;
  isNew: boolean;
  product: MenuProduct | undefined;
  productTypes: MenuProductType[] | undefined;
  shopId: number;
  shopWebSlug: string | null | undefined;
};

const MenuProductPageHeader = ({
  category,
  isLoading,
  isNew,
  product,
  productTypes,
  shopId,
  shopWebSlug,
}: Props) => {
  const navigate = useNavigate();
  const { trackProductDelete, trackExternalLinkClick } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const title = isNew ? 'New Item' : 'Edit Item';

  const {
    isPending: isDeleting,
    isSuccess: isDeleted,
    mutate: deleteProduct,
  } = useDeleteProductMutation();

  const handleClickDelete = () => {
    if (!product) {
      return;
    }

    deleteProduct(
      { productId: product.id, shopId },
      {
        onError: () => {
          showUnexpectedErrorToast();
        },
        onSuccess: (response) => {
          const shopId = response.shopId;
          const productId = response.product.id;

          // Show the category of the deleted product. If the category is now
          // empty, it won't be found on the page and this scroll ID will just be
          // discarded. Likewise, if there is a search query, this scroll ID will
          // be discarded and the user will see the previous search without the
          // deleted product.
          setMenuProductsScrollIdSynchronous(
            `category-${response.product.categoryId}`,
          );

          trackProductDelete(shopId, productId);
          toast.success(
            `${response.product.name} has been deleted from your menu.`,
          );
        },
      },
    );
  };

  useEffect(() => {
    if (isDeleted) {
      navigate(getMenuItemsPath(shopId));
    }
  }, [isDeleted, navigate, shopId]);

  const productUrl = useMemo(() => {
    const name = category?.name;

    if (name) {
      return `${generateShopMenuLink(shopWebSlug)}#${buildAnchorCategoryId(
        name,
      )}`;
    }
  }, [category, shopWebSlug]);

  const trackClickViewOnline = () => {
    trackExternalLinkClick({
      shopId: String(shopId),
      linkLabel: 'View Product Online',
    });
  };

  const controls = [
    {
      name: 'Delete Item',
      action: () => setIsModalOpen(true),
    },
  ];

  if (productUrl) {
    controls.unshift({
      name: 'View Online',
      action: () => {
        trackClickViewOnline();
        window.open(productUrl, '_blank');
      },
    });
  }

  if (isNew || isLoading) {
    return (
      <PageHeader className={styles.header}>
        <PageTitle className={styles.title}>{title}</PageTitle>
      </PageHeader>
    );
  }

  return (
    <>
      <PageHeader className={styles.header}>
        <PageTitle className={styles.title}>
          {title}
          <DropdownButton
            buttonClassName={styles.overflowButton}
            buttonContent={<EllipsesIcon className={styles.ellipsisIcon} />}
            buttonLabel="item actions"
            controls={controls}
          />
        </PageTitle>
        <div className={styles.contentWrapper}>
          {product && productTypes ? (
            <StockDropdown
              product={product}
              productTypes={productTypes}
              shopId={String(shopId)}
            />
          ) : null}
          <Link
            className={styles.buttonWrapper}
            icon="newWindow"
            href={productUrl}
            onPress={trackClickViewOnline}
            size="small"
            variant="secondary"
          >
            View Online
          </Link>
          <Button
            className={styles.buttonWrapper}
            appearance="link"
            icon="trashCan"
            onPress={() => setIsModalOpen(true)}
            size="small"
            variant="secondary"
            data-testid="menuProductPageDeleteProductButton"
          >
            Delete Item
          </Button>
        </div>
      </PageHeader>
      <Modal
        header="Are you sure?"
        isOpen={isModalOpen}
        isYesButtonDisabled={isDeleting}
        onClickNo={() => setIsModalOpen(false)}
        onClickYes={handleClickDelete}
        onRequestClose={() => setIsModalOpen(false)}
        yesButtonDataTestId="menuProductPageDeleteModalConfirmDeleteButton"
        yesButtonText="Delete"
      >
        {`You are about to delete ${product?.name} from your menu.`}
      </Modal>
    </>
  );
};

export { MenuProductPageHeader };
