import { useMemo } from 'react';
import { Control, UseFieldArrayAppend, useWatch } from 'react-hook-form';

import { Button } from 'crust';

import { MenuCategoryFormValues } from 'types/menu/category';
import { getPossibleAvailabilities } from 'utilities/menu';

type Props = {
  appendField: UseFieldArrayAppend<
    MenuCategoryFormValues,
    `days.${number}.availabilities`
  >;
  control: Control<MenuCategoryFormValues>;
  dayFieldIndex: number;
  onAdd?: () => void;
};

export const AddAvailabilityButton = ({
  appendField,
  control,
  dayFieldIndex,
  onAdd,
}: Props) => {
  const availabilities = useWatch({
    control,
    name: `days.${dayFieldIndex}.availabilities`,
  });

  const candidates = useMemo(
    () => getPossibleAvailabilities(availabilities),
    [availabilities],
  );

  return (
    <Button
      appearance="link"
      isDisabled={candidates.length === 0}
      onPress={() => {
        if (candidates[0]) {
          appendField(candidates[0]);
          onAdd?.();
        }
      }}
    >
      Add More
    </Button>
  );
};
