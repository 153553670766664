import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CardNumberElement,
  useElements as useStripeElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AddDebitCardForm from 'components/debit-card/form';
import SubmitFooter from 'components/shared/submit-footer';
import { useCreateDebitCardMutation } from 'hooks/debit-cards/use-create-debit-card-mutation';
import * as paths from 'routes/paths';
import { showInvalidSubmitToast } from 'utilities/forms';

const errorSubmittingMessage =
  'There was a problem submitting your card details, please try again.';

const AddDebitCard = ({ shopId }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useStripeElements();

  const defaultFormvalues = {
    nameOnCard: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  };

  const { clearErrors, control, formState, handleSubmit, register, setError } =
    useForm({
      defaultValues: defaultFormvalues,
      mode: 'onBlur',
    });

  const { errors, isSubmitting } = formState;

  const { mutate: createDebitCard, isPending: isCreatingCard } =
    useCreateDebitCardMutation(shopId);

  const handleCreateDebitCard = (debitCardToken) =>
    createDebitCard(debitCardToken, {
      onError: () => {
        toast.error(errorSubmittingMessage);
      },
      onSuccess: () => {
        toast.success(
          'Thank you. We will contact you within 48 hours to confirm your request for a debit card payout.',
        );
        navigate(paths.debitCards(shopId));
      },
    });

  const { mutate: submit, isPending: isGeneratingStripeToken } = useMutation({
    mutationFn: (values) => {
      // To submit a debit card we first need to reach out to the stripe API directly, in order to generate a token.
      // This token means that no actual card values reach our code and instead we send a tokenized value
      // to rez API

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const { nameOnCard } = values;

      return stripe.createToken(cardElement, {
        name: nameOnCard,
        currency: 'USD',
        default_for_currency: true,
      });
    },
    onError: () => {
      toast.error(errorSubmittingMessage);
    },
    onSuccess: (data) => {
      // The call to stripe.createToken returns an error in the returned data rather than triggering
      // the onError so handle it here also.
      const { error, token } = data;

      if (error) {
        const errorMessage =
          error?.type === 'card_error' && error?.message
            ? error.message
            : errorSubmittingMessage;
        toast.error(errorMessage);
        return;
      }

      // We only want to accept debit cards, so display an error toast and skip sending the token to admin
      if (token?.card?.funding !== 'debit') {
        toast.error(
          'This card does not appear to be a debit card. Please try again.',
        );
        return;
      }

      handleCreateDebitCard(token.id);
    },
  });

  return (
    <>
      <AddDebitCardForm
        clearErrors={clearErrors}
        control={control}
        errors={errors}
        register={register}
        setError={setError}
        onSubmit={handleSubmit(submit, showInvalidSubmitToast)}
      >
        <SubmitFooter
          onCancel={() => navigate(-1)}
          isSubmitting={
            isSubmitting || isGeneratingStripeToken || isCreatingCard
          }
          submitButtonChameleonTarget="Add Debit Card Save Button"
          isSubmitDisabled={
            !stripe ||
            !elements ||
            isSubmitting ||
            isGeneratingStripeToken ||
            isCreatingCard
          }
        />
      </AddDebitCardForm>
    </>
  );
};

AddDebitCard.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddDebitCard;
