import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormText from 'components/shared/form-text';
import { Suspended } from 'components/shared/suspended';

import ProductTypes from './product-types';
import ProductTypesHelp from './product-types-help';

const ProductTypesTile = ({
  control,
  errors,
  getValues,
  isLoading = false,
  productId,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (errors) {
      setIsOpen(true);
    }
  }, [errors, submitCount]);

  return (
    <CollapsibleTile
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Type and Price"
      bodyChameleonTarget="Type and Price Area"
    >
      <Suspended isLoading={isLoading} variant="short">
        <FormText>
          Enter at least one item type and price, for example{' '}
          {'"Regular, Medium, Large"'}.
        </FormText>
        <ProductTypes
          control={control}
          getValues={getValues}
          errors={errors}
          register={register}
          setValue={setValue}
          submitCount={submitCount}
          shopId={shopId}
        />
        <ProductTypesHelp
          getValues={getValues}
          productId={productId}
          shopId={shopId}
        />
      </Suspended>
    </CollapsibleTile>
  );
};

ProductTypesTile.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.array,
  getValues: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductTypesTile;
