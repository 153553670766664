import { useCallback, useEffect, useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import Select from 'components/shared/slice-select';
import {
  CustomizationField,
  getCustomizationFieldName,
  ProductField,
  ProductTypeField,
} from 'utilities/menu';

import styles from './styles.module.scss';

const ProductTypeSelect = ({
  control,
  customizationIndex,
  hasSelectionError = false,
  isByType,
}) => {
  const id = `customization-${customizationIndex}-active-product-type-select`;

  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    control,
    name: getCustomizationFieldName(
      CustomizationField.ActiveProductTypeId,
      customizationIndex,
    ),
  });

  // We need to update the options when product type names change.
  const productTypes = useWatch({
    control,
    name: ProductField.ProductTypes,
  });

  const options = useMemo(
    () =>
      productTypes.map((it) => ({
        label: it[ProductTypeField.Name],
        value: it[ProductTypeField.Id],
      })),
    [productTypes],
  );

  const handleChange = useCallback(
    (option) => {
      onChange(option.value);
    },
    [onChange],
  );

  const selection = options.find((it) => it.value === value);

  useEffect(() => {
    // If the corresponding option no longer exists (ie the product type was
    // removed), reset the field value.
    if (selection == null) {
      handleChange(options[0]);
    }
  }, [handleChange, options, selection]);

  if (!isByType) {
    return null;
  }

  return (
    <>
      <Label htmlFor={id}>Item Option</Label>
      <Select
        className={styles.select}
        inputId={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        options={options}
        ref={ref}
        value={selection}
      />
      {hasSelectionError && (
        <FormFeedback>
          Please check that all selections (such as toppings) have a name and
          price. You may need to toggle between item options (such as sizes) to
          view more.
        </FormFeedback>
      )}
    </>
  );
};

ProductTypeSelect.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  hasSelectionError: PropTypes.bool,
  isByType: PropTypes.bool.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductTypeSelect;
