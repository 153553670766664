import cx from 'classnames';

import Spinner from 'components/shared/spinner';

import styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  variant?: 'short' | 'stretch' | 'viewPort';
};

// This component is meant to simplify the patterns used to show content behind
// a loading spinner. It contains both the styling necessary to act as a decent
// placeholder and the conditional logic to remove ternary operators elsewhere.
export const Suspended = ({
  children,
  className,
  isLoading = false,
  variant = 'stretch',
}: Props) => {
  if (isLoading) {
    return (
      <div className={cx(styles[variant], className)}>
        <Spinner />
      </div>
    );
  }

  return children;
};
