import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import CustomWebsite from 'components/website-tools/custom-website';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

import styles from './styles.module.scss';

const CustomWebsitePage = () => {
  const shopId = useCurrentShopId();

  const {
    data: user,
    isError: isUserError,
    isPending: isUserLoading,
  } = useCurrentUserQuery();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  let content;

  if (isShopLoading || isUserLoading) {
    content = <Loading />;
  } else if (isShopError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <CustomWebsite
        domainName={shop.domainName}
        shopId={String(shopId)}
        user={user}
      />
    );
  }

  return (
    <Page
      name="Custom Website"
      title="Custom Website"
      contentClassName={styles.customWebsiteContent}
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomWebsitePage;
