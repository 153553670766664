import { IconName } from 'crust/components/icon/icon';

import * as externalUrls from 'routes/external-urls';
import * as paths from 'routes/paths';
import {
  getCustomerAnalyticsPath,
  getCustomerProfilesPath,
  getCustomerSegmentsPath,
} from 'routes/paths/customers';
import { Shop } from 'types/shops';
import {
  getShopHasBasicRegister,
  getShopHasFullRegister,
} from 'utilities/shops';

export type SubItem = {
  activeWith?: RegExp[] | string[];
  label: string;
  url: string;
};

export type Item = {
  activeWith?: RegExp[] | string[];
  dataTestId?: string;
  icon: IconName;
  items?: SubItem[];
  label: string;
  url: string;
};

export type NavSection = {
  name: string;
  items: Item[];
};

export const firstSection = (
  shopId: string,
  isHoursAnalyticsEnabled: boolean,
  isMobile: boolean,
) => {
  const sectionItems: Item[] = [];

  const homeItem: Item = {
    icon: 'house',
    label: 'Home',
    url: paths.shop(shopId),
    dataTestId: isMobile ? 'mobileNavItemHome' : 'desktopNavItemHome',
  };
  sectionItems.push(homeItem);

  const menuItem: Item = {
    activeWith: [/shops\/\d+\/menu/],
    icon: 'menu',
    items: [
      {
        activeWith: [/shops\/\d+\/menu\/items\/\w+/],
        label: 'Items',
        url: paths.getMenuItemsPath(shopId),
      },
      {
        activeWith: [/shops\/\d+\/menu\/categories\/\w+/],
        label: 'Categories',
        url: paths.getMenuCategoriesPath(shopId),
      },
      {
        label: 'Online Menu Layout',
        url: paths.getMenuLayoutPath(shopId),
      },
    ],
    label: 'Menu',
    url: paths.getMenuItemsPath(shopId),
  };
  sectionItems.push(menuItem);

  const hoursItem: Item = {
    activeWith: [
      paths.shopHoursDelivery(shopId),
      paths.shopHoursWeekly(shopId),
      paths.shopClosings(shopId),
      paths.shopHoursAnalytics(shopId),
    ],
    icon: 'clock',
    items: [
      {
        activeWith: [
          paths.shopHoursDelivery(shopId),
          paths.shopHoursWeekly(shopId),
          paths.shopClosings(shopId),
        ],
        label: 'Overview',
        url: paths.shopHours(shopId),
      },
    ],
    label: 'Hours',
    url: paths.shopHours(shopId),
  };

  if (isHoursAnalyticsEnabled) {
    hoursItem.items?.push({
      label: 'Hours Analytics',
      url: paths.shopHoursAnalytics(shopId),
    });
  }

  sectionItems.push(hoursItem);

  return {
    name: 'first',
    items: sectionItems,
  } as NavSection;
};

export const secondSection = (
  shopId: string,
  isCustomerProfilesPageEnabled: boolean,
  isGoodsPaymentsPageEnabled: boolean,
) => {
  const sectionItems: Item[] = [];

  const financialsItem: Item = {
    icon: 'money',
    items: [
      {
        activeWith: [
          paths.getFinancialsOrdersPath(shopId, 'online'),
          paths.getFinancialsOrdersPath(shopId, 'register'),
        ],
        label: 'Orders',
        url: paths.getFinancialsOrdersPath(shopId, 'online'),
      },
      {
        label: 'Statements',
        url: paths.getFinancialsStatementsPath(shopId),
      },
      {
        label: 'Payouts',
        url: paths.getFinancialsPayoutsPath(shopId),
      },
      {
        activeWith: [/shops\/\d+\/financials\/reports\/\w+/],
        label: 'Reports',
        url: paths.getFinancialsReportsOrderActivityPath(shopId),
      },
    ],
    label: 'Financials',
    url: paths.getFinancialsOrdersPath(shopId, 'online'),
  };

  if (isGoodsPaymentsPageEnabled) {
    financialsItem.items?.push({
      label: 'Goods Payments',
      url: paths.getFinancialsGoodsPaymentsPath(shopId),
    });
  }

  sectionItems.push(financialsItem);

  const customersItem: Item = {
    icon: 'people',
    items: [
      {
        label: 'Analytics',
        url: getCustomerAnalyticsPath(shopId),
      },
      {
        label: 'Segments',
        url: getCustomerSegmentsPath(shopId),
      },
      {
        label: 'Feedback',
        url: paths.getCustomerFeedbackPath(shopId),
      },
    ],
    label: 'Customers',
    url: getCustomerAnalyticsPath(shopId),
  };

  if (isCustomerProfilesPageEnabled) {
    const profilesItem: SubItem = {
      label: 'Profiles',
      url: getCustomerProfilesPath(shopId),
    };

    customersItem.items?.splice(2, 0, profilesItem);
  }

  sectionItems.push(customersItem);

  const analyticsItem: Item = {
    activeWith: [paths.shopAnalyticsCustomers(shopId)],
    icon: 'graph',
    label: 'Analytics',
    url: paths.shopAnalyticsOrders(shopId),
  };
  sectionItems.push(analyticsItem);

  return { name: 'second', items: sectionItems } as NavSection;
};

export const thirdSection = ({
  shopId,
  isBrandManagerPageEnabled,
  isGoogleBusinessProfilePageEnabled,
  canViewDigitalAdsReport,
}: {
  shopId: string;
  isBrandManagerPageEnabled: boolean;
  isUpsellAMSEnabled: boolean;
  isGoogleBusinessProfilePageEnabled: boolean;
  canViewDigitalAdsReport: boolean;
}) => {
  const sectionItems: Item[] = [];

  const marketingItem: Item = {
    icon: 'bubble',
    items: [
      {
        activeWith: [/shops\/\d+\/marketing\/discounts(\/[\d-]+)?/],
        label: 'Discounts',
        url: paths.automaticDiscounts(shopId),
        // Match both promo codes and online discounts, including their respective create and
        // and edit pages.
      },
      {
        activeWith: [paths.assetLibrary(shopId)],
        label: 'Branding Studio',
        url: paths.assetLibrary(shopId),
      },
    ],
    label: 'Marketing',
    url: paths.automaticDiscounts(shopId),
  };

  if (canViewDigitalAdsReport) {
    marketingItem.items?.push({
      activeWith: [paths.campaignManager(shopId)],
      label: 'Digital Ads Report',
      url: paths.campaignManager(shopId),
    });
  }
  sectionItems.push(marketingItem);

  const websiteItem: Item = {
    activeWith: [
      paths.shopPartnerButtonsCreateCustomButton(shopId),
      paths.shopPartnerButtonsGetLink(shopId),
      paths.shopCustomWebsite(shopId),
      paths.shopSmartButtons(shopId),
      paths.shopSmartPopups(shopId),
    ],
    icon: 'website',
    items: [
      {
        label: 'Smart Buttons',
        url: paths.shopSmartButtons(shopId),
      },
      {
        label: 'Smart Popups',
        url: paths.shopSmartPopups(shopId),
      },
      {
        label: 'Custom Website',
        url: paths.shopCustomWebsite(shopId),
      },
      {
        activeWith: [
          paths.shopPartnerButtonsCreateCustomButton(shopId),
          paths.shopPartnerButtonsGetLink(shopId),
        ],
        label: 'Classic Buttons',
        url: paths.shopPartnerButtonsCreateCustomButton(shopId),
      },
    ],
    label: 'Website Tools',
    url: paths.shopSmartButtons(shopId),
  };
  sectionItems.push(websiteItem);

  const onlinePresenceItem: Item = {
    activeWith: [paths.shopScore(shopId)],
    icon: 'verification',
    items: [
      {
        label: 'Pizzeria Score',
        url: paths.shopScore(shopId),
      },
    ],
    label: 'Online Presence',
    url: paths.shopScore(shopId),
  };

  if (isGoogleBusinessProfilePageEnabled) {
    onlinePresenceItem.items?.push({
      activeWith: [/shops\/\d+\/online-presence\/google-business-profile/],
      label: 'Google Business Profile',
      url: paths.googleBusinessProfile(shopId),
    });
  }

  if (isBrandManagerPageEnabled) {
    onlinePresenceItem.items?.push({
      activeWith: [paths.brandManager(shopId)],
      label: 'Brand Manager',
      url: paths.brandManager(shopId),
    });
  }

  sectionItems.push(onlinePresenceItem);

  return { name: 'third', items: sectionItems } as NavSection;
};

export const registerSection = ({ shop }: { shop: Shop }) => {
  const { shopId } = shop;
  const registerNavItem: Item = {
    icon: 'register',
    label: 'Register',
    url: '',
  };

  if (getShopHasFullRegister(shop)) {
    registerNavItem.url = paths.registerLayout(shopId);

    registerNavItem.items = [
      {
        activeWith: [/shops\/\d+\/register\/layout/],
        label: 'Register Menu Layout',
        url: paths.registerLayout(shopId),
      },
      {
        activeWith: [
          /shops\/\d+\/register\/(users|permissions|log)(\/[\d-]+)?/,
        ],
        label: 'Employee Permissions',
        url: paths.registerUsers(shopId),
      },
      {
        label: 'Printer Settings',
        url: paths.shopRegisterPrinting(shopId),
      },
      {
        activeWith: [
          /shops\/\d+\/register\/promotions-and-discounts(\/[\d-]+)?/,
        ],
        label: 'Promotions & Discounts',
        url: paths.promotionsAndDiscounts(shopId),
      },
      {
        label: 'Tip Settings',
        url: paths.registerTipSettings(shopId),
      },
      {
        label: 'System Profiles',
        url: paths.registerProfiles(shopId),
      },
      {
        label: 'User Guide',
        url: externalUrls.registerUserGuide,
      },
    ];
  } else {
    registerNavItem.url = paths.getRegisterSplashPath(shopId);

    if (getShopHasBasicRegister(shop)) {
      registerNavItem.activeWith = [/shops\/\d+\/register/];

      registerNavItem.items = [
        {
          label: 'Learn More',
          url: paths.getRegisterSplashPath(shopId),
        },
        {
          label: 'Printer Settings',
          url: paths.shopRegisterPrinting(shopId),
        },
      ];
    }
  }

  return {
    name: 'register',
    items: [registerNavItem],
  } as NavSection;
};

export const phoneSection = (shopId: string, isPhonePageEnabled: boolean) => {
  if (isPhonePageEnabled) {
    const phoneNavItem: Item = {
      icon: 'phone',
      label: 'Phone',
      url: paths.getPhonePath(shopId),
    };

    return {
      name: 'phone',
      items: [phoneNavItem],
    } as NavSection;
  }

  return null;
};

export const operationsSection = (shopId: string) => {
  const operationsNavItem: Item = {
    icon: 'shop',
    label: 'Operations',
    url: paths.operations(shopId),
  };

  return {
    name: 'operations',
    items: [operationsNavItem],
  } as NavSection;
};

export const accountsSection = (
  shopId: string,
  isSlicePaymentsEnabled: boolean,
  isPayoutManagementFlagEnabled: boolean,
) => {
  const accountNavItem: Item = {
    icon: 'gear',
    items: [
      {
        label: 'POS Integrations',
        url: paths.shopIntegrations(shopId),
      },
      {
        label: 'Knowledge Hub',
        url: externalUrls.knowledgeHub,
      },
    ],
    label: 'Account',
    url: paths.shopIntegrations(shopId),
  };

  if (isSlicePaymentsEnabled && isPayoutManagementFlagEnabled) {
    accountNavItem.items?.splice(1, 0, {
      activeWith: [paths.debitCards(shopId), paths.debitCardNew(shopId)],
      label: 'Payout Management',
      url: paths.debitCards(shopId),
    });
  }

  return { name: 'account', items: [accountNavItem] } as NavSection;
};
