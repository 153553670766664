import { useState } from 'react';
import PropTypes from 'prop-types';

import CollapsibleTile, {
  CollapsibleTileVariant,
} from 'components/shared/collapsible-tile';
import { formatDailyHour } from 'utilities/date-time';

import EditorForm from './editor-form';

import styles from './styles.module.scss';

export const Day = ({
  className,
  dataTestId,
  effectiveDate,
  hours = [],
  isUpdateInProgress = false,
  onFormSubmit = () => {},
  shippingType = '',
  shopTimezone = '',
  trackDailyHoursActions = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasHours = hours.length > 0;

  let summary;

  if (hours.length === 0) {
    summary = (
      <span className={styles.closed}>
        Closed <span className={styles.closedExtended}> for the day</span>
      </span>
    );
  } else if (hours.length < 3) {
    summary = hours.map((hour) => {
      const { from, to } = hour;
      return (
        <span className={styles.hour} key={from}>
          {formatDailyHour(from, shopTimezone)} -{' '}
          {formatDailyHour(to, shopTimezone)}
        </span>
      );
    });
  } else {
    summary = (
      <>
        <span className={styles.hour}>
          {formatDailyHour(hours[0].from, shopTimezone)} -{' '}
          {formatDailyHour(hours[0].to, shopTimezone)}
        </span>
        <span className={styles.hour}>+ {hours.length - 1} more</span>
      </>
    );
  }

  return (
    <CollapsibleTile
      className={className}
      isDisabled={!hasHours}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={summary}
      summaryClassName={styles.summary}
      title={shippingType}
      titleClassName={styles.title}
      variant={CollapsibleTileVariant.Embedded}
      dataTestId={dataTestId}
    >
      <EditorForm
        effectiveDate={effectiveDate}
        hours={hours}
        isUpdateInProgress={isUpdateInProgress}
        onCloseForm={() => setIsOpen(false)}
        onFormSubmit={onFormSubmit}
        shopTimezone={shopTimezone}
        trackDailyHoursActions={trackDailyHoursActions}
      />
    </CollapsibleTile>
  );
};

Day.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  effectiveDate: PropTypes.string,
  hours: PropTypes.array,
  isUpdateInProgress: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  shippingType: PropTypes.string,
  shopTimezone: PropTypes.string,
  trackDailyHoursActions: PropTypes.func,
};
