import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Button } from 'crust';

import VideoTutorialModal from 'components/shared/video-tutorial-modal';
import useAnalytics from 'hooks/use-analytics';
import EmptyCustomizationsIcon from 'images/placeholders/empty-customizations.svg?react';
import { ProductField } from 'utilities/menu';
import VideoTutorials from 'utilities/video-tutorials';

import AddCustomizationsModal from './add-customizations-modal';
import Customization from './customization';

import styles from './styles.module.scss';

const Customizations = ({
  categoriesById,
  categoryOptions,
  control,
  errors,
  getValues,
  productId,
  productsById,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  const { trackClickedAddCustomizations, trackViewedVideoTutorial } =
    useAnalytics();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  // Customizations don't have an ID that could conflict with the field array
  // keyName, but we'll change it to stay consistent.
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: 'key',
    name: ProductField.Customizations,
  });

  const handleClickAdd = () => {
    trackClickedAddCustomizations({
      shopId,
      productId,
    });
    setIsAddModalOpen(true);
  };

  const isNew = productId === 'new';

  const onClickHelpButton = () => {
    trackViewedVideoTutorial({
      shopId,
      type: 'menu',
      page: `Menu Product ${isNew ? 'Add' : 'Edit'} Page`,
      videoType: 'Menu Modifier',
      videoLocation: `${isNew ? 'Add' : 'Edit'} Item Customization Tile`,
    });
    setIsHelpModalOpen(true);
  };

  return (
    <>
      {fields.length === 0 ? (
        <div className={styles.empty}>
          <EmptyCustomizationsIcon className={styles.emptyIcon} />
          Please call us to add customizations, or leave this section empty if
          customizations are not applicable.
        </div>
      ) : (
        <div
          className={styles.list}
          data-chameleon-target="Customizations Set Area"
        >
          {fields.map((field, index) => (
            <Customization
              control={control}
              errors={errors?.[index]}
              index={index}
              getValues={getValues}
              key={field.key}
              length={fields.length}
              productId={productId}
              register={register}
              removeField={() => remove(index)}
              setValue={setValue}
              shopId={shopId}
              submitCount={submitCount}
            />
          ))}
        </div>
      )}
      <div className={styles.editOptions}>
        <Button appearance="link" onPress={handleClickAdd}>
          Add Customizations
        </Button>
        <Button
          appearance="link"
          className={styles.helpLink}
          icon="help"
          onClick={() => onClickHelpButton()}
          variant="secondary"
        >
          Need Help?
        </Button>
      </div>

      <AddCustomizationsModal
        appendCustomizations={append}
        categoriesById={categoriesById}
        categoryOptions={categoryOptions}
        getValues={getValues}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        productId={productId}
        productsById={productsById}
        setValue={setValue}
        shopId={shopId}
      />

      <VideoTutorialModal
        modalHeader="How to add or edit modifiers"
        onClose={() => setIsHelpModalOpen(false)}
        showModal={isHelpModalOpen}
        videoSrc={VideoTutorials.AddOrEditMenuCustomization}
        videoTitle="How to add or edit modifiers"
      />
    </>
  );
};

Customizations.propTypes = {
  categoriesById: PropTypes.object.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      length: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.array,
  getValues: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  productsById: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Customizations;
