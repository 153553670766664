import moment from 'moment-timezone';

import Loading from 'components/shared/loading';
import Modal from 'components/shared/slice-modal';
import { useRegisterOrderQuery } from 'hooks/orders';
import useAnalytics from 'hooks/use-analytics';
import { useWindowSize } from 'hooks/use-window-size';

import ModalBody from './body';
import ModalHeader from './header';

import styles from './styles.module.scss';

export type RegisterOrderModalProps = {
  handleCloseModal: () => void;
  isOpen: boolean;
  orderId: string;
  shopId: string;
  timezone: string;
};

const RegisterOrderModal = ({
  handleCloseModal,
  isOpen,
  orderId,
  shopId,
  timezone,
}: RegisterOrderModalProps) => {
  const { isMobile } = useWindowSize();
  const { trackClickedPrintReceiptModal } = useAnalytics();

  const { data: order, isPending } = useRegisterOrderQuery({
    orderId,
    shopId,
  });

  let modalYesButton;

  if (!isMobile) {
    modalYesButton = {
      onClickYes: () => {
        trackClickedPrintReceiptModal({ shopId });
        window.print();
      },
    };
  }

  return (
    <Modal
      className={styles.orderModal}
      header={
        !isPending &&
        order && (
          <ModalHeader
            date={
              order?.completedAt
                ? moment.tz(order.completedAt, timezone).format('MMM DD, YYYY')
                : ''
            }
            orderNumber={order?.number}
          />
        )
      }
      isOpen={isOpen}
      isYesButtonDisabled={isPending}
      noButtonText="Cancel"
      onClickNo={() => handleCloseModal()}
      onRequestClose={() => handleCloseModal()}
      role="dialog"
      yesButtonText="Print"
      {...modalYesButton}
    >
      {order ? <ModalBody order={order} timezone={timezone} /> : <Loading />}
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterOrderModal;
