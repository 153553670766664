import { useState } from 'react';

import { Divider } from 'components/shared/divider';
import { useRegisterUpsellModal } from 'hooks/salesforce/use-register-upsell-modal';
import useAnalytics from 'hooks/use-analytics';
import { RegisterUpsellModalOptions } from 'types/register/register-splash';
import { Shop } from 'types/shops';
import { User } from 'types/user';

import { BenefitsSection } from './benefits-section';
import { FaqSection } from './faq-section';
import { RegisterSplashFooter } from './footer';
import { RegisterSplashHeader } from './header';
import { RegisterUpsellModal } from './register-upsell-modal';
import { VideoPreviewModal } from './video-preview-modal';
import { VideoPreviewSection } from './video-preview-section';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  user: User;
};

export const RegisterSplash = ({ shopId, user }: Props) => {
  const { trackClickedRegisterSplashVideo } = useAnalytics();

  const [openedModal, setOpenedModal] =
    useState<RegisterUpsellModalOptions | null>(null);

  const handleOpenModalClick = (modal: RegisterUpsellModalOptions) => {
    setOpenedModal(modal);
  };

  const handleModalClose = () => {
    setOpenedModal(null);
  };

  const { hasValidCase, setCaseExpiration } = useRegisterUpsellModal(shopId);

  const handlePreviewVideo = () => {
    handleOpenModalClick('videoPreview');
    trackClickedRegisterSplashVideo({
      shopId: String(shopId),
      page: 'register [splash]',
    });
  };

  return (
    <>
      <div className={styles.container}>
        <RegisterSplashHeader
          hasValidCase={hasValidCase}
          onScheduleDemoButtonClick={() => handleOpenModalClick('scheduleDemo')}
          shopId={shopId}
        />
        <VideoPreviewSection onPreviewClick={handlePreviewVideo} />
        <BenefitsSection />
        <Divider className={styles.divider} />
        <FaqSection />
        <Divider className={styles.divider} />
        <RegisterSplashFooter
          hasValidCase={hasValidCase}
          onScheduleDemoButtonClick={() => handleOpenModalClick('scheduleDemo')}
          shopId={shopId}
        />
      </div>

      <RegisterUpsellModal
        isOpen={openedModal === 'scheduleDemo'}
        onRequestClose={handleModalClose}
        shopId={shopId}
        user={user}
        setCaseExpiration={setCaseExpiration}
        page={'register [splash]'}
      />

      <VideoPreviewModal
        isOpen={openedModal === 'videoPreview'}
        onRequestClose={handleModalClose}
        onScheduleDemoClick={() => setOpenedModal('scheduleDemo')}
        hasValidCase={hasValidCase}
        shopId={shopId}
      />
    </>
  );
};
