import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTable } from 'hooks/shared/use-table';
import useAnalytics from 'hooks/use-analytics';
import { useShopStatementsQuery } from 'hooks/use-shop-statements-query';
import { useStatementMutation } from 'hooks/use-statement-mutation';
import * as paths from 'routes/paths';
import { createStringComparator, Direction } from 'utilities/sorting';

import styles from './styles.module.scss';

const OrderStatementsTile = ({ className = '', shopId, showAll = false }) => {
  const { trackDownloadOrderStatement } = useAnalytics();

  const { data: statements, isPending: isStatementsLoading } =
    useShopStatementsQuery({ shopId });

  const filtered = useMemo(() => {
    const comparator = createStringComparator('toDate', {
      direction: Direction.Descending,
    });
    const sorted = statements?.sort(comparator) ?? [];
    return showAll ? sorted : sorted.slice(0, 4);
  }, [showAll, statements]);

  const { mutate: openStatement } = useStatementMutation({ shopId });

  const viewAllLink = (
    <div className={styles.viewAllLinkWrapper}>
      <Link
        href={paths.getFinancialsStatementsPath(shopId)}
        icon="chevronRight"
        iconPosition="end"
        variant="secondary"
      >
        View All
      </Link>
    </div>
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper();

    const handleOpenStatement = (statementId) => {
      const statementWindow = window.open('', '_blank');

      trackDownloadOrderStatement(statementId);
      openStatement(statementId, {
        onError: () => {
          statementWindow?.close();
          window.alert('Statement not found');
        },
        onSuccess: (response) => {
          if (statementWindow) {
            statementWindow.location = response.presignedUrl;
          }
        },
      });
    };

    // Formatting the statement dates timezone to PST for display consistency
    // with Admin and Statement details
    const formatDateRange = (from, to) =>
      `${moment.tz(from, 'America/Los_Angeles').format('MM/DD/YYYY')} - ${moment
        .tz(to, 'America/Los_Angeles')
        .format('MM/DD/YYYY')}`;

    return [
      helper.accessor('toDate', {
        id: 'report',
        header: 'Statement',
        cell(ctx) {
          const report = ctx.row.original;

          return (
            <>
              <span className={styles.dateRange}>
                {formatDateRange(report.fromDate, report.toDate)}
              </span>
              <br />
              Online and phone orders
            </>
          );
        },
        meta: {
          className: styles.reportColumn,
        },
      }),

      helper.display({
        id: 'download',
        header() {
          return <VisuallyHidden>Download</VisuallyHidden>;
        },
        meta: {
          isActionColumn: true,
        },
        cell(ctx) {
          const statement = ctx.row.original;
          const range = formatDateRange(statement.fromDate, statement.toDate);

          return (
            <TableAction
              className={styles.downloadLink}
              icon="download"
              onClick={() => handleOpenStatement(statement.id)}
              title={`Download order statement for ${range}.`}
            />
          );
        },
      }),
    ];
  }, [openStatement, trackDownloadOrderStatement]);

  const table = useTable({
    columns,
    data: filtered,
    state: {
      isLoading: isStatementsLoading,
    },
    chameleonTableTitle: 'Order statements',
  });

  return (
    <ContentTile title={!showAll && 'Statements'} className={className}>
      <Table table={table} />
      {!showAll && viewAllLink}
    </ContentTile>
  );
};

OrderStatementsTile.propTypes = {
  className: PropTypes.string,
  shopId: PropTypes.number.isRequired,
  showAll: PropTypes.bool,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OrderStatementsTile;
