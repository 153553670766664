import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { WebsiteIntegrationsSettings } from 'types/website-tools';
import { camelCaseKeys } from 'utilities/objects';

export const getWebsiteIntegrationsSettingsQueryKey = (
  shopId: number,
): QueryKey => [shopId, 'smartButtonsSettings'];

type UseWebsiteIntegrationsSettingsQueryOptions = Omit<
  UseQueryOptions<WebsiteIntegrationsSettings>,
  'queryKey' | 'queryFn'
>;

export const useWebsiteIntegrationsSettingsQuery = (
  shopId: number,
  options?: UseWebsiteIntegrationsSettingsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getWebsiteIntegrationsSettingsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/third_party_web_setting`,
      );

      return camelCaseKeys(response) as WebsiteIntegrationsSettings;
    },
    ...options,
  });
};
