import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Button, Link } from 'crust';

import CategorySelect from 'components/shared/category-select';
import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import FormText from 'components/shared/form-text';
import Input from 'components/shared/input';
import InputDescription from 'components/shared/input-description';
import Label from 'components/shared/label';
import { Suspended } from 'components/shared/suspended';
import TextArea from 'components/shared/text-area';
import VideoTutorialModal from 'components/shared/video-tutorial-modal';
import useAnalytics from 'hooks/use-analytics';
import useValidationErrorTracking, {
  ValidationErrorLocation,
  ValidationInputId,
} from 'hooks/use-validation-error-tracking.ts';
import { phoneNumbers } from 'utilities/constants';
import { AnalyticsField, ProductField } from 'utilities/menu';
import { formatPhoneNumber } from 'utilities/strings';
import VideoTutorials from 'utilities/video-tutorials';

import ProductPhoto from './photo';

import styles from './styles.module.scss';

const ProductDetailsTile = ({
  categoriesById,
  categoryError = null,
  categoryOptions,
  className = null,
  control,
  descriptionError = null,
  getValues,
  isLoading = false,
  isRosEnabled,
  isKitchenNameEnabled = false,
  kitchenNameError = null,
  productId,
  productsById,
  nameError = null,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { trackViewedVideoTutorial } = useAnalytics();

  const validateUniqueName = (value) => {
    if (!value) {
      return true;
    }

    value = value.toLowerCase();
    const categoryId = getValues(ProductField.CategoryId);
    const productIds = categoriesById[categoryId]?.productIds ?? [];

    for (const otherProductId of productIds) {
      if (
        otherProductId !== Number(productId) &&
        productsById[otherProductId]?.name.toLowerCase() === value
      ) {
        return 'A product with the same name already exists within the category. Please enter a new category or product name.';
      }
    }

    return true;
  };

  useEffect(() => {
    if (categoryError || kitchenNameError || nameError) {
      setIsOpen(true);
    }
  }, [categoryError, kitchenNameError, nameError, submitCount]);

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductName,
    nameError,
    submitCount,
  );

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductKitchenName,
    kitchenNameError,
    submitCount,
  );

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductCategory,
    categoryError,
    submitCount,
  );

  const isNew = productId === 'new';

  const onClickHelpButton = () => {
    trackViewedVideoTutorial({
      shopId,
      type: 'menu',
      page: `Menu Product ${isNew ? 'Add' : 'Edit'} Page`,
      videoType: 'Menu Item',
      videoLocation: `${isNew ? 'Add' : 'Edit'} Item Details Tile`,
    });
    setShowModal(true);
  };

  return (
    <CollapsibleTile
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Item Details"
      bodyChameleonTarget="Item Details Area"
    >
      <Suspended isLoading={isLoading} variant="short">
        <FormText>
          Including an item photo is a good way to increase your sales.
        </FormText>
        <div className={styles.itemDetailsWrapper}>
          <div className={styles.fieldsWrapper}>
            <div className={styles.field}>
              <Label htmlFor="product-name-input">Name</Label>
              <Input
                aria-describedby="name-description"
                aria-required="true"
                data-testid="productPageProductNameInput"
                id="product-name-input"
                isInvalid={nameError != null}
                placeholder="e.g. Cheese Pizza"
                {...register(ProductField.Name, {
                  maxLength: {
                    message: 'Please enter no more than 100 characters.',
                    value: 100,
                  },
                  onChange: () => setValue(AnalyticsField.NameChanged, true),
                  required: 'Please enter an item name.',
                  validate: validateUniqueName,
                })}
              />
              <InputDescription id="name-description">
                You cannot add alcoholic items through {"Slice's"} self-service
                menu editor. For any questions, call us at{' '}
                <Link
                  href={`tel:${phoneNumbers.accountManagers}`}
                  variant="tertiary"
                >
                  {formatPhoneNumber(phoneNumbers.accountManagers)}
                </Link>{' '}
                .
              </InputDescription>
              <FormFeedback>{nameError?.message}</FormFeedback>
            </div>
            {isKitchenNameEnabled && isRosEnabled && (
              <div className={styles.field}>
                <Label htmlFor="product-kitchen-name-input">Kitchen name</Label>
                <Input
                  aria-describedby="kitchen-name-description"
                  data-testid="productPageProductKitchenNameInput"
                  id="product-kitchen-name-input"
                  isInvalid={kitchenNameError != null}
                  {...register(ProductField.KitchenName, {
                    maxLength: {
                      message: 'Please enter no more than 100 characters.',
                      value: 100,
                    },
                    onChange: () => {
                      setValue(AnalyticsField.KitchenNameChanged, true);
                    },
                  })}
                />
                <InputDescription id="kitchen-name-description">
                  By shortening your {"item's"} name on the kitchen ticket, it
                  could speed up the {"kitchens'"} operations and save on ink
                  costs. If not provided, the kitchen name will default to the
                  product name.
                </InputDescription>
                <FormFeedback>{kitchenNameError?.message}</FormFeedback>
              </div>
            )}
            <div className={styles.field}>
              <Label
                htmlFor="product-category-select"
                id="product-category-select-label"
              >
                Category
              </Label>
              <Controller
                control={control}
                name={ProductField.CategoryId}
                rules={{
                  deps: ProductField.Name,
                  onChange: () =>
                    setValue(AnalyticsField.CategoryChanged, true),
                  required: 'Please choose a category.',
                }}
                render={({ field }) => (
                  <CategorySelect
                    aria-labelledby="product-category-select-label"
                    aria-required="true"
                    inputId="product-category-select"
                    isInvalid={categoryError != null}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(option) => field.onChange(option.value)}
                    options={categoryOptions}
                    ref={field.ref}
                    value={categoryOptions.find(
                      (option) => option.value === field.value,
                    )}
                  />
                )}
              />
              <FormFeedback>{categoryError?.message}</FormFeedback>
            </div>
            <div className={styles.field}>
              <Label htmlFor="product-description-textarea">Description</Label>
              <TextArea
                id="product-description-textarea"
                placeholder="Example: Plum tomatoes, fresh garlic"
                {...register(ProductField.Description, {
                  maxLength: {
                    message: 'Please enter no more than 5000 characters.',
                    value: 5000,
                  },
                  onChange: () =>
                    setValue(AnalyticsField.DescriptionChanged, true),
                })}
              />
              <FormFeedback>{descriptionError?.message}</FormFeedback>
            </div>
          </div>
          <ProductPhoto
            control={control}
            productId={productId}
            shopId={shopId}
          />
        </div>
        <input type="hidden" {...register(AnalyticsField.NameChanged)} />
        <input type="hidden" {...register(AnalyticsField.KitchenNameChanged)} />
        <input type="hidden" {...register(AnalyticsField.CategoryChanged)} />
        <input type="hidden" {...register(AnalyticsField.DescriptionChanged)} />
        <Button
          appearance="link"
          className={styles.helpLink}
          icon="help"
          onClick={() => onClickHelpButton()}
          variant="secondary"
        >
          Need Help?
        </Button>
      </Suspended>
      <VideoTutorialModal
        modalHeader="How to add or edit a menu item"
        onClose={() => setShowModal(false)}
        showModal={showModal}
        videoSrc={VideoTutorials.AddOrEditMenuItem}
        videoTitle="How to add or edit a menu item"
      />
    </CollapsibleTile>
  );
};

ProductDetailsTile.propTypes = {
  categoriesById: PropTypes.object.isRequired,
  categoryError: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
  }),
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  isRosEnabled: PropTypes.bool.isRequired,
  isKitchenNameEnabled: PropTypes.bool.isRequired,
  kitchenNameError: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
  }),
  descriptionError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  getValues: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  productsById: PropTypes.object.isRequired,
  nameError: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
  }),
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductDetailsTile;
