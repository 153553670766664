import { Reports } from 'components/reports';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

const ReportsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    error: shopQueryError,
    isPending: isShopQueryLoading,
  } = useShopQuery(shopId);

  const {
    isEnabled: isRegisterCombinedSalesSummaryReportsEnabled,
    isLoading: isRegisterCombinedSalesSummaryReportsEnabledLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.RegisterCombinedSalesSummaryReport);

  let content;
  if (
    isShopQueryLoading ||
    isRegisterCombinedSalesSummaryReportsEnabledLoading
  ) {
    content = <Loading />;
  } else if (shop == null || shopQueryError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <Reports
        isRegisterCombinedSalesSummaryReportsEnabled={
          isRegisterCombinedSalesSummaryReportsEnabled
        }
        shop={shop}
      />
    );
  }

  return (
    <Page name="reports" title="Reports">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ReportsPage;
