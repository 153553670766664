import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';

import { getMenuProductsQueryKey } from './use-menu-products-query';

type UpdatePrinterAssignmentsParams = {
  itemIds: number[];
  printerRoleIds: string[];
};

export const useUpdatePrinterAssignments = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      itemIds,
      printerRoleIds,
    }: UpdatePrinterAssignmentsParams) => {
      const response = await authenticatedFetch.put(
        `api/management/v1/ros/shops/${shopId}/menu/products/print_settings`,
        {
          itemIds,
          printerRoleIds,
        },
      );

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getMenuProductsQueryKey({ shopId }),
      });
    },
  });
};
