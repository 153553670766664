import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  CreateSalesSummaryReportResponse,
  SalesSummaryReportFormValues,
} from 'types/financials';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { camelCaseKeys } from 'utilities/objects';

import { getSalesSummaryReportsQueryKey } from './use-sales-summary-reports-query';

export const useCreateSalesSummaryReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: SalesSummaryReportFormValues) => {
      const params = {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
        order_types:
          values.order_types === 'offline' || values.order_types === 'online'
            ? values.order_types
            : undefined,
        generated_by: 'owners_portal',
      };

      const query = `${queryString.stringify(params)}`;
      const response = await authenticatedFetch.post(
        `api/management/v2/shops/${shopId}/sales_summary_reports?${query}`,
      );

      return camelCaseKeys(response) as CreateSalesSummaryReportResponse;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getSalesSummaryReportsQueryKey(shopId),
      });
    },
  });
};
