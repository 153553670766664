import Header from 'components/shared/header';

import styles from './styles.module.scss';

const CustomerAnalyticsHeader = () => {
  return (
    <Header
      title="All Your Customers In One Place"
      content={[
        `Verified Slice customers are organized into lists based on their ordering habits. Use this dashboard to answer: Who's my best customer? Who are my new customers? How much are my most loyal customers spending?`,
      ]}
      contentClass={styles.headerContent}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerAnalyticsHeader;
