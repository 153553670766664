import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AutomaticDiscount } from 'components/discounts/automatic-discount';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentOnlineDiscount } from 'hooks/discounts';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const AutomaticDiscountPage = () => {
  const shopId = useCurrentShopId();
  const navigate = useNavigate();
  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId, { staleTime: Infinity });
  const discountAction = useCurrentOnlineDiscount();
  const discountPercent = shop?.discountPercent;
  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
    if (!isShopLoading && !isShopError) {
      if (discountAction === 'edit' && !discountPercent) {
        navigate(`/shops/${shopId}/marketing/discounts/new`);
      } else if (discountAction === 'new' && !!discountPercent) {
        navigate(`/shops/${shopId}/marketing/discounts/edit`);
      } else {
        setIsRedirecting(false);
      }
    }
  }, [
    isShopLoading,
    isShopError,
    discountAction,
    discountPercent,
    navigate,
    shopId,
  ]);

  if (isShopLoading || isRedirecting) {
    return <Loading />;
  }

  if (isShopError) {
    return <UnexpectedError />;
  }

  const isNew = discountAction === 'new' || !discountPercent;
  const metadata = isNew
    ? {
        name: 'add-automatic-discount',
        title: 'Add Discount',
      }
    : {
        name: 'edit-automatic-discount',
        title: 'Edit Discount',
      };

  return (
    <Page name={metadata.name} title={metadata.title}>
      <AutomaticDiscount
        shopDiscountPercent={discountPercent || null}
        shopId={shop.shopId}
      />
    </Page>
  );
};
