import { Controller, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import { IconButton } from 'crust';

import { nextDayLabels } from 'components/hours/weekly-hours/helpers';
import TimeSelect, { CLOSED_VALUE } from 'components/shared/time-select';

import styles from './styles.module.scss';

const Entry = ({
  canDelete = false,
  closeShop,
  control,
  dayOfWeek = null,
  errors,
  index,
  onDeleteEntry = () => {},
  openShop,
  trigger,
  validateEntries,
  setValue,
}) => {
  const hoursForDay = useWatch({ control, name: 'hoursForDay' });
  const isOpenToday = useWatch({ control, name: 'isOpenToday' });

  const handleTimeChange = (value, field, timeType) => {
    if (value === CLOSED_VALUE) {
      // Handle user selecting 'closed' from the time dropdowns. We mark the shop as closed on this day in this case
      closeShop();
      setValue('isOpenToday', false);
    } else {
      if (!isOpenToday) {
        // Handle user going from a 'closed' time dropdown value, to a valid time. We mark the shop as opened in this case
        openShop({
          from: timeType === 'from' ? value : undefined,
          to: timeType === 'to' ? value : undefined,
        });
        setValue('isOpenToday', true);
      } else {
        // Handle updating a time
        setValue(`hoursForDay.${index}.isModified`, true);
        field.onChange(value);
        trigger();
      }
    }
  };

  const hasFromError = errors?.hoursForDay?.[index]?.from;

  const hasToError = errors?.hoursForDay?.[index]?.to;

  const fromEarliestTime = hoursForDay?.[index]?.from ?? '';

  return (
    <div className={styles.range}>
      <div className={styles.hourSelectors}>
        <div className={hasFromError ? styles.hourInvalid : styles.hour}>
          <span className={styles.entryLabel}>Start:</span>
          <Controller
            control={control}
            name={`hoursForDay[${index}].from`}
            rules={{
              required: true,
              validate: () => validateEntries(index),
            }}
            render={({ field }) => (
              <TimeSelect
                {...field}
                hasClosedValue
                aria-label="start time"
                onChange={({ value }) => handleTimeChange(value, field, 'from')}
              />
            )}
          />
        </div>
        <div className={hasToError ? styles.hourInvalid : styles.hour}>
          <span className={styles.entryLabel}>End:</span>
          <Controller
            control={control}
            name={`hoursForDay[${index}].to`}
            rules={{
              required: true,
              validate: () => validateEntries(index),
            }}
            render={({ field }) => (
              <TimeSelect
                {...field}
                hasClosedValue
                labelNextDayBefore={fromEarliestTime}
                nextDayLabel={nextDayLabels[dayOfWeek]}
                startAtTime={
                  fromEarliestTime === CLOSED_VALUE ? '' : fromEarliestTime
                }
                aria-label="end time"
                onChange={({ value }) => handleTimeChange(value, field, 'to')}
              />
            )}
          />
        </div>
      </div>
      <IconButton
        icon="trashCan"
        isDisabled={!canDelete}
        aria-label="Delete"
        onPress={onDeleteEntry}
      />
    </div>
  );
};

Entry.propTypes = {
  canDelete: PropTypes.bool,
  closeShop: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  dayOfWeek: PropTypes.string,
  errors: PropTypes.object,
  index: PropTypes.number.isRequired,
  onDeleteEntry: PropTypes.func,
  openShop: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  validateEntries: PropTypes.func,
  setValue: PropTypes.func,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Entry;
