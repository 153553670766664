import { useFieldArray, useWatch } from 'react-hook-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'crust';

import Label from 'components/shared/label';
import {
  AnalyticsField,
  createFormHalfOffer,
  createFormRelationship,
  createFormSelection,
  createFormWholeOffer,
  CustomizationField,
  getCustomizationFieldName,
  ProductField,
  ProductTypeField,
} from 'utilities/menu';

import ProductTypeSelect from './product-type-select';
import Selection from './selection';

import styles from './styles.module.scss';

const Selections = ({
  control,
  customizationIndex,
  errors,
  getValues,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  // Selections don't have an ID that could conflict with the field array
  // keyName, but we'll change it to stay consistent.
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: 'key',
    name: getCustomizationFieldName(
      CustomizationField.Selections,
      customizationIndex,
    ),
  });

  const isByHalf = useWatch({
    control,
    name: getCustomizationFieldName(
      CustomizationField.IsByHalf,
      customizationIndex,
    ),
  });

  const isByType = useWatch({
    control,
    name: getCustomizationFieldName(
      CustomizationField.IsByType,
      customizationIndex,
    ),
  });

  const handleClickAdd = () => {
    const productTypes = getValues(ProductField.ProductTypes);

    const relationships = [];
    const halfOffers = [];
    const wholeOffers = [];

    // We don't really need to generate all of these objects right here as the
    // SelectionMeta and SelectionOffers components would automatically create
    // them in a useEffect, but doing so saves one render and prevents a slight
    // delay in the price input appearing.
    productTypes.forEach((it) => {
      const productTypeId = it[ProductTypeField.Id];

      relationships.push(createFormRelationship({ productTypeId }));
      wholeOffers.push(createFormWholeOffer({ productTypeId }));

      if (isByHalf) {
        halfOffers.push(createFormHalfOffer({ productTypeId }));
      }
    });

    append(
      createFormSelection({
        halfOffers,
        relationships,
        wholeOffers,
      }),
    );

    setValue(AnalyticsField.SelectionAdded, true);
  };

  const handleRemove = (index) => {
    remove(index);
    setValue(AnalyticsField.SelectionRemoved, true);
  };

  return (
    <>
      <ProductTypeSelect
        control={control}
        customizationIndex={customizationIndex}
        hasSelectionError={errors != null}
        isByType={isByType}
      />
      <div className={cx(styles.container, isByHalf && styles.byHalf)}>
        <Label className={styles.name}>Name</Label>
        <Label className={styles.whole}>{isByHalf ? 'Whole' : 'Price'}</Label>
        {isByHalf && <Label className={styles.half}>Half</Label>}
        {fields.map((field, index) => (
          <Selection
            control={control}
            customizationIndex={customizationIndex}
            errors={errors?.[index]}
            getValues={getValues}
            isByHalf={isByHalf}
            isByType={isByType}
            key={field.key}
            length={fields.length}
            register={register}
            removeField={fields.length > 1 ? () => handleRemove(index) : null}
            selectionIndex={index}
            setValue={setValue}
            shopId={shopId}
            submitCount={submitCount}
          />
        ))}
      </div>
      <Button
        appearance="link"
        className={styles.addButton}
        onPress={handleClickAdd}
      >
        Add More
      </Button>
    </>
  );
};

Selections.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  errors: PropTypes.array,
  getValues: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Selections;
