import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getDailyHoursQueryKey } from 'hooks/daily-hours/use-daily-hours';
import useApi from 'hooks/use-api';
import { OpenForToday } from 'types/open-for-today';
import { camelCaseKeys } from 'utilities/objects';

import { getClosingsQueryKey } from './use-closings';
import { getOpenForTodayQueryKey } from './use-open-for-today';
import { getPausingsQueryKey } from './use-pausings';
import { getScheduleQueryKey } from './use-schedule';

type Values = {
  open_for_today: { delivery?: boolean; pickup?: boolean };
  closure_reason_id?: number;
  source?: string;
};

export const useUpdateOpenForTodayMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (openForTodayParams: Values) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/open_for_today`,
        openForTodayParams,
      );

      return camelCaseKeys(response.data) as OpenForToday;
    },
    onSuccess(data) {
      queryClient.setQueryData(getOpenForTodayQueryKey(shopId), data);
      // When we open or close we invalidate the daily hours so that we get the latest today and tomorrow times
      queryClient.invalidateQueries({
        queryKey: getDailyHoursQueryKey(shopId),
      });
      queryClient.invalidateQueries({
        queryKey: getPausingsQueryKey(shopId),
      });
      queryClient.invalidateQueries({
        queryKey: getClosingsQueryKey(shopId),
      });
      queryClient.invalidateQueries({
        queryKey: getScheduleQueryKey(shopId),
      });
    },
  });
};
