import cx from 'classnames';
import PropTypes from 'prop-types';

import Button, { ButtonVariant } from 'components/shared/slice-button';

import styles from './styles.module.scss';

const HeaderCellSortable = ({
  columnId = '',
  content = '',
  onClickSort = () => {},
  isSorted = false,
  isSortAscending = false,
  className = '',
  buttonClassName = '',
  isSortArrowVisible = true,
}) => (
  <th className={cx(styles.headerCell, className)}>
    <Button
      className={
        isSorted
          ? cx(styles.headerButtonSelected, buttonClassName)
          : cx(styles.headerButton, buttonClassName)
      }
      onClick={() => onClickSort(columnId, isSorted ? !isSortAscending : true)}
      variant={ButtonVariant.Transparent}
    >
      <span className={cx(isSorted ? styles.contentSelected : '')}>
        {content}
      </span>
      {isSortArrowVisible && (
        <span
          className={
            isSorted && !isSortAscending ? styles.arrow : styles.arrowUp
          }
          role="img"
          aria-label={
            !isSorted || !isSortAscending ? 'Sort ascending' : 'Sort descending'
          }
        >
          ▾
        </span>
      )}
    </Button>
  </th>
);

HeaderCellSortable.propTypes = {
  columnId: PropTypes.string,
  content: PropTypes.node,
  isSortAscending: PropTypes.bool,
  isSorted: PropTypes.bool,
  onClickSort: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  isSortArrowVisible: PropTypes.bool,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HeaderCellSortable;
