import { useMemo } from 'react';

import { Dashboard } from 'components/dashboard';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { getShopHasAnyRegister } from 'utilities/shops';

export const DashboardPage = () => {
  const shopId = useCurrentShopId();

  const traits = useShopTraitsContext();

  const {
    data: user,
    isError: isUserError,
    isPending: isUserLoading,
  } = useCurrentUserQuery();

  const {
    data: shop,
    isError: isShopError,
    isPending: isShopLoading,
  } = useShopQuery(shopId);

  const additionalTrackingProperties = useMemo(
    () => ({
      isRegisterUser: shop ? getShopHasAnyRegister(shop) : false,
      registerMode: shop?.registerMode.name ?? '',
    }),
    [shop],
  );

  const {
    isEnabled: isHomepageCalloutRecommendationEnabled,
    isLoading: isHomepageCalloutRecommendationLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.HomepageCalloutRecommendation);

  const isLoading =
    isHomepageCalloutRecommendationLoading || isShopLoading || isUserLoading;

  let content;

  if (isLoading) {
    content = <Loading />;
  } else if (isShopError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <Dashboard
        isHomepageCalloutRecommendationEnabled={
          isHomepageCalloutRecommendationEnabled
        }
        shop={shop}
        traits={traits}
        user={user}
      />
    );
  }

  return (
    <Page
      name="dashboard"
      isLoading={isLoading}
      isHeaderShown={false}
      additionalTrackingProperties={additionalTrackingProperties}
    >
      {content}
    </Page>
  );
};
