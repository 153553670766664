import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AutomaticDiscountForm } from 'components/discounts/automatic-discount/form';
import { AutomaticDiscountFormHeader } from 'components/discounts/automatic-discount/form-header';
import SubmitFooter from 'components/shared/submit-footer';
import { useSaveOnlineDiscountMutation } from 'hooks/discounts';
import OnlineDiscountsImg from 'images/discounts/online-discounts.svg';
import * as paths from 'routes/paths';
import { OnlineDiscountFormValues } from 'types/discounts';
import { Shop } from 'types/shops';
import { getDefaultOnlineDiscountFormValues } from 'utilities/discounts';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import { SummaryTile } from '../summary-tile';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  shopDiscountPercent: Shop['discountPercent'];
};

export const AutomaticDiscount = ({ shopDiscountPercent, shopId }: Props) => {
  const navigate = useNavigate();
  const isNew = !shopDiscountPercent;

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: getDefaultOnlineDiscountFormValues(shopDiscountPercent),
    mode: 'onBlur',
  });

  const {
    mutate: saveDiscount,
    isError: isDiscountRejected,
    isPending: isDiscountSaving,
    isSuccess: isDiscountSaved,
  } = useSaveOnlineDiscountMutation(shopId, shopDiscountPercent == null);

  useEffect(() => {
    if (isDiscountRejected) {
      showUnexpectedErrorToast();
    }
  }, [isDiscountRejected]);

  useEffect(() => {
    if (isDiscountSaved) {
      toast.success('Online discount saved!', { toastId: 'discountSaved' });
      navigate(paths.automaticDiscounts(shopId));
    }
  }, [isDiscountSaved, navigate, shopId]);

  const handleValidSubmit = (values: OnlineDiscountFormValues) =>
    saveDiscount(values);

  const handleInvalidSubmit = () => {
    showInvalidSubmitToast();
  };

  const handleCancel = () => {
    navigate(paths.automaticDiscounts(shopId));
  };

  return (
    <>
      <AutomaticDiscountFormHeader isNew={isNew} />
      <div className={styles.container}>
        <AutomaticDiscountForm
          control={control}
          errors={errors}
          onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
        >
          <SubmitFooter
            onCancel={handleCancel}
            isSubmitDisabled={isSubmitting || isDiscountSaving}
            isSubmitting={isSubmitting || isDiscountSaving}
            submitButtonChameleonTarget="Discount Edit"
          />
        </AutomaticDiscountForm>
        <SummaryTile
          content="This discount will display in your shop on Slice web and app marketplaces."
          imgUrl={OnlineDiscountsImg}
          summaryPoints={[
            'Discount on your entire menu',
            'Automatically applied at checkout',
          ]}
        />
      </div>
    </>
  );
};
