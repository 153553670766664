import { AddNewCard } from 'components/goods-payments/add-new-card';
import { SavedCardDetails } from 'components/goods-payments/saved-card-details';
import Page from 'components/shared/page';
import { usePaymentMethodTypesQuery } from 'hooks/adyen-credit-card/use-payment-method-type-query';
import { useCurrentShopId } from 'hooks/shops';

export const GoodsPaymentsPage = () => {
  const shopId = useCurrentShopId();
  const { paymentMethodTypes } = usePaymentMethodTypesQuery({
    parameters: { shopId },
  });

  console.log(paymentMethodTypes);

  //temp details untill the query hook is implemented
  //TODO implement tests when the query hook is implemented
  const shouldDisplaySavedCC = false;
  const creditCardDetails = {
    lastFourDigits: '1234',
    expirationMonth: 3,
    expirationYear: 2030,
    zipCode: '12345',
  };

  return (
    <Page name="goods payments" title="Goods Payments">
      {shouldDisplaySavedCC ? (
        <SavedCardDetails
          creditCardDetails={creditCardDetails}
          shopId={shopId}
        />
      ) : (
        <AddNewCard />
      )}
    </Page>
  );
};
