import PropTypes from 'prop-types';

import { TooltipIconButton } from 'components/shared/tooltips';

import styles from './styles.module.scss';

const AnalyticsTileHeader = ({ title = '', tooltipText = null }) => (
  <div className={styles.header}>
    <span className={styles.title}>{title}</span>
    {tooltipText && (
      <TooltipIconButton
        buttonClassName={styles.trigger}
        label="More about this metric"
        tooltipClassName={styles.tooltip}
      >
        {tooltipText}
      </TooltipIconButton>
    )}
  </div>
);

AnalyticsTileHeader.propTypes = {
  title: PropTypes.string,
  tooltipText: PropTypes.node,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsTileHeader;
